<script setup>
import header_arrow from 'src/asset/softPledge/inventory/header_arrow.webp'
import red_spot from 'src/asset/softPledge/inventory/red_spot.webp'
import { useRedSpot } from './useRedSpot'
const { handleClick: handleClickRedSpot, showRedSpotRef } = useRedSpot()
</script>
<template>
  <div class="absolute nft_soft mouse_pointer" @click="handleClickRedSpot">
    <img :src="header_arrow" class="arrow" />
    <i
      class="family_th_bold fz-5 text-#FFE26C absolute-center w-full text-center pr-2.5 pt-1"
      >NFT SOFT STAKING</i
    >
    <img
      :src="red_spot"
      v-if="showRedSpotRef"
      class="w-15px absolute top-5% right-16%"
    />
  </div>
</template>

<style scoped lang="scss">
.nft_soft {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include bg100('src/asset/softPledge/inventory/events.webp');
  @apply h-66px w-419px;

  .arrow {
    position: absolute;
    top: 50%;
    right: 15.5%;
    width: 13px;
    transform: translate(0, -50%);
  }

  &:hover {
    .arrow {
      animation: move 1s infinite;
    }
  }

  @keyframes move {
    0% {
      transform: translate(0, -50%);
    }

    50% {
      transform: translate(30%, -50%);
    }

    100% {
      transform: translate(0, -50%);
    }
  }
}
</style>
