<script setup>
import { useHandleWallet } from 'src/hooks/useHandleWallet'
import { useUserInfoStore } from 'src/stores/user'
import { accountRef } from 'src/sdk/blockchain'
import { ref } from 'vue'
import { useMessage } from 'naive-ui'
import { codeToMessage } from '@BOGX/helpers'
import WalletConfirmModal from './WalletConfirmModal'
import ItemModal from './ItemModal'
import { storeToRefs } from 'pinia'
const props = defineProps(['cd'])

const message = useMessage()
const { userInfo } = storeToRefs(useUserInfoStore())

const walletConfirmShowRef = ref(false)
const notWalletShowRef = ref(false)
const handleWallet = useHandleWallet({
  unbind() {
    notWalletShowRef.value = true
  },
  notBound() {
    walletConfirmShowRef.value = true
  },
  pass: props.cd,
})
function handleNewBind() {
  message.success('Wallet address binding successful.')
  walletConfirmShowRef.value = false
}

function handleUnbind() {
  notWalletShowRef.value = false
}
function handleAddressError(error) {
  message.error(codeToMessage(error?.data?.code))
}
</script>

<template>
  <div @click="handleWallet">
    <slot></slot>
    <!-- 确认绑定钱包地址 -->
    <WalletConfirmModal
      v-model:show="walletConfirmShowRef"
      text="Confirm wallet address binding."
      @success="handleNewBind"
      @error="handleAddressError"
    ></WalletConfirmModal>
    <!-- 当前钱包没有找到账号地址 -->
    <ItemModal
      class="w-600px!"
      v-model:show="notWalletShowRef"
      @confirm="handleUnbind"
    >
      <div class="text-18px p-4 text-left text-#CFDDE7">
        The address associated with your account does not match the currently
        active wallet address.
        <div class="mt-2">
          <span>Currently active wallet:</span>
          <span class="ml-2 orange-color break-all">
            {{ accountRef?.address }}
          </span>
        </div>
        <div class="my-2">
          <span>Void Labs linked wallet:</span>
          <span class="ml-2 orange-color break-all">
            {{ userInfo?.wallet }}
          </span>
        </div>
        <div>
          Please click on the MENU in the top right corner to link the same
          wallet and try again.
        </div>
      </div>
    </ItemModal>
  </div>
</template>
