import { ref } from 'vue'

export function useMintGameSoulCard() {
  // const mintShowRef = ref(true)
  const mintShowRef = ref(false)
  // 0: mint, 1: wait
  // const currentStageRef = ref(1)
  const currentStageRef = ref(0)
  function handleMintShow() {
    currentStageRef.value = 0
    mintShowRef.value = true
  }
  function handleMintClose() {
    mintShowRef.value = false
    currentStageRef.value = 0
  }

  return {
    mintShowRef,
    currentStageRef,
    handleMintClose,
    handleMintShow,
  }
}
