import { fetchUnlockImmutableSoulCard } from 'src/api'
import { useAsyncState } from '@vueuse/core'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { getBaseWalletSign } from 'src/helpers/blockchain'
import { fetchAuth } from 'src/sdk/immutable'
import { storeToRefs } from 'pinia'

export function useUnlockImmutableSoulCard() {
  const { immutableCurrentBindAccount, sbtInfo } = storeToRefs(
    useImmutableInfoStore(),
  )
  const { isLoading, execute } = useAsyncState(
    async itemToken => {
      await fetchAuth()
      const web3Signed = await getBaseWalletSign(
        immutableCurrentBindAccount.value,
        sbtInfo.value.chainId,
      )
      return fetchUnlockImmutableSoulCard({
        itemToken,
        web3Signed,
      })
    },
    null,
    {
      immediate: false,
      onError(error) {
        throw error
      },
    },
  )
  return {
    isLoading,
    execute,
  }
}
