import { useAsyncState } from '@vueuse/core'
import { fetchNFTInfo, fetchPFPDetail1 } from 'src/api'
import { ref, watch } from 'vue'
import copy from 'copy-to-clipboard'
import { useMessage } from 'naive-ui'

export function useHistoryTable(props) {
  const message = useMessage()
  const listRef = ref([])
  const { execute: fetchList } = useAsyncState(
    async () => {
      const pList = props.list.map(item => {
        // type显示0是nft 1是pfp，token是这几个币的tokenid，日常玩法也没有
        if (item.type === 0) {
          return fetchNFTInfo(item.token)
        } else if (item.type === 1) {
          return fetchPFPDetail1(item.token)
        } else {
          return Promise.resolve(item)
        }
      })
      const list = await Promise.all(pList)
      listRef.value = list.map((item, index) => {
        // TODO 假数据
        // const data = {
        //   ...props.list[index],
        //   cdkey: '1231asdasdasd',
        // }
        const data = props.list[index]
        return {
          ...item,
          ...data,
          use: Number(data.use),
          noneCode: data?.cdkey?.slice(0, 4) + '****',
          showCode: false,
        }
      })
    },
    null,
    {
      immediate: false,
    },
  )
  watch(
    () => props.list,
    () => {
      fetchList()
    },
    {
      immediate: true,
    },
  )
  function handleCopy(text) {
    copy(text)
    message.success('copy success')
  }
  return {
    listRef,
    handleCopy,
  }
}
