import { ref, watch, computed, watchEffect } from 'vue'
import { useMessage } from 'naive-ui'
import { useAsyncState } from '@vueuse/core'
import {
  fetchRoleList,
  fetchTokenInfo,
  fetchTokenOrderDraw,
  fetchTokenOrderInfo,
  fetchNFTPropsSend,
  fetchTBogxConaumeList,
  fetchWeb3Signed,
} from 'src/api'
import { codeToMessage } from '@BOGX/helpers'
import { getWalletSign } from 'src/helpers/blockchain'
import { getReceiveJobName } from 'src/pages/myCollection/utils'
import { useUserInfoStore } from 'src/stores/user'
import { useNFTCard } from 'src/stores/nftCard'
import { storeToRefs } from 'pinia'
import benefitImage1 from 'src/asset/nftCards/benefit1.webp'
import benefitImage2 from 'src/asset/nftCards/benefit2.webp'
import benefitImage3 from 'src/asset/nftCards/benefit3.webp'
import benefitImage4 from 'src/asset/nftCards/benefit4.webp'

export function useBenefitToken() {
  const { userInfo } = storeToRefs(useUserInfoStore())
  const { selectRoleId } = storeToRefs(useNFTCard())
  const message = useMessage()
  const roleList = ref([])
  const tokenInfoRef = ref()
  const roleId = ref('')
  const isOrderLoading = ref(false)
  const showSuccessModal = ref(false)
  const showHistoryModal = ref(false)
  const showRoleModal = ref(false)
  const counts = ref(0)
  const tokenCounts = ref(0)
  const consumeTbogxList = ref([])

  const benefitsList = computed(() => {
    return [
      {
        id: 0,
        roleId: roleId.value,
        rate: tokenInfoRef.value?.show_related?.genesis_magnification || 0,
        image: benefitImage1,
        level: tokenInfoRef.value?.nft_level,
        isClaim: tokenInfoRef.value?.is_nft_get_reward,
        desc: `
          <div style="text-align:center;margin-top:-4px;margin-bottom: 4px;color:#EFC58A">Genesis - Orisols</div>
          <div>1.Unlock exclusive Transmog</div>
          <div>2.Increase the minting rate of $tBOGX by ${tokenInfoRef.value?.show_related?.genesis_magnification || 0}% (Each additional Genesis increases its minting rate by 300%)</div>
          <div>3.Increased number of Soul Gem obtained ${tokenInfoRef.value?.show_related?.genesis_da_jin || 0}% (Only in Event - Daily quest)</div>`,
      },
      {
        id: 1,
        roleId: roleId.value,
        image: benefitImage2,
        level:
          tokenInfoRef.value?.pfp_level < 0 ? 0 : tokenInfoRef.value?.pfp_level,
        rate: tokenInfoRef.value?.show_related?.pfp_magnification || 0,
        desc: `
          <div style="text-align:center;margin-top:-4px;margin-bottom: 4px;color:#EFC58A">Ethereal</div>
          <div>1.Unlock all AI Agent features</div>
          <div>2.Increase the minting rate of $tBOGX by 1000% (Each additional Ethereal increases its minting rate by 200%)</div>
          <div>3.Increase the amount of Soul Gems obtained by 300% (Only applicable in Events - Daily Quests)</div>`,
      },
      {
        id: 2,
        roleId: roleId.value,
        image: benefitImage3,
        level:
          tokenInfoRef.value?.third_nft_level < 0
            ? 0
            : tokenInfoRef.value?.third_nft_level,
        rate: tokenInfoRef.value?.show_related?.third_nft_magnification || 0,
        desc: `
          <div style="text-align:center;margin-top:-4px;margin-bottom: 4px;color:#EFC58A">Partner Collection</div>
          <div>1.Increase the minting rate of $tBOGX by 100% (Does not stack with the Genesis/Ethereal minting rate boost. <br>This effect will not be activated once Genesis/Ethereal is obtained.)</div>
          <div>2.Increase the amount of Soul Gems obtained by 100% (Only applicable in Events - Daily Quests)</div>`,
      },
      {
        id: 3,
        roleId: roleId.value,
        image: benefitImage4,
        rate: tokenInfoRef.value?.show_related?.acceleration_magnification || 0,
        desc: `
          <div style="text-align:center;margin-top:-4px;margin-bottom: 10px;color:#EFC58A">Mintage Acceleration Coupon</div>
          <div>1.Within 24 hours, the minting rate of $tBOGX is increased by 500% <br>(Stacks with the privilege bonus effect)</div>`,
      },
    ]
  })

  const statusText = computed(() => {
    if (tokenInfoRef.value?.is_nft_get_reward) {
      return 'Claimed'
    } else {
      if (tokenInfoRef.value?.nft_level > 0) {
        return 'Claim'
      } else {
        return 'Not unlocked'
      }
    }
  })

  const { execute: executeRoleList } = useAsyncState(
    async () => {
      const data = await fetchRoleList()
      const list = data.RoleList.map(item => {
        return {
          ...item,
          label: getReceiveJobName(item.Job, item.Name),
          value: item.UserID,
        }
      })
      if (!selectRoleId.value) {
        if (list?.length > 1) {
          showRoleModal.value = true
        } else if (list?.length === 1) {
          roleId.value = list[0].UserID
        }
      } else {
        if (list.find(item => item.UserID === +selectRoleId.value)) {
          roleId.value = selectRoleId.value
        } else {
          if (list?.length > 1) {
            showRoleModal.value = true
          }
          roleId.value = ''
        }
      }
      roleList.value = [
        {
          label: 'Please select.',
          value: '',
        },
        ...list,
      ]
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
      },
    },
  )

  const { execute: executeData } = useAsyncState(
    async roleId => {
      const data = await fetchTokenInfo({
        char_id: roleId,
      })
      if (data.order) {
        executeOrderData(0, data.order)
      }
      tokenInfoRef.value = data
      tokenCounts.value = parseInt(data.boGxCount)
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
      },
    },
  )
  const { execute: executeOrderData } = useAsyncState(
    async orderId => {
      isOrderLoading.value = true
      const data = await fetchTokenOrderInfo({
        char_id: roleId.value,
        order: orderId,
      })
      if (data.order_status !== 0) {
        isOrderLoading.value = false
        if (data.order_status === -1) {
          message.error('Network failure, please try again')
        }
        if (data.order_status === 2) {
          showSuccessModal.value = true
          counts.value = parseInt(data.count)
          executeConsumeTbogxList()
        }
        executeData(0, roleId.value)
      } else {
        setTimeout(() => {
          executeOrderData(0, orderId)
        }, 5000)
      }
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
      },
    },
  )

  const { execute: executeTokenOrderDraw } = useAsyncState(
    async params => {
      const data = await fetchTokenOrderDraw({
        ...params,
      })
      const orderId = data.order
      executeOrderData(0, orderId)
    },
    null,
    {
      immediate: false,
      onError(error) {
        message.error(codeToMessage(error?.data?.code))
        console.log('error', error)
      },
    },
  )

  const { execute: executeNFTPropsSend } = useAsyncState(
    async params => {
      const data = await fetchNFTPropsSend({
        ...params,
      })
      message.success('Unlock success, Check your in-game Mail')
      executeData(0, roleId.value)
    },
    null,
    {
      immediate: false,
      onError(error) {
        message.error(codeToMessage(error?.data?.code))
        console.log('error', error)
      },
    },
  )

  const { execute: executeConsumeTbogxList } = useAsyncState(
    async () => {
      const data = await fetchTBogxConaumeList()
      consumeTbogxList.value = data
    },
    null,
    {
      immediate: false,
    },
  )

  const roleName = computed(() => {
    const item = roleList.value?.find(item => item.UserID === roleId.value)
    return item?.label
  })
  function handleSelectHero() {
    const currentList = roleList.value?.slice(1)
    if (currentList?.length > 1) {
      showRoleModal.value = true
    } else if (currentList?.length === 1) {
      return
    } else {
      message.info('your dont have hero')
    }
  }

  function handleClaim() {
    const currentItem = roleList.value?.find(
      item => item.UserID === roleId.value,
    )
    const params = {
      char_id: roleId.value,
      serverId: currentItem.SERVERID,
      count: tokenInfoRef.value.v_bo_gx_unlock_count,
    }
    executeTokenOrderDraw(0, params)
  }

  function handleReceive(level) {
    const params = {
      char_id: roleId.value,
      level,
    }
    executeNFTPropsSend(0, params)
  }

  function handleBindSuccess() {
    console.log('handleBindSuccess')
    executeData(0, roleId.value)
  }

  const { execute: executeWeb3Signed } = useAsyncState(
    async () => {
      const data = await handleEdit()
      console.log(data)
      await fetchWeb3Signed({
        ...data,
      })
      consumeTbogxList.value = data
    },
    null,
    {
      immediate: false,
    },
  )
  const { bindAddressComputed } = storeToRefs(useUserInfoStore())

  async function handleEdit() {
    const wallet = bindAddressComputed.value
    const web3Signed = await getWalletSign()
    if (wallet && web3Signed) {
      return {
        wallet,
        web3Signed,
      }
    } else {
      return false
    }
  }

  function handleConfirm(val) {
    roleId.value = val
    selectRoleId.value = val
  }

  watch(
    userInfo,
    data => {
      if (data?.AccountId) {
        executeRoleList()
        executeConsumeTbogxList()
        // executeData(0, roleId.value)
      } else {
        selectRoleId.value = ''
        roleId.value = ''
        tokenCounts.value = 0
        tokenInfoRef.value = {}
        roleList.value = [
          {
            label: 'Please select.',
            value: '',
          },
        ]
      }
    },
    {
      immediate: false,
    },
  )

  watch(roleId, id => executeData(0, id))
  // watchEffect(() => executeData(0, roleId.value))

  return {
    roleList,
    tokenInfoRef,
    roleId,
    isOrderLoading,
    showSuccessModal,
    showHistoryModal,
    showRoleModal,
    counts,
    tokenCounts,
    consumeTbogxList,
    benefitsList,
    statusText,
    roleName,
    executeData,
    handleClaim,
    handleConfirm,
    handleReceive,
    handleBindSuccess,
    handleSelectHero,
    executeWeb3Signed,
  }
}
