import { useAsyncState } from '@vueuse/core'
import { AppHttpCode, codeToMessage } from '@BOGX/helpers'
import { fetchUserInfoP } from 'src/helpers/user'
import { fetchImmutableBind } from 'src/api'
import { thirdPartyDiscord } from 'src/sdk/thirdParty'
const third_type = 'bogximx_discord'
export function useDiscord() {
  const message = window.$message

  const bindDiscord = useAsyncState(
    async () => {
      try {
        const token = thirdPartyDiscord.tokenData.value?.access_token
        if (!token) {
          thirdPartyDiscord.goToCodeLink()
          return
        }
        await fetchImmutableBind({
          third_type,
          token,
        })
        await fetchUserInfoP.run()
        message.success('binding successful.')
        return null
      } catch (error) {
        const code = error?.data?.code
        let text
        if (AppHttpCode.BOUND === code) {
          text = 'The discord account has been bound.'
        } else {
          text = codeToMessage(error?.data?.code)
        }
        message.error(text)
        throw error
      }
    },
    null,
    {
      immediate: false,
    },
  )
  return {
    bindDiscord,
  }
}
