import { fetchSoulCardAttrsAndConsume } from 'src/api'
import { computed, ref, watch } from 'vue'
export function useUpItemList(tokenIdValue, userIdValue) {
  const resRef = ref({})
  watch(
    [tokenIdValue, userIdValue],
    ([itemToken, UserID]) => {
      if (!(itemToken && UserID)) return
      fetchSoulCardAttrsAndConsume({
        itemToken,
        UserID,
      }).then(data => (resRef.value = data))
    },
    {
      immediate: true,
    },
  )
  const upListComputed = computed(() => {
    return (
      resRef.value?.propertyRange?.map(item => {
        return {
          ...item,
          oldValue: item.min,
          upValue: item.max,
          theme: item.quality,
          id: item.name,
        }
      }) || []
    )
  })
  const consumeComputed = computed(() => resRef.value?.consume)
  return {
    upListComputed,
    consumeComputed,
    resRef,
  }
}
