import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNFTCard = defineStore(
  'nftCardInfo',
  () => {
    const selectRoleId = ref('')
    const selectIncomeRoleId = ref('')
    return {
      selectRoleId,
      selectIncomeRoleId,
    }
  },
  {
    persist: true,
  },
)
