<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useMessage, NSelect } from 'naive-ui'
import Modal from 'src/components/Modal'
import ItemModal from 'src/components/ItemModal'
import Table from './components/Table'
import { useNFTCards, selectTheme } from './index'
import { OKX_PATH } from 'src/constant'

const props = defineProps({
  show: Boolean,
  roleList: Array,
})
const emit = defineEmits(['update:show', 'success'])

const message = useMessage(emit)
const { listRef, loadingEditRef, executeList, executeBind, executeUnbind } =
  useNFTCards(emit)
const confirmModelRef = ref(false)
const currentItemRef = ref(null)
async function handleOperate(item) {
  if (!item.roleId) {
    message.warning('Please select a hero.')
    return
  }
  currentItemRef.value = { ...item }
  confirmModelRef.value = true
}
async function handleConfirm() {
  const item = currentItemRef.value
  if (item.operateType) {
    await executeUnbind(
      0,
      item.type,
      item.data.tokenId || item.data.id,
      item.roleId,
      item.id,
    )
  } else {
    await executeBind(
      0,
      item.type,
      item.data.tokenId || item.data.id,
      item.roleId,
    )
  }
  confirmModelRef.value = false
}
const roleListComputed = computed(() => {
  return props.roleList.map(item => ({
    label: item.value ? item.label + '-' + item.ServerName : item.label,
    value: item.value,
  }))
})

watch(
  () => props.show,
  () => {
    executeList()
  },
)

function handleShow(b) {
  emit('update:show', b)
}
</script>
<template>
  <Modal class="modal" :show="props.show" @update:show="handleShow">
    <div
      class="scroll w-80% h-80vh! item_tracking overflow-auto bg-#15130F border border-solid border-#DCAC81"
    >
      <div class="relative">
        <div
          @click="handleShow(false)"
          class="btn-close mouse_pointer absolute top-1 right-2 w-100px h-38px"
        ></div>
        <Table class="table">
          <thead>
            <tr>
              <th><div class="text-center">NFT OPERATE</div></th>
              <th><div class="text-center">ITEM</div></th>
              <th><div class="text-center">NUMBER</div></th>
              <th><div class="text-center">HERO</div></th>
              <th><div class="text-center">STATE</div></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <template v-for="item in listRef" :key="item.type + item.data.id">
              <tr>
                <td class="w-150px">
                  <div class="flex-center">
                    <img
                      :src="item.data.image"
                      class="w-40px h-40px border border-solid border-#587277"
                      alt=""
                    />
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    {{ item.name }}
                  </div>
                </td>
                <td class="w-150px">
                  <div class="text-center">
                    #{{ item.data.tokenId || item.data.id }}
                  </div>
                </td>
                <td class="w-300px">
                  <div class="flex-center" v-if="item.roleId">
                    <NSelect
                      :show-checkmark="false"
                      v-model:value="item.roleId"
                      :disabled="item.operateType === 1 ? true : false"
                      :options="roleListComputed"
                      :theme-overrides="selectTheme"
                    ></NSelect>
                  </div>
                  <div class="flex-center" v-else>
                    <NSelect
                      :show-checkmark="false"
                      :disabled="item.operateType === 1 ? true : false"
                      v-model:value="item.roleId"
                      :options="roleListComputed"
                      :theme-overrides="selectTheme"
                    ></NSelect>
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <span class="text-#A39883" v-if="item.coolingTimeText"
                      >Rights cooling down ({{ item.coolingTimeText }})</span
                    >
                    <span class="text-#52D058" v-else-if="item.operateType"
                      >Rights inuse</span
                    >
                    <span class="text-#FD8E4B" v-else
                      >Rights awaiting redemption</span
                    >
                  </div>
                </td>
                <td class="w-154px">
                  <div
                    class="w-154px h-20px flex-center"
                    :class="`${item.operateType ? 'unbind_btn' : 'bind_btn'} ${item.coolingTimeText ? 'cursor-no-allowed' : 'mouse_pointer'}`"
                    @click="!item.coolingTimeText && handleOperate(item)"
                  >
                    {{ item.operateType ? 'Unbind Rights' : 'Bind Rights' }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </Table>
        <div v-if="!listRef.length" class="h-10vh flex-center">
          <div>
            No BOGX NFT?
            <a :href="OKX_PATH" target="_blank" class="link text-#F9CD79"
              >Click to buy.</a
            >
          </div>
        </div>
      </div>
      <ItemModal
        v-model:show="confirmModelRef"
        @confirm="handleConfirm"
        :loading="loadingEditRef"
      >
        <div class="text-center fz-4 py-8">
          Confirm {{ currentItemRef.operateType ? 'unbinding' : 'Binding' }}?
        </div>
      </ItemModal>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
.table {
  ::v-deep {
    th {
      border-bottom: 1px solid #dcac81;
    }
  }
}

.btn-close {
  @include bg100('src/asset/nftCards/btn_close.webp');

  &:hover {
    @include bg100('src/asset/nftCards/btn_close_active.webp');
  }
}

.bind_btn {
  @include bg100('src/asset/nftCards/btn_bind.webp');

  color: #ebc06c;

  // &:hover {
  //   @include bg100('src/asset/nftCards/btn_bind.webp');

  //   color: #ebc06c;
  // }
}

.unbind_btn {
  @include bg100('src/asset/nftCards/btn_unbind.webp');

  color: #db6140;
}

.scroll {
  &::-webkit-scrollbar {
    /* 滚动条整体样式 */
    width: 0; /* 高宽分别对应横竖滚动条的尺寸 */
    height: 0;
  }
}

.modal {
  ::v-deep {
    z-index: 2020;
  }
}

// .n-modal-container {
//   z-index: 2020;
// }

.link {
  text-decoration: underline;
}
</style>
