<script setup>
import { NPopover } from 'naive-ui'
import { gsap } from 'gsap'
import { onMounted, onUnmounted, ref, computed } from 'vue'
import Video from 'src/components/Video'
import SocializeLinks from './SocializeLinks.vue'
import copy from 'copy-to-clipboard'
import { storeToRefs } from 'pinia'
import { useNFTSoftPledgeStore } from 'src/stores/softPledge'
const { emptyGuideShow } = storeToRefs(useNFTSoftPledgeStore())

const props = defineProps({
  deckData: Object,
  notDeck: Boolean,
})

const isCopyed = ref(false)
let timer
function copyAddress() {
  copy('0x3e0361c7E8C3C87B2fa55cC042648BeC9990fCD6')
  isCopyed.value = !isCopyed.value
  timer = setTimeout(() => {
    isCopyed.value = false
  }, 2000)
}

let triangleRef = ref(null)
let triangleTl

function initAnimation() {
  triangleTl = gsap.to(triangleRef.value, {
    rotate: 180,
    transformOrigin: 'center bottom',
    ease: 'linear',
    paused: true,
    duration: 0.15,
  })
}

function getNPopoverStatus(val) {
  if (val) {
    triangleTl.restart()
  } else {
    triangleTl.reverse()
  }
}
const boxDom = ref(null)
const widthComputed = computed(() => {
  if (boxDom.value) {
    return boxDom.value.getBoundingClientRect().width
  } else {
    return 0
  }
})

onMounted(() => {
  initAnimation()
})

onUnmounted(() => {
  triangleTl?.kill()
  clearTimeout(timer)
})
</script>

<template>
  <div class="relative">
    <div class="flex-1 text-1.25vw leading-1.46vw pb-0.52vw relative">
      <div
        class="family_blk_bold relative flex flex-items-center text-1.56vw leading-1.83vw tracking-3px"
      >
        <template v-if="deckData">
          <span>
            {{
              deckData?.nft?.name?.replace(/ - .*/, '')?.toUpperCase()
            }}-</span
          >
          <span class="text-#F8B462">{{
            deckData?.nft?.name?.replace(/.*- (.*?) #.*/, '$1')?.toUpperCase()
          }}</span>
          <div class="ml-1.04vw">
            <div v-if="!isCopyed">
              <NPopover class="BrownPopover" placement="bottom" raw>
                <template #trigger>
                  <img
                    @click="copyAddress"
                    class="mouse_pointer w-1.406vw"
                    src="src/asset/nftSoftStaking/copy_icon.webp"
                    alt=""
                  />
                </template>
                <div
                  class="flex-center px-0.208vw h-1.35vw rounded-6px color-#F89B58 bg-#15110E border border-solid border-#804D2C text-0.9375vw"
                >
                  copy contract address
                </div>
              </NPopover>
            </div>
            <img
              v-else
              :style="{ opacity: isCopyed ? 1 : 0 }"
              class="w-0.9375vw"
              src="src/asset/nftSoftStaking/copyed_icon.webp"
              alt=""
            />
          </div>
        </template>
        <span v-else class="text-#B2A998">BOGX GENESIS</span>
      </div>
      <div :style="{ color: !deckData ? '#707579' : '#52D058' }">
        +{{ deckData?.data?.nft_next_hour_integral || 0 }} points / h
        <span :style="{ color: !deckData ? '#707579' : '#FFFFFF' }"
          >({{ deckData?.data?.total_bonus_sum || '0' }}% bonus)</span
        >
      </div>
      <NPopover
        placement="bottom"
        :show-arrow="false"
        @update:show="getNPopoverStatus"
        raw
      >
        <template #trigger>
          <div
            class="btn-buy mouse_pointer flex-center w-11.77vw h-2.19vw absolute right-0 bottom-0"
          >
            BUY NOW
            <div
              ref="triangleRef"
              class="triangle w-0.677vw h-0.677vw absolute z-1 bottom-1px mx-auto bg-#EBAA56"
            ></div>
          </div>
        </template>
        <div class="bg-#7E582B w-11.77vw px-0.365vw py-0.3125vw rounded-5px">
          <SocializeLinks :isMaxHeight="false"></SocializeLinks>
        </div>
      </NPopover>
    </div>
    <div class="flex flex-items-end">
      <div
        ref="boxDom"
        class="relative w-13.59vw 5xl:w-14.2vw 6xl:w-15vw h-13.59vw border border-solid border-#614A32 overflow-hidden"
      >
        <template v-if="deckData">
          <Video
            class="w-full h-full"
            :src="deckData?.nft?.link"
            :controls="false"
            :poster="deckData?.nft?.cover"
            autoplay
            loop
            muted
          ></Video
        ></template>
        <img
          v-else
          class="w-9.66vw absolute top-50% left-50% -translate-50%"
          src="src/asset/nftSoftStaking/empty_nft.webp"
          alt=""
        />
      </div>
      <div class="flex-1 relative">
        <div
          class="py-0.9375vw px-1.04vw w-full h-13.59vw bg-#15110E border border-l-0 border-solid border-#614A32 tracking-3px text-0.9375vw text-#AC9D94 leading-1.14vw"
        >
          <div class="flex mb-0.2vw">
            <span>1.</span>
            <div class="text-justify">
              The base staking points for each Genesis NFT are:
              <span class="text-#fff">10 / hour</span>.
            </div>
          </div>
          <div class="flex mb-0.2vw">
            <span>2.</span>
            <div class="text-justify"></div>
            Additional bonuses based on rarity, quantity held, and staking
            duration.
          </div>
          <div class="flex mb-0.2vw">
            <span>3.</span>
            <div class="text-justify">
              Each Genesis NFT possesses
              <span class="text-#fff">5 &lt;Ethereal slots&gt;</span>.
            </div>
          </div>
          <div class="flex mb-0.2vw">
            <span>4.</span>
            <div class="text-justify">
              Placing each Ethereal NFT in
              <span class="text-#fff">a &lt;slot&gt;</span> will enhance the
              staking points of the Genesis NFT.
            </div>
          </div>
          <div class="mt-1.04vw text-#FFFFFF">
            Note: Once an NFT is listed, transferred, or sold, all earned points
            will be forfeited.
          </div>
        </div>
      </div>
    </div>

    <!-- 指引 -->
    <div
      v-if="emptyGuideShow"
      class="guide-bg absolute -bottom-1% -left-1.6% w-102.8% h-103% z-1 md:text-base 2xl:text-2xl"
    >
      <div class="absolute top-0.97vw -right-1.04vw family_blk_bold">
        <div class="flex flex-items-center">
          <div class="text-#69F093 mr-1.04vw">TOTAL STAKING POINTS</div>
          <img
            class="w-14.48vw"
            src="src/asset/nftSoftStaking/guide/step/4/arrow3.webp"
            alt=""
          />
        </div>
        <span class="text-#fff"
          >Total staking points of all NFTs within the<br />
          current deck.</span
        >
      </div>
      <div
        class="absolute bottom-3vw left-13vw 5xl:left-14vw 6xl:left-14.5vw family_blk_bold"
      >
        <div class="flex flex-items-start">
          <img
            class="w-2.08vw"
            src="src/asset/nftSoftStaking/guide/step/4/arrow2.webp"
            alt=""
          />
          <div class="text-#69F093 ml-0.52vw flex flex-col">
            <span>GENESIS NFT SLOTS</span>
            <span class="text-#fff"
              >NFTs of different rarities will provide<br />
              different bonuses.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.btn-buy {
  @include bg100('src/asset/nftSoftStaking/btn_buy_bg.webp');
  @apply hover:bg-[url(src/asset/nftSoftStaking/btn_buy_active_bg.webp)];

  .triangle {
    clip-path: polygon(50% 50%, 100% 100%, 0 100%);
  }
}

.guide-bg {
  background-color: rgb(6 13 9 / 92%);

  @include bg100('src/asset/nftSoftStaking/guide/step/4/box_bg.webp');
}

.BrownPopover {
  .n-popover-arrow {
    @apply border border-solid border-#804D2C;

    background-color: #15110e !important;
  }
}

.video-js .vjs-tech {
  object-fit: fill !important;
}
</style>
