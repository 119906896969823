import { createPublicClient, createWalletClient } from 'viem'
import { arbitrumSepolia, polygon } from 'viem/chains'
import { accountRef } from '../store'
import { getTransport, hasTestChainID } from '../walletConnect'

export function newCreateWalletClient(
  chainId,
  accountAddress = accountRef.value?.address,
  transport = getTransport(),
) {
  return createWalletClient({
    chain: { id: chainId },
    transport,
    account: accountAddress,
  })
}

export function newCreatePublicClient(chainId, transport = getTransport()) {
  return createPublicClient({
    chain: { id: chainId },
    transport,
  })
}

export async function newSwitchChain(walletClient) {
  const chainId = walletClient.chain.id
  try {
    await walletClient.switchChain({ id: chainId })
  } catch (error) {
    // 测试网络
    if (hasTestChainID(chainId)) {
      await walletClient.addChain({ chain: arbitrumSepolia })
      await walletClient.switchChain({ id: chainId })
    } else {
      if (error.code === 4001) throw new Error('User rejected the request.')
      if (chainId === polygon.id) {
        await walletClient.addChain({ chain: polygon })
        await walletClient.switchChain({ id: chainId })
      }
    }
  }
}
