import { ReceiveJobSelectMap } from 'src/constant'

export function getReceiveJobName(ReceiveJob, Name) {
  return `${ReceiveJobSelectMap[ReceiveJob]}-${Name}`
}

export function getType(id, type) {
  // if (id < 10000000) {
  switch (type) {
    case 4:
      return 'Fashion'
    case 10:
      return 'Special Props'
    case 11:
      return 'Soul Card'
    case 16:
      return 'Identity'
    default:
      return 'Other'
  }
  // } else {
  //   return 'Equipment'
  // }
}

export function tokenIdFormat(text) {
  if (typeof text === 'string') {
    return text.slice(0, 8)
  }
  return text
}

export const selectTheme = {
  peers: {
    InternalSelection: {
      heightMedium: '27px',
    },
  },
}

export function getBtnName(data) {
  const { state } = data || {}
  switch (state) {
    case 0:
    case 4:
      return 'Mint'
    case 1:
    case 3:
      return 'Minting'
    case 2:
    case 5:
      return 'Unlock to wallet'
    case 10:
      return 'Unlocking'
    case 11:
      return 'Deliver to Game'
    case 15:
      return 'Delivering'
    case 20:
      return 'Deliver to Game'
  }
}
export function getSoulToknDataId(data) {
  return data?.tokenId || data?.mint?.tokenid || data?.info?.mint?.tokenid
}
export function idsToListTokenIdOne(ids, list) {
  return getSoulToknDataId(idsToListItem(ids, list))
}
export function idsToListItem(ids, list) {
  const id = Array.isArray(ids) ? ids[0] : ids
  const data = list.find(item => item?.Id === id)
  return data
}

// const statusMessageList = {
//   1: 'Already locked',
//   2: 'Unlocking...',
//   3: 'Unlocked',
//   4: 'Locking...',
// }
// export function getStateMessage(state) {
//   return statusMessageList[state]
// }

export function getResponseWaitState(state) {
  return [-1, 1, 3, 10, 15].includes(state)
}

export function handleIngMessage(item) {
  const message = window.$message
  switch (item.type) {
    case 1:
      message.warning('Minting in progress')
      break
    // case 2:
    //   message.warning('Unlocking in progress')
    //   break
    // case 3:
    //   message.warning('Locking in progress')
    //   break
  }
}
