<script setup>
import Header from './components/Header/index.vue'
import { ref, computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import ItemModal from './components/ItemWallet/index'
import Panel from './components/Panel/index.vue'
import DeckPoints from './components/DeckPoints/index.vue'
import NFTPanel from './components/NFTPanel/index.vue'
import PFPCombination from './components/PFPCombination/index.vue'
import NFTModal from './components/NFTModal/index.vue'
import PFPModal from './components/NFTModal/PFPModal.vue'
import EmptyModal from './components/NFTModal/EmptyModal.vue'
import Step1 from './components/Guide/Step1.vue'
import Step2Left from './components/Guide/Step2Left.vue'
import Step2Right from './components/Guide/Step2Right.vue'
import Step3 from './components/Guide/Step3.vue'
import {
  useNFTSoftPledgeDomStore,
  useNFTSoftPledgeStore,
} from 'src/stores/softPledge'
import { useLoginStore } from 'src/stores/user'
const loginStore = useLoginStore()
const nftSoftPledgeStore = useNFTSoftPledgeStore()
const { open: login } = loginStore
const { hasLogin } = storeToRefs(loginStore)

const {
  deckList,
  activeDeckIndex,
  notDeck,
  guideShow,
  unAddNFTList,
  hasNFT,
  unAddPFPList,
  emptyGuideShow,
  emptyGuideList,
} = storeToRefs(useNFTSoftPledgeStore())

const { deletePFPStakingCombination } = nftSoftPledgeStore
const { mainDom, mainContainerLeftDom } = storeToRefs(
  useNFTSoftPledgeDomStore(),
)

const showNFTModal = ref(false)
const showEmptyModal = ref(false)
const showPFPModal = ref(false)
const mainDomRef = ref(null)
const mainContainerLeftDomRef = ref(null)
const position = ref(0)
const pfpId = ref(0)

const deckData = computed(() => {
  return deckList.value?.[activeDeckIndex.value]
})

const emptyGuideData = computed(() => {
  return emptyGuideList.value?.[0]
})

function getActiveDeck(index) {}
async function handleAdd() {
  if (!hasLogin.value) {
    await login()
  }
  if (!hasNFT.value) {
    showEmptyModal.value = true
  } else {
    showNFTModal.value = true
  }
}

const confirmShowRef = ref(false)
async function handleConfirm() {
  await deletePFPStakingCombination(
    0,
    deckData.value?.data?.group_id,
    pfpId.value,
    position.value,
    0,
  )
  confirmShowRef.value = false
}

function handleEdit(index, data) {
  if (!data && !unAddPFPList.value.length) {
    showEmptyModal.value = true
    return
  }
  position.value = index
  pfpId.value = data?.id
  if (data) {
    confirmShowRef.value = true
    return
  }
  showPFPModal.value = true
}

async function handleRemoveAll() {
  await deletePFPStakingCombination(
    0,
    deckData.value?.data?.group_id,
    null,
    null,
    1,
  )
}
watch([mainDomRef, mainContainerLeftDomRef], ([dom, leftDom]) => {
  if (dom) {
    mainDom.value = dom
  }
  if (leftDom) {
    mainContainerLeftDom.value = leftDom
  }
})
</script>
<template>
  <div class="wrap bg-#090D10 item_container">
    <Header></Header>
    <div
      class="main flex relative"
      :class="{ 'pointer-events-none': guideShow }"
    >
      <div class="relative h-full">
        <Panel @add="handleAdd" @active="getActiveDeck"></Panel>
        <Step2Left></Step2Left>
      </div>
      <div
        ref="mainDomRef"
        class="main-container flex relative p-1.37vw pb-0.2vw"
      >
        <div
          class="main-container-left w-61% h-full mr-2.5%"
          ref="mainContainerLeftDomRef"
        >
          <NFTPanel
            :notDeck="notDeck"
            :deckData="emptyGuideShow ? emptyGuideData : deckData"
          ></NFTPanel>
          <PFPCombination
            @edit="handleEdit"
            @removeAll="handleRemoveAll"
            :empty="guideShow || emptyGuideShow"
            :pfpList="
              emptyGuideShow ? emptyGuideData?.pfpList : deckData?.pfpList
            "
          ></PFPCombination>
        </div>
        <div class="main-container-right flex-1 h-full origin-tl relative">
          <DeckPoints
            :nftData="emptyGuideShow ? emptyGuideData?.nft : deckData?.nft"
            :pointsData="emptyGuideShow ? emptyGuideData?.data : deckData?.data"
          ></DeckPoints>
          <Step2Right></Step2Right>
        </div>
        <Step1></Step1>
      </div>
      <!-- <div v-else class="main-container p-0.5% pl-1% relative">

      </div> -->

      <Step3></Step3>
    </div>
    <NFTModal :list="unAddNFTList" v-model:show="showNFTModal"></NFTModal>
    <PFPModal
      :list="unAddPFPList"
      :groupId="deckData?.data?.group_id"
      :pfpId="pfpId"
      :positionIndex="position"
      v-model:show="showPFPModal"
    ></PFPModal>
    <EmptyModal v-model:show="showEmptyModal"></EmptyModal>
    <ItemModal v-model:show="confirmShowRef" @confirm="handleConfirm">
      <div class="fz-5 px-2 py-10 text-center text-#CFDDE7">
        Confirm remove this Ethereal?
      </div>
    </ItemModal>
  </div>
</template>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .main {
    height: calc(100vh - var(--header-height));

    &-container {
      width: calc(100vw - 304px);
    }
  }
}
</style>
