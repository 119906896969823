import { fetchPFPMintInfo } from 'src/api'
import { computed, ref } from 'vue'

export function useMintInfo() {
  const mintInfoRef = ref(null)
  // 当前阶段
  const phaseComputed = computed(() => {
    const serverTime = mintInfoRef.value?.serverTime
    const gtdTimeArr = mintInfoRef.value?.gtdTime
    const fcfsTimeTimeArr = mintInfoRef.value?.fcfsTime

    const timeStages = [
      gtdTimeArr, // gtdTime
      fcfsTimeTimeArr, // fcsTime
    ]

    return getCurrentStage(serverTime, timeStages)
  })
  // 总的活动时间，1: 未开始，2：进行中，3：已结束
  const wholeActivityStatusComputed = computed(() => {
    const phase = phaseComputed.value
    if (phase === 3 || mintCompleteComputed.value) {
      return 3
    } else if (phase === -1) {
      return 1
    } else {
      return 2
    }
  })

  const totalMintCountComputed = computed(
    () => mintInfoRef.value?.totalMintCount || 0,
  )
  const supplyComputed = computed(() => mintInfoRef.value?.supply || 0)

  const mintProgressComputed = computed(() => {
    return Math.min(totalMintCountComputed.value / supplyComputed.value, 1)
  })
  const mintCompleteComputed = computed(() => mintProgressComputed.value === 1)
  function getCurrentStage(currentTime, timeStages) {
    const [gtdTime, fcsTime] = timeStages
    const gtdStart = gtdTime?.[0]
    const gtdEnd = gtdTime?.[1]
    const fcsStart = fcsTime?.[0]
    const fcsEnd = fcsTime?.[1]

    if (currentTime < gtdStart) {
      return 0 // 未开始
    } else if (currentTime >= gtdStart && currentTime < gtdEnd) {
      return 1 // gtdTime
    } else if (currentTime >= fcsStart && currentTime < fcsEnd) {
      return 2 // fcsTime
    } else if (currentTime >= fcsEnd) {
      return 3 // 已结束
    } else {
      return 0 // 未开始
    }
  }
  async function getMintInfo() {
    mintInfoRef.value = await fetchPFPMintInfo()
  }
  const serverTimeComputed = computed(
    () => mintInfoRef.value?.serverTime * 1000,
  )
  const startTimeComputed = computed(
    () => mintInfoRef.value?.gtdTime?.[0] * 1000,
  )
  const endTimeComputed = computed(
    () => mintInfoRef.value?.fcfsTime?.[1] * 1000,
  )
  getMintInfo()
  return {
    mintInfoRef,
    wholeActivityStatusComputed,
    phaseComputed,
    serverTimeComputed,
    startTimeComputed,
    endTimeComputed,
    mintCompleteComputed,
    getMintInfo,
  }
}
