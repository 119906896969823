<script setup>
import { computed } from 'vue'
import { hasPhone } from 'src/stores/media.js'
import { getSoulToknDataId, getType, tokenIdFormat } from '../utils.js'
import TransferItem from './TransferItem.vue'
import ImmutableSvg from 'src/asset/Immutable.svg?url'
import { useBox } from '../hooks/useBox'
import { useMintIng } from '../hooks/useMintIng'
const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    },
  },
  selectData: {
    type: Array,
    default() {
      return []
    },
  },
  roleList: {
    type: Array,
    default() {
      return []
    },
  },
  singleChoiceData: {
    type: Object,
    default() {
      return []
    },
  },
})
const emit = defineEmits(['select', 'cancel', 'enlarge', 'submit', 'check'])
const {
  qualityRef,
  hasSelectClassRef,
  multipleSelectionModeRef,
  handleEnlarge,
  handleSubmit,
  handleStopPropagation,
  handleCheck,
} = useBox(props, emit)
const dataComputed = computed(() => props.data)
const { hasMintComputed, hasMintIngComputed } = useMintIng(dataComputed)
</script>

<template>
  <div
    class="soul_item overflow-hidden relative"
    @click="handleCheck"
    :class="`${hasSelectClassRef ? 'soul_select' : 'soul_default'}`"
  >
    <div class="bg-#0E0E0F">
      <div class="box_item">
        <div
          class="cover_bg m-a h-full w-full"
          :class="{ mint_ing_img: hasMintIngComputed }"
          :style="{
            backgroundImage: `url(${data.Icon})`,
          }"
        ></div>
        <img
          v-if="!hasPhone"
          src="/src/asset/enlarge.webp"
          class="mouse_pointer absolute bottom-1 right-1 w-[24px]"
          @click="handleEnlarge"
        />
      </div>
      <div
        class="fz-3 border_style footer relative border-0 border-t px-1.5 py-1"
      >
        <div>
          <div class="flex-sb">
            <div class="flex-center">
              <span class="ellipsis-1 mr-2">{{ data.Name }}</span>
              <span
                v-if="!hasPhone && data.type !== 1"
                class="flex-shrink-0 text-#C0C5CC"
                >#{{ tokenIdFormat(getSoulToknDataId(data)) }}</span
              >
            </div>
            <!-- <div
              v-if="!data.ReceiveUid"
              class="flex-center ml-1 h-[15px] w-[15px]"
              @click="handleSelect"
            >
              <div
                class="mouse_pointer relative h-[12px] w-[12px] border border-#A0A3A5 border-solid md:h-[15px] md:w-[15px]"
              >
                <img
                  v-show="hasSelectRef"
                  src="/src/asset/white_tick.webp"
                  class="w-[9px] align-super md:w-[12px]"
                  alt=""
                />
              </div>
            </div> -->
          </div>
          <div class="flex flex-justify-between tracking-1px mt-1">
            <div class="fz-2">
              <span class="gray-color">Type:</span>
              <span>{{ getType(data.ItemId, data.ItemType) }}</span>
            </div>
            <img
              v-if="!hasMintComputed"
              :src="ImmutableSvg"
              class="md:w-24px w-15.5px"
            />
          </div>

          <div
            class="box_item_rarity fz-2 tracking-1px"
            v-if="qualityRef.color"
          >
            <div class="flex items-center pt-0.5">
              <span class="gray-color mr1">Rarity: </span>
              <span :style="{ color: qualityRef.color }">{{
                qualityRef.text
              }}</span>
            </div>
            <div class="flex items-center pt-0.5 ellipsis-1">
              <span>{{ data.ReceiveName }}</span>
            </div>
          </div>
        </div>
        <TransferItem
          v-if="!hasPhone"
          class="absolute"
          :class="{
            come_out: !multipleSelectionModeRef,
            permanent: data.responseWait,
          }"
          :data="data"
          :select-data="selectData"
          :role-list="roleList"
          :disabled="data?.UserID || data.responseWait"
          :value="data?.UserID"
          submitText="Unlock"
          @click="handleStopPropagation"
          @submit="handleSubmit"
        >
          <template #btn="{ handleSubmit }">
            <slot name="btn" :handleSubmit="handleSubmit" class="bg-red"></slot>
          </template>
        </TransferItem>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
@import '../border';
@import './mint';

.soul_item {
  @apply p-1px;

  &.soul_select {
    background: linear-gradient(
      180deg,
      rgb(153 216 225 / 100%) 0%,
      rgb(215 177 218 / 100%) 100%
    );
  }

  &.soul_default {
    background-color: #4a4f51;
  }

  .cover_bg {
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 0.2s;
  }

  // &:hover {
  //   .cover_bg {
  //     @include minMD {
  //       transform: scale(1.1);
  //     }
  //   }
  // }
}

.select {
  .cover_bg {
    transform: scale(1.1);
  }
}

.footer {
  &:hover {
    .come_out {
      transform: translateY(-1px);
    }
  }
}

.permanent {
  transform: translateY(-1px);
}
</style>
