import { useAsyncState } from '@vueuse/core'
import { immutableNFTPool, soulCard } from 'src/helpers/blockchain'
import { getSoulToknDataId } from '../utils'
import { useImmutableInfoStore } from 'src/stores/immutable'

export function useLockImmutableSoulCard() {
  const immutableInfoStore = useImmutableInfoStore()
  const { executeSoulCoreMarketplaceTnfoList, executeSoulCoreInfoList } =
    immutableInfoStore
  const { isLoading, execute } = useAsyncState(
    async (item, UserID) => {
      const itemToken = getSoulToknDataId(item)

      await soulCard.writeContract({
        functionName: 'approve',
        args: [immutableNFTPool.contractAddress, itemToken],
      })

      await immutableNFTPool.lock({
        args: [soulCard.contractAddress, itemToken, UserID],
      })
      await Promise.all([
        executeSoulCoreMarketplaceTnfoList(),
        executeSoulCoreInfoList(),
      ])
    },
    null,
    {
      immediate: false,
      onError(error) {
        throw error
      },
    },
  )
  return {
    isLoading,
    execute,
  }
}
