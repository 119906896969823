<script setup>
import { NTooltip, NPopover } from 'naive-ui'
import Countdown from './Countdown.vue'

const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    },
  },
})

const emit = defineEmits(['endTime', 'claim', 'download'])

function secondsToHhMmSs(seconds) {
  let hours = Math.floor(seconds / 3600)
  let minutes = Math.floor((seconds % 3600) / 60)
  let secs = seconds % 60

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  secs = secs < 10 ? '0' + secs : secs

  return `${hours}h ${minutes}m ${secs}s`
}

const tooltipTheme = {
  color: '#2D2924', // 设置背景颜色
  arrowHeight: '10px', // 箭头高度
  arrowWidth: '10px', // 箭头宽度
}
</script>

<template>
  <div class="relative flex flex-col flex-items-center">
    <div
      v-if="data?.v_bo_gx_count"
      class="light-bg absolute top-0 -left-2% w-110% h-640px z-0"
    ></div>
    <div class="w-full mx-8 z-1">
      <div class="fz-5 flex-center">
        <span>Minting Efficiency:</span>
        <span class="ml-2 text-#FFE1A1"
          >{{ data?.show_related?.overall_efficiency || 0 }}%</span
        >
        <NPopover
          class="BlownPopover"
          arrow-class=".BlownPopover-arrow-class"
          placement="bottom"
          :theme-overrides="tooltipTheme"
        >
          <template #trigger>
            <img
              class="mouse_pointer w-23px ml-2"
              src="src/asset/nftCards/introduction2.webp"
              alt=""
            />
          </template>
          <div class="fz-3 flex-center flex-col">
            <div class="w-700px pb-10 px-4 pt-2 text-#fff flex-col">
              <div class="flex mb-4">
                <div>
                  Rate of Influence:
                  <div class="text-#B8AEA1">
                    Reduces the time required to mint $tBOGX.
                  </div>
                </div>
              </div>
              <div class="flex mb-4">
                <div>
                  Privilege Bonuses:
                  <div class="text-#B8AEA1">
                    The minting rate bonuses from Genesis and Ethereal
                    privileges can stack.When Genesis or Ethereal privileges are
                    active, the minting rate bonus from partner privileges will
                    not take effect.
                  </div>
                </div>
              </div>
              <div class="flex flex-col mb-4">
                <span
                  >Genesis Bonus: <span class="text-#B8AEA1">1400%</span></span
                >
                <span
                  >Ethereal Bonus: <span class="text-#B8AEA1">1000%</span></span
                >
                <span
                  >Partner Privilege Bonus:
                  <span class="text-#B8AEA1">500%</span></span
                >
              </div>
              <div class="flex flex-col">
                Additional Bonus:
                <div class="text-#B8AEA1">
                  After using the Minting Acceleration Coupon, the minting rate
                  increases by 500% during the valid period.
                </div>
              </div>
            </div>
          </div>
        </NPopover>
      </div>
      <div class="fz-3 w-full my-2 flex justify-center">
        <span class="mr-15"
          >Single Coin Minting Time:<span class="ml-1 text-#FFE1A1">
            {{
              data?.show_related?.single_token_minting_time
                ? secondsToHhMmSs(data?.show_related?.single_token_minting_time)
                : '00h 00m 00s'
            }}</span
          ></span
        >
        <span
          >Mint Ratio Soul Gem: $tBOGX =
          <span class="ml-1 text-#FFE1A1">1:1</span>
        </span>
      </div>
    </div>
    <div class="text-#FFE1A1 fz-5 flex flex-col flex-items-center z-1">
      <img
        v-if="data?.v_bo_gx_count"
        class="w-80% mt-4"
        src="src/asset/nftCards/doing_bg.webp"
      />
      <img v-else class="w-80% mt-4" src="src/asset/nftCards/empty_bg.webp" />
      <div
        :class="{ 'text-#F98E79': !data?.v_bo_gx_count }"
        class="mt-3 mb-5 tracking-1.2px"
      >
        {{
          data?.v_bo_gx_count
            ? 'THE AI AGENT IS AUTOMATICALLY MINTING TOKENS'
            : 'WITHOUT SOUL GEM, AI AGENT STOPPED MINTING.'
        }}
      </div>
      <!-- 有未解锁的tvbogx或者已解锁的tbogx -->
      <template v-if="data?.v_bo_gx_count || data?.v_bo_gx_unlock_count">
        <div class="-mb-7 ml-12 tracking-1.2px">Mint Successful</div>
        <div class="relative flex flex-items-center">
          <img class="w-67px z-1" src="src/asset/nftCards/tbogx.webp" alt="" />
          <div class="coin-bg-big w-260px h-38px mt-2 pl-12 text-center">
            {{ data?.v_bo_gx_unlock_count || 0 }}
          </div>
          <!-- 有未解锁的tvbogx -->
          <div
            v-if="data?.v_bo_gx_count"
            class="absolute -bottom-1 left-70px flex flex-items-center"
          >
            <div class="fz-3 mt-1">Next needs time:</div>
            <div class="text-center text-#fff ml-2">
              <Countdown
                v-if="data?.unlock_time"
                ref="countdownRef"
                :start-time="data?.unlock_time"
                :end-time="data?.unlock_time"
                :current-time="Date.now() / 1000"
                @end="emit('endTime')"
              ></Countdown>
            </div>
          </div>
        </div>
      </template>
      <!-- 都没有币 -->
      <template v-else>
        <div class="text-#fff mt-12">Go play games to earn Soul Gem.</div>
        <div
          @click="emit('download')"
          class="hover:bg-#5F4337 flex-center mouse_pointer mt-6 w-277px h-56.5px border border-solid border-#AB8573 bg-#3F3029 text-#fff rounded-5px"
        >
          Download
        </div>
      </template>
      <div
        @click="emit('claim')"
        v-if="data?.v_bo_gx_unlock_count"
        class="btn-claim flex-center mouse_pointer mt-4 w-277px h-56.5px text-#fff rounded-5px"
      >
        Claim
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.coin-bg-big {
  margin-left: -67px;

  @include bg100('src/asset/nftCards/big_box.webp');
}

.light-bg {
  @include bg100('src/asset/nftCards/bg_light.webp');
}

.btn-claim {
  background: linear-gradient(90deg, #9b4c29 0%, #a55e2e 100%);

  &:hover {
    background: linear-gradient(90deg, #c36a43 0%, #d0733c 100%);
  }
}
</style>
