import { ref, watch, computed, onUnmounted } from 'vue'
import { useAsyncState } from '@vueuse/core'
import { useMessage } from 'naive-ui'
import { fetchSaleListInfo, fetchRoleList } from 'src/api'
import { errorCodeToMessage } from 'src/helpers/utils'
import { getReceiveJobName } from 'src/pages/myCollection/utils'
import { useUserInfoStore } from 'src/stores/user'
import { useLoginStore } from 'src/stores/user'
import { useNFTCard } from 'src/stores/nftCard'
import { soulJade, soulJadePurchase } from 'src/helpers/blockchain'
import { storeToRefs } from 'pinia'
import { accountRef } from 'src/sdk/blockchain'
import { parseUnits, formatEther } from 'viem'
import { watchAccount } from '@wagmi/core'
import { BaseError, ContractFunctionRevertedError } from 'viem'

export function useImmutableIncome() {
  const { open: login } = useLoginStore()
  const { hasLogin } = storeToRefs(useLoginStore())
  const { userInfo } = storeToRefs(useUserInfoStore())
  const { selectIncomeRoleId } = storeToRefs(useNFTCard())
  const message = useMessage()
  const walletaddr = ref('')
  const productsList = ref([])
  const roleId = ref()
  const roleList = ref([])
  const orderData = ref({})
  const showRoleModal = ref(false)
  const selectId = ref(0)
  const balance = ref(0)
  const showHistoryModal = ref(false)
  const selectedChainId = ref(1)

  // 可选择的区块链网络列表
  const chains = [
    // { label: 'Please select', value: 0 },
    // { label: 'Arbitrum Sepolia', value: 421614 },
    { label: 'Ethereum Mainnet', value: 1 },
    { label: 'Polygon', value: 137 },
  ]

  //BogxMarket address
  const contractMap = {
    1: '0xeeE4eFc194BCA8E19a29879081300CB86AB575BD',
    137: '0xBcDd9f0973d25d3FcC70028f42395F119F785f70',
  }
  //usdt address
  const usdcContractMap = {
    1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    137: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
  }

  async function initPage() {
    soulJade.setChainId(selectedChainId.value)
    soulJade.setContractAddress(usdcContractMap[selectedChainId.value])
    try {
      const data = await soulJade.getBalances({
        functionName: 'balanceOf',
        args: [accountRef?.value.address],
      })
      balance.value = formatEther(data)
    } catch (error) {
      balance.value = 0
      throw error
    }
  }

  const { execute: executeList } = useAsyncState(
    async () => {
      const data = await fetchSaleListInfo({
        web_type: 'soulJade',
      })
      productsList.value = data
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
        errorCodeToMessage(error)
      },
    },
  )

  const { execute: executeRoleList } = useAsyncState(
    async () => {
      const data = await fetchRoleList()
      const list = data.RoleList.map(item => {
        return {
          ...item,
          label: getReceiveJobName(item.Job, item.Name),
          value: item.UserID,
        }
      })
      if (!selectIncomeRoleId.value) {
        if (list?.length > 1) {
          showRoleModal.value = true
        } else if (list?.length === 1) {
          roleId.value = list[0].UserID
        }
      } else {
        if (list.find(item => item.UserID === +selectIncomeRoleId.value)) {
          roleId.value = selectIncomeRoleId.value
        } else {
          roleId.value = ''
        }
      }
      roleList.value = [
        {
          label: 'Please select.',
          value: '',
        },
        ...list,
      ]
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
      },
    },
  )

  const roleName = computed(() => {
    const item = roleList.value?.find(item => item.UserID === roleId.value)
    return item?.label
  })

  function handleSelectHero() {
    const currentList = roleList.value?.slice(1)
    if (currentList?.length > 1) {
      showRoleModal.value = true
    } else if (currentList?.length === 1) {
      return
    } else {
      message.info('your dont have hero')
    }
  }

  function handleConfirm(val) {
    roleId.value = val
    selectIncomeRoleId.value = val
  }

  const { execute: handleBuy, isLoading: loading } = useAsyncState(
    async (data, chainId) => {
      selectId.value = data.id
      try {
        if (!hasLogin.value) {
          login()
          return
        }
        if (!roleId.value) {
          message.warning('Please select hero')
          return
        }
        if (!chainId) {
          message.warning('Please select network')
          return
        }
        const { count, id, price } = data
        productsList.value.find(item => {
          if (item.id === id) {
            item.qty = count
            orderData.value = item
          }
        })
        const productsContractAddress = usdcContractMap[chainId]
        const BogxMarketContractAddress = contractMap[chainId]
        soulJade.setChainId(chainId)
        soulJade.setContractAddress(productsContractAddress)
        soulJadePurchase.setChainId(chainId)
        soulJadePurchase.setContractAddress(contractMap[chainId])
        await soulJade.mint(chainId, count, price, BogxMarketContractAddress)
        await soulJadePurchase.mint(chainId, id, count, roleId.value)
        message.success(
          'Buy success, The product will be dispatched within 1 minute. Check your in-game Mail',
        )
      } catch (error) {
        if (error instanceof BaseError) {
          const revertError = error.walk(
            err => err instanceof ContractFunctionRevertedError,
          )
          if (revertError instanceof ContractFunctionRevertedError) {
            const errorName = revertError.data?.args?.[0] ?? ''
            message.error(errorName)
          }
        }

        if (error.message === '40001')
          message.error(
            'execution reverted: ERC20: transfer amount exceeds balance',
          )
      } finally {
        window.soulJadeProcessing = 0
      }
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log(error)
        errorCodeToMessage(error)
      },
    },
  )

  function handleHistoryModal() {
    if (!roleId.value) {
      message.warning('Please select your hero first')
      return
    }
    showHistoryModal.value = true
  }

  const watchAccountStop = watchAccount(() => {
    if (accountRef.value?.address) {
      initPage()
    }
  })

  onUnmounted(() => {
    watchAccountStop()
  })

  watch(
    userInfo,
    data => {
      if (data?.AccountId) {
        executeRoleList()
      } else {
        selectIncomeRoleId.value = ''
        roleId.value = ''
        roleList.value = [
          {
            label: 'Please select.',
            value: '',
          },
        ]
      }
    },
    {
      immediate: false,
    },
  )
  executeRoleList()
  executeList()
  initPage()
  return {
    walletaddr,
    productsList,
    showRoleModal,
    showHistoryModal,
    roleName,
    roleId,
    roleList,
    loading,
    selectId,
    selectedChainId,
    chains,
    executeList,
    handleSelectHero,
    handleConfirm,
    handleBuy,
    handleHistoryModal,
  }
}
