import { storeToRefs } from 'pinia'
import axios from 'axios'
import { useLoginStore, useUserInfoStore } from 'src/stores/user'
import { AppHttpCode } from '@BOGX/helpers'
import { CookieStorage } from 'src/helpers/cookieStorage'
import { fetchUserInfoP } from 'src/helpers/user.js'
import { encryption } from './encryption'
import { isString, fromPairs, toPairs } from 'lodash-es'
export const baseTokenStorage = new CookieStorage('baseToken', {
  domain: import.meta.env.VITE_COOKIE_DOMAIN,
})
export function requestSuccess(config) {
  if (config.method === 'post') {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  }
  const itemConfig = organizationItemConfig(config)
  return itemConfig
}
export function organizationItemConfig(config) {
  const { userInfo } = storeToRefs(useUserInfoStore())
  if (config.method === 'get') {
    // 接口文档需要
    config.params.type = 1

    if (userInfo.value || baseTokenStorage.value) {
      config.params.token = userInfo.value?.token || baseTokenStorage.value
    }
    if (userInfo.value?.AccountId) {
      config.params.AccountId = userInfo.value?.AccountId
    }
    config.params.hash = encryption(config.params)
  } else {
    if (isString(config.data)) {
      const keyValueArray = config.data.split('&').map(item => item.split('='))
      const data = fromPairs(keyValueArray)
      if (userInfo.value?.AccountId) {
        data.AccountId = userInfo.value?.AccountId
      }
      // if (userInfo.value?.wallet) {
      //   data.wallet = userInfo.value?.wallet
      // }
      data.hash = encryption(data)
      config.data = toPairs(data)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    } else {
      if (userInfo.value?.AccountId) {
        config.data.AccountId = userInfo.value?.AccountId
      }
      config.data.hash = encryption(config.data)
    }
  }
  return config
}

export function tokenInvalidLogin() {
  return fetchUserInfoP.run().catch(openLoginProcess)
}
export function openLogin() {
  window.$message.info('Login expired.')
  const { logOut, open } = useLoginStore()
  logOut()
  return open()
}

export function openLoginProcess() {
  fetchUserInfoP.clear()
  return openLogin().then(tokenInvalidLogin)
}

export function validateSuccess(code) {
  return code === AppHttpCode.SUCCESS
}

export function getResponseData(response) {
  return response.data.data
}

export function creasteRequest() {
  return axios.create({
    // withCredentials: true,
    baseURL: import.meta.env.VITE_REQUEST_API || '',
  })
}
