<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
const letters = 'claiming'.split('')

const getAssetsFile = url => {
  return new URL(`/src/asset/nftCards/loading/${url}`, import.meta.url).href
}

// 动态导入字母的两种状态图片（正常状态和 loading 状态）
const letterStates = {
  c: [getAssetsFile('claim1.webp'), getAssetsFile('claim_active1.webp')],
  l: [getAssetsFile('claim2.webp'), getAssetsFile('claim_active2.webp')],
  a: [getAssetsFile('claim3.webp'), getAssetsFile('claim_active3.webp')],
  i: [getAssetsFile('claim4.webp'), getAssetsFile('claim_active4.webp')],
  m: [getAssetsFile('claim5.webp'), getAssetsFile('claim_active5.webp')],
  n: [getAssetsFile('claim7.webp'), getAssetsFile('claim_active7.webp')],
  g: [getAssetsFile('claim8.webp'), getAssetsFile('claim_active8.webp')],
}

// 设置每个字母的初始图片（正常状态）
const currentImages = ref(letters.map(letter => letterStates[letter][0]))
let intervalId
let currentIndex = 0 // 追踪当前正在切换的字母索引

// 切换字母状态的函数，逐步从第一个字母到最后一个字母切换
const toggleNextLetter = () => {
  const letter = letters[currentIndex]
  const [normalImage, loadingImage] = letterStates[letter]

  // 切换当前字母的图片
  currentImages.value[currentIndex] =
    currentImages.value[currentIndex] === normalImage
      ? loadingImage
      : normalImage

  // 更新索引，确保从头开始循环
  currentIndex = (currentIndex + 1) % letters.length
}

// 启动定时器
onMounted(() => {
  intervalId = setInterval(toggleNextLetter, 150) // 每500毫秒切换一个字母
})

// 清理定时器
onBeforeUnmount(() => {
  clearInterval(intervalId)
})
</script>

<template>
  <div class="loading-container">
    <span v-for="(letter, index) in letters" :key="index">
      <img
        :class="{ 'fade-in': index === currentIndex }"
        class="loading-letter h-64px"
        :src="currentImages[index]"
        :alt="letter"
      />
    </span>
  </div>
</template>

<style lang="scss" scoped>
.loading-letter {
  opacity: 1;
  transition: opacity 0.15s ease-in-out; /* 设置淡入淡出效果 */
}

.fade-in {
  opacity: 1; /* 淡入效果 */
}
</style>
