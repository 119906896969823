<script setup>
import { NTooltip, NPopover } from 'naive-ui'
import { useMint } from './index'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { errorCodeToMessage } from 'src/helpers/utils'
import { NFT_CARDS } from 'src/constant'
import bogx_icon from 'src/asset/t_bogx_white.webp'
import purple_question_mark from 'src/asset/purple_question_mark.webp'
import Header from 'src/pages/myCollection/components/Header'
import Video from 'src/components/Video'
import UpList from '../UpList'
import {
  tokenIdFormat,
  getSoulToknDataId,
} from 'src/pages/myCollection/utils.js'
import { useUpItemList } from '../../hooks'
import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'

const emit = defineEmits(['success', 'close'])
const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    },
  },
})
const immutableInfoStore = useImmutableInfoStore()
const { executeSoulCoreInfoList } = immutableInfoStore
const { tBogxCounts } = storeToRefs(immutableInfoStore)
const { executeGameMint, loadingGameMint, insufficientFundsRef } = useMint()
const tokenIdComputed = computed(() => getSoulToknDataId(props.data))
const userIdComputed = computed(() => props.data?.UserID)
const { upListComputed, consumeComputed } = useUpItemList(
  tokenIdComputed,
  userIdComputed,
)
async function handleMint() {
  try {
    const res = await executeGameMint(0, props.data)
    await executeSoulCoreInfoList()
    emit('success', res)
  } catch (error) {
    executeSoulCoreInfoList()
    errorCodeToMessage(error)
    throw error
  }
}
function handleClose() {
  emit('close')
}
watch(
  [tBogxCounts, consumeComputed],
  ([tbogx, consume]) => {
    insufficientFundsRef.value = tbogx < consume
  },
  {
    immediate: true,
  },
)
const VideoSrc = `${import.meta.env.VITE_FILE_PATH}/title_animation.mp4`
</script>
<template>
  <div class="w-full pb-10">
    <!-- header -->
    <Header class="mb-18" @close="handleClose">
      MINT AS SOUL CARD NFT
      <!-- 标题右侧加一个小问号，Hover 展示文案 -->
      <NTooltip placement="bottom-end">
        <template #trigger>
          <img
            src="src/asset/softPledge/inventory/illustrate.webp"
            class="w-49px ml-4"
          />
        </template>
        <div class="fz-3 flex-center flex-col">
          <span class="mb-6">MINTING INSTRUCTIONS</span>
          <ul class="4xl:w-35vw w-600px text-#899CA5 mb-15">
            <li>1. It costs $tBOGX to mint a Soul Core into a SoulCard NFT.</li>
            <li>2. Soul Card NFTs get additional attributes.</li>
            <li>3. Only Soul Cores with flashing effects can be minted.</li>
            <li>
              4. Flashing Soul Cores can be obtained through Eternal Summons and
              Celebration Event.
            </li>
            <li>
              5. Ways to acquire $tBOGX: Obtain Soul Gem through various in-game
              activities and convert Soul Gem to $tBOGX through Al Agent
              features
            </li>
          </ul>
        </div>
      </NTooltip>
    </Header>
    <!-- body -->
    <div class="flex px-5 pb-5 flex justify-center">
      <div>
        <div
          class="bg-#131314 border-solid border border-#343A41 h-31px fz-3 text-#909AA3 flex-center"
        >
          {{ data.Name }}
        </div>
        <div class="relative cover">
          <Video
            class="video-cover"
            v-if="data.animation_url"
            :controls="false"
            :src="data.animation_url"
            :poster="data.Icon"
            autoplay
            loop
            muted
          ></Video>
          <img v-else :src="data.Icon" alt="" class="w-full h-full" />
          <div class="inset-0 bg-#000 opacity-10 absolute"></div>
        </div>
      </div>
      <div class="mx-2">
        <img src="src/asset/bag_mint_arrow.webp" class="w-634px" />
        <div class="flex-center mx-6">
          <div class="w-full p-2">
            <UpList :list="upListComputed"></UpList>
          </div>
        </div>
      </div>
      <div>
        <div class="soul_select h-31px fz-3 text-#fff flex-center relative">
          <Video
            class="absolute! top-0 bottom-0 left-0 right-0 w-full h-[31px] border-solid border border-#fff"
            :src="VideoSrc"
            :controls="false"
            autoplay
            object-fit:
            cover
            loop
            muted
          ></Video>
          <span class="relative">
            {{ data.Name }}&nbsp;&nbsp;#{{
              tokenIdFormat(getSoulToknDataId(data))
            }}
          </span>
        </div>
        <div class="cover">
          <Video
            class="video-cover"
            v-if="data.animation_url"
            :controls="false"
            :src="data.animation_url"
            :poster="data.Icon"
            autoplay
            loop
            muted
          ></Video>
          <img v-else :src="data.Icon" alt="" class="w-full h-full" />
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="mt-10">
      <div class="flex-center mb-3">
        <div class="relative">
          <div
            class="mint_btn h-[54px] w-[267px] flex items-center justify-around"
            :class="`${loadingGameMint ? ' disabled' : ''}`"
            @click="!loadingGameMint && handleMint()"
          >
            <span>Mint</span>
            <div class="flex flex-items-center">
              <img class="w-34px mr-3" :src="bogx_icon" />
              {{ consumeComputed }}
            </div>
          </div>
          <NPopover placement="top" :theme-overrides="{ color: '#3C2B48' }">
            <template #trigger>
              <img
                :src="purple_question_mark"
                class="w-40px h-40px absolute -right-55px mouse_pointer top-50% -translate-y-50%"
              />
            </template>
            <div class="fz-3 text-#C594EF">
              The cost of Mint will fluctuate based on the total number of mints
              for this type of soul card.
            </div>
          </NPopover>
        </div>
      </div>
      <!-- mint点击不足后，显示，并调整到代币充值页 -->
      <div v-show="insufficientFundsRef" class="text-center text-#E14040">
        <span>Insufficient $BOGX balance. </span>
        <router-link
          target="_blank"
          :to="{ name: NFT_CARDS }"
          class="text-decoration-underline text-#E14040"
          >Go here</router-link
        >
        <span> to get more token.</span>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import '../../../components/mint';

.cover {
  @apply w-432px h-432px border border-solid border-#3E444D border-t-0 overflow-hidden;
}

.soul_select {
  background: linear-gradient(
    180deg,
    rgb(153 216 225 / 100%) 0%,
    rgb(215 177 218 / 100%) 100%
  );
}

.video-js .vjs-tech {
  object-fit: cover !important;
}

.mint_btn {
  color: #fff;
  background: #a031ff;
  border-radius: 5px;

  @apply border border-solid border-#A862FF px-2;
  @extend .fz-7;
  @extend .mouse_pointer;

  @include minMD {
    &:hover {
      background-color: #d46cff;

      @apply border-#E08DFF;
    }
  }
}
</style>
