<script setup>
import { ref, computed } from 'vue'
import { useIntervalFn } from '@vueuse/core'
import Header from 'src/pages/myCollection/components/Header'
import { useUpItemList } from '../../hooks'
import { getSoulToknDataId } from 'src/pages/myCollection/utils.js'
import UpList from '../UpList'
const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    },
  },
})
const loadingTextRef = ref('.')
useIntervalFn(() => {
  let i = loadingTextRef.value.length

  if (i === 6) {
    loadingTextRef.value = '.'.repeat(1)
  } else {
    loadingTextRef.value = '.'.repeat(i + 1)
  }
}, 350)
const tokenIdComputed = computed(() => getSoulToknDataId(props.data))
const userIdComputed = computed(() => props.data?.UserID)
const { upListComputed } = useUpItemList(tokenIdComputed, userIdComputed)
</script>
<template>
  <div class="pb-14">
    <!-- header -->
    <Header :close="false" class="mb-14">
      <div class="text-center relative">
        <span>Soul Card Minting</span>
        <span class="absolute">{{ loadingTextRef }}</span>
      </div>
    </Header>
    <div class="mb-4 text-center text-#A3BACE fz-3">
      After minting, one of the following attributes will be randomly obtained:
    </div>
    <!-- 升级项 -->
    <div class="flex-center">
      <div class="w-75%">
        <UpList :list="upListComputed"></UpList>
      </div>
    </div>
  </div>
</template>
