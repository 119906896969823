<script setup>
import BaseBox from './BaseBox'
import { NFT_SOFT_STAKING } from 'src/constant'
const softPledgeShow = import.meta.env.VITE_SOFT_PLEDGE_SHOW
</script>
<template>
  <BaseBox
    text="NFT SOFT STAKING"
    :routerName="softPledgeShow ? NFT_SOFT_STAKING : undefined"
  ></BaseBox>
</template>
