<script setup>
import { NTable } from 'naive-ui'
import { computed } from 'vue'
const props = defineProps({
  themeOverrides: Object,
})
const tablethemeOverridesComputed = computed(() => {
  const thColor = '#443822'
  const tdColor = '#15130f'
  // const padding = 0
  return {
    // borderColor: '#DCAC81',
    thColor,
    thColorModal: thColor,
    tdColorStriped: '#1F1C18',
    tdColor,
    tdColorModal: tdColor,
    tdTextColor: '#A39883',
    // tdPaddingSmall: 0,
  }
})
</script>
<template>
  <NTable
    flex-height
    striped
    size="small"
    :theme-overrides="tablethemeOverridesComputed"
  >
    <slot></slot>
  </NTable>
</template>
