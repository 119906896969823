import { requestBase } from 'src/helpers/request/index'

/**
 * 获取转盘页面基础信息
 * @returns {
    "code": 0,
    "msg": 0,
    "data": {
        "turntableInfo": [
            {
                "id": 1,//转盘商品唯一id
                "item_id": 1,//网页道具唯一id
                "item_count": 1,//网页道具数量
                "item_name": 1,//网页道具名称
                "item_icon": 1,//
                "game_item_id": 1,
                "probability": 1,
                "describe": 1
            }
        ],
        "PlayingInfo": {
            "integral": "0",
            "play_count": 1,
            "check": false
        }
    }
}
 */
export function fetchLuckyWheelInfo() {
  return requestBase({
    params: {
      code: 72,
    },
  })
}

/**
 * 每日签到
 * @returns {
  //签到成功
  {
    "code": 0,
    "msg": 0,
    "data": {
        "today_attendance": true,
        "increased_points": 1
    }
}
  //今日已签到
  {
    "code": 161,
    "msg": "",
    "data": []
  }
}
 */
export function fetchSignIn(params) {
  return requestBase({
    params: {
      ...params,
      code: 73,
    },
  })
}

/**
 * 轮盘抽奖
 * @returns {
    "code": 0,
    "msg": 0,
    "data": {
        "item_id": 1,
        "item_count": *
    }
}
 */
export function fetchDrawLuckyWheel() {
  return requestBase({
    params: {
      code: 74,
    },
  })
}

/**
 * 获取软质押页面信息
 * @returns {
    "code": 0,
    "msg": 0,
    "data": {
        "points": {
            "lucky": "0",
            "bogx": "0",
            "imx": "0",
            "ustc": "0",
        },
        "nft": {
            "token": "0",
            "type": "0",
            "time": "Y-m-d H:i:s",
            "next_hour": "0",
        }
    }
}
 */
export function fetchSoftPledgeInfo() {
  return requestBase({
    params: {
      code: 76,
    },
  })
}
/**
 * 获取 软质押/每日玩法 历史记录信息
 * @param {*} params {record_type 1日常记录； 2软质押记录,page,rows}
 * @returns {
    "code": 0,
    "msg": 0,
    "data": {
        "title": {
            "total": "一共多少条数据",
            "rows":[{
                "key": "",//log类型
                "log": "",//log
                "time": "Y-m-d H:i:s",//创建时间
                "token": "",//为链上币种质押增加时显示 币种 token
                "type":"",//显示链上币种类型 pfp nft
                "cdkey":"",//该log对应事件产生游戏内道具时，生成游戏内兑换码
                "use":"",//记录该兑换码是否使用过
            },],
        },

    }
}
 */
export function fetchSoftPledgeHistory(params) {
  return requestBase({
    params: {
      ...params,
      code: 77,
    },
  })
}
/**
 * 获取 二测奖励发放详细接口
 *
 */
export function fetchTestReward() {
  return requestBase({
    params: {
      code: 78,
    },
  })
}

/**
 * 获取NFT页面整体信息
 */
export function fetchNFTStakingInfo(params) {
  return requestBase({
    params: {
      ...params,
      code: 79,
    },
  })
}

/**
 * 创建NFT组合
 */
export function fetchAddNFTStakingCombination(params) {
  return requestBase({
    params: {
      ...params,
      code: 80,
    },
  })
}

/**
 * 删除NFT组合
 */
export function fetchDeleteNFTStakingCombination(params) {
  return requestBase({
    params: {
      ...params,
      code: 81,
    },
  })
}

/**
 * 获取未创建组合的NFT列表
 */
export function fetchUnAddNFTList(params) {
  return requestBase({
    params: {
      ...params,
      code: 82,
    },
  })
}

/**
 * pfp镶嵌
 */
export function fetchAddPFP(params) {
  return requestBase({
    params: {
      ...params,
      code: 111,
    },
  })
}

/**
 * pfp解除镶嵌
 */
export function fetchDeletePFP(params) {
  return requestBase({
    params: {
      ...params,
      code: 112,
    },
  })
}

/**
 * 获取pfp未镶嵌列表
 */
export function fetchUnAddPFPList(params) {
  return requestBase({
    params: {
      ...params,
      code: 113,
    },
  })
}
