<script setup>
import item_bg from 'src/asset/events/item_bg.webp'
import bg5_box from 'src/asset/events/staking/bg5.webp'
import bg4_box from 'src/asset/events/staking/bg4.webp'
import bg3_box from 'src/asset/events/staking/bg3.webp'
import bg2_box from 'src/asset/events/staking/bg2.webp'
import bg1_box from 'src/asset/events/staking/bg1.webp'
import { useRouter } from 'vue-router'
import { messageDevelop } from 'src/helpers/utils'
const props = defineProps({
  text: String,
  routerName: String,
})
const router = useRouter()
function goToLink() {
  if (props.routerName) {
    router.push({
      name: props.routerName,
    })
  } else {
    messageDevelop()
  }
}
</script>
<template>
  <div @click="goToLink" class="block min-h-[calc(25px*1.2)]">
    <div class="relative flex-center mouse_pointer item">
      <img :src="item_bg" class="w-[calc(181px*1.2)] relative top-1%" alt="" />
      <img
        :src="bg5_box"
        class="w-[calc(207.5px*1.2)] absolute-center top-53%!"
        alt=""
      />
      <img
        :src="bg4_box"
        class="bg4_box w-[calc(247.6px*1.2)] absolute-center"
        alt=""
      />
      <img
        :src="bg3_box"
        class="bg3_box w-[calc(232px*1.2)] absolute-center"
        alt=""
      />
      <img
        :src="bg2_box"
        class="bg2_box w-[calc(217px*1.2)] absolute-center"
        alt=""
      />
      <img :src="bg1_box" class="w-[calc(204px*1.2)] absolute-center" alt="" />
      <span
        v-if="text"
        class="fz-3 family_bold_bold text-#FFE26C absolute-center w-[calc(180px*1.3)] text-center"
        >{{ text }}</span
      >
      <slot></slot>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import './index';

.item:hover {
  .bg2_box {
    @include animation-box(min-scale);
  }

  .bg3_box {
    @include animation-box;
  }

  .bg4_box {
    @include animation-box(max-scale);
  }

  @keyframes min-scale {
    0% {
      transform: translate(-50%, -50%) scaleX(1);
    }

    45% {
      transform: translate(-50%, -50%) scaleX(0.96);
    }

    65% {
      transform: translate(-50%, -50%) scaleX(0.96);
    }

    100% {
      transform: translate(-50%, -50%) scaleX(1);
    }
  }

  @keyframes max-scale {
    0% {
      transform: translate(-50%, -50%) scaleX(1);
    }

    45% {
      transform: translate(-50%, -50%) scaleX(1.2);
    }

    65% {
      transform: translate(-50%, -50%) scaleX(1.2);
    }

    100% {
      transform: translate(-50%, -50%) scaleX(1);
    }
  }
}
</style>
