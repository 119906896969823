<script setup>
import Modal from 'src/components/Modal'
import Video from 'src/components/Video'
import SocializeLinks from '../NFTPanel/SocializeLinks.vue'
import closeImg from 'src/asset/close.webp'
import { useRouter } from 'vue-router'
import { PFP_MINT_NAME } from 'src/constant'
const router = useRouter()

const nftVideoPath = `${import.meta.env.VITE_FILE_PATH}/nft_video/NFT-LionFace2.mp4`
const nftPosterPath = `${import.meta.env.VITE_FILE_PATH}/nft_video/NFT-LionFace2.webp`
function handleClick() {
  router.push({ name: PFP_MINT_NAME })
}
const emit = defineEmits(['update:show'])
const props = defineProps({})

function handleShow(b) {
  emit('update:show', b)
}
</script>
<template>
  <Modal @update:show="handleShow">
    <div
      class="relative bg-#040405 border border-solid border-#253D48 px-8 pt-5 pb-7 w-82vw item_container"
    >
      <img
        :src="closeImg"
        @click="handleShow(false)"
        class="absolute right-20px top-20px w-24px mouse_pointer"
      />
      <div class="fz-7 tracking-1 flex flex-col items-center">
        <span class="family_blk_bold text-36px flex-1 flex-center">
          DON'T HAVE GENESIS OR ETHEREAL？
        </span>
        <span class="family_th tracking-0.5 text-#F54949 text-18px font-300"
          >Tip: If your NFT is not detected 20 minutes after receipt, please
          contact the staff on
          <a
            class="underline text-#F54949"
            href="https://discord.gg/bogx"
            target="_blank"
            >Discord</a
          >.</span
        >
      </div>
      <div
        class="w-full h-10px bg-#2A2D31 border border-solid border-#616568"
      ></div>
      <div
        class="w-full bg-#15181D/70 pt-64px md:h-540px 2xl:h-760px pb-20px flex justify-around text-36px leading-42px border border-t-0 border-solid border-#616568"
      >
        <div
          class="relative text-#F1C571 text-center flex flex-col flex-items-center md:scale-72 2xl:scale-100 origin-ct"
        >
          <div class="family_th font-300">GO BUY GENESIS</div>
          <div
            class="w-306px h-306px mt-20px relative border border-#F7C76A border-solid overflow-hidden"
          >
            <Video
              :width="302"
              :height="302"
              :src="nftVideoPath"
              :controls="false"
              :poster="nftPosterPath"
              autoplay
              loop
              muted
            ></Video>
          </div>
          <div
            class="absolute left-0 top-334px w-full flex flex-col flex-items-center"
          >
            <div class="triangle w-18px h-18px bg-#EBAA56"></div>
            <div class="-mt-6px bg-#7E582B w-307px px-7px py-6px rounded-5px">
              <SocializeLinks :isMaxHeight="true"></SocializeLinks>
            </div>
          </div>
        </div>
        <div
          class="text-#65E782 text-center flex flex-col flex-items-center md:scale-72 2xl:scale-100 origin-ct"
        >
          <div class="family_th font-300">GO BUY ETHEREALS</div>
          <!-- <div class="family_th font-300">COMING SOON</div> -->
          <div
            class="mt-20px w-306px h-306px border border-solid border-#73FC90"
          >
            <img
              class="w-full h-full"
              src="src/asset/nftSoftStaking/deck/new/green_pfp.webp"
              alt=""
            />
          </div>
          <div
            class="absolute left-0 top-334px w-full flex flex-col flex-items-center"
          >
            <div class="triangle w-18px h-18px bg-#EBAA56"></div>
            <div class="-mt-6px bg-#7E582B w-307px px-7px py-6px rounded-5px">
              <SocializeLinks
                :isMaxHeight="true"
                :isPFPLink="true"
              ></SocializeLinks>
            </div>
          </div>
          <!-- <div class="mt-12px text-#F54949 text-center fz-6">Upcoming</div> -->
          <!-- <div
            @click="handleClick"
            class="btn-mint color-#fff flex-center mouse_pointer w-305.5px h-56px text-32px"
          >
            GO MINT
          </div> -->
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.btn-mint {
  @include bg100('src/asset/nftSoftStaking/deck/new/btn_bg.webp');

  transition: all 0.1s;
}

.underline {
  text-decoration: underline;
}

.triangle {
  clip-path: polygon(0 0, 100% 0, 50% 50%);
}
</style>
