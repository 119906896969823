<script setup>
import { storeToRefs } from 'pinia'
import { useAsyncState } from '@vueuse/core'
import { NDivider, useMessage } from 'naive-ui'
import { useChangePasswordStore, useLoginStore } from 'src/stores/user'
import { ref } from 'vue'
import { fetchChangePassword } from 'src/api'
import { codeToMessage } from '@BOGX/helpers'
import { hasPhone } from 'src/stores/media'
import Modal from '../Modal.vue'

import ChangePassword from './ChangePassword'

const changePasswordRef = ref(null)

const message = useMessage()
const { open: login } = useLoginStore()
const { hasLogin } = storeToRefs(useLoginStore())
const changePasswordStore = useChangePasswordStore()
const { success: changePasswordSuccess, errorClose: errorCloseChangePassword } =
  changePasswordStore
const { show: changePasswordShow } = storeToRefs(changePasswordStore)

const { isLoading, execute: executechangePassword } = useAsyncState(
  async data => {
    const res = await fetchChangePassword(data)
    handleChangePasswordSuccess(res)
    return null
  },
  null,
  {
    immediate: false,
    onError(error) {
      message.error(codeToMessage(error?.data?.code))
    },
  },
)

function handleChangePasswordSuccess(res) {
  message.success('修改密码成功')
  changePasswordSuccess(res)
}

async function handleSubmit(e) {
  const data = await changePasswordRef.value.handleValidate(e)
  executechangePassword(0, data)
}

function openLogin() {
  login()
  errorCloseChangePassword()
}
</script>

<template>
  <Modal :show="changePasswordShow" @update:show="errorCloseChangePassword">
    <div class="user_modal">
      <span class="modal_triangle_top top-0"></span>
      <img
        src="/src/asset/labs_logo.webp"
        alt=""
        class="logo mb-2 md:mb-[18px]"
      />
      <div class="fz-2 mb-7 text-center text-white md:mb-[27px]">
        <template v-if="!hasPhone">PLEASE RESET YOUR PASSWORD</template>
        <NDivider v-else class="m-0!">
          <span>PLEASE RESET YOUR PASSWORD</span>
        </NDivider>
      </div>
      <ChangePassword
        ref="changePasswordRef"
        class="mb-1"
        :disabled="isLoading"
      />
      <div class="mb-[34px]">
        <div class="flex-sb">
          <div
            class="item-btn-black user_btn"
            @click="errorCloseChangePassword"
          >
            Cancel
          </div>
          <div class="item-btn-gray user_btn" @click="handleSubmit">Reset</div>
        </div>
      </div>
      <div v-if="!hasLogin">
        <div class="fz-2 gray-color text-center">
          Already have
          <span class="text-white">VOIDLABS</span> account?
          <span class="orange-color mouse_pointer" @click="openLogin"
            >Login here</span
          >
          .
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import './common';
</style>
