<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { gsap } from 'gsap'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { hasPhone } from 'src/stores/media'
import { getHasRouterActivation } from 'src/helpers/utils'
import TBogxModel from 'src/pages/myCollection/components/TBogxModel'
import TBogxDetail from 'src/pages/myCollection/components/TBogxDetail'
// import { lineAngleW, lineBottomConnectW } from 'src/pages/home/constant'
import Menu from 'src/components/user/Menu.vue'
import UserInfoUtils from 'src/components/user/UserInfoUtils'

const route = useRoute()

const { tBogxCounts } = storeToRefs(useImmutableInfoStore())
const hornDomRef = ref(null)
// const domAttributeReactive = reactive({
//   opacityLine: 1,
//   lineBottomRect: 0,
//   lineAngleRect: lineAngleW,
// })

let hoverIng = false
let hornXTl, hornOpacityTL
// let hornXTl, hornOpacityTL, bottomLineTl, obliqueLineTl, opacityTl

function initHover() {
  const options = {
    ease: 'none',
    paused: true,
  }
  hornOpacityTL = gsap.to(hornDomRef.value, {
    ...options,
    opacity: 1,
    duration: 0.3,
  })
  hornXTl = gsap.to(hornDomRef.value, {
    ...options,
    x: '-20%',
    duration: 0.5,
    onComplete() {
      if (hoverIng) {
        hornXTl.reverse()
      }
    },
    onReverseComplete() {
      if (hoverIng) {
        hornXTl.play()
      }
    },
  })
  // bottomLineTl = gsap.to(domAttributeReactive, {
  //   ...options,
  //   lineBottomRect: lineBottomConnectW,
  //   duration: 0.4,
  // })
  // obliqueLineTl = gsap.to(domAttributeReactive, {
  //   ...options,
  //   lineAngleRect: 0,
  //   duration: 0.1,
  //   onComplete() {
  //     bottomLineTl.play()
  //   },
  // })

  // opacityTl = gsap.to(domAttributeReactive, {
  //   opacityLine: 0,
  //   duration: 0.3,
  //   onComplete() {
  //     bottomLineTl.progress(0).pause()
  //     obliqueLineTl.progress(0).pause()
  //     opacityTl.progress(0).pause()
  //   },
  // })
}
function handleMouseenter() {
  hoverIng = true
  hornOpacityTL.play()
  hornXTl.play()
  // obliqueLineTl.play()
}
function handleMouseleave() {
  hoverIng = false
  hornOpacityTL.reverse()
  hornXTl.reverse()
  // opacityTl.play()
}

const walletShowRef = ref(false)
function handleShow() {
  walletShowRef.value = true
}
function handleTBogxDetailClose() {
  walletShowRef.value = false
}

const showTbogxRef = computed(
  () =>
    getHasRouterActivation(route.matched, 'gameBanefits') ||
    getHasRouterActivation(route.matched, 'PFPEthereal'),
)

onMounted(() => {
  !hasPhone && initHover()
})
onUnmounted(() => {
  hornXTl?.kill()
  hornOpacityTL?.kill()
  // bottomLineTl?.kill()
  // obliqueLineTl?.kill()
  // opacityTl?.kill()
})
</script>

<template>
  <div
    class="mouse_pointer right-9 top-0 relative md:absolute z-2"
    @mouseenter="!hasPhone && handleMouseenter()"
    @mouseleave="!hasPhone && handleMouseleave()"
  >
    <div class="flex flex-items-end">
      <template v-if="!showTbogxRef">
        <div @click="handleShow" class="relative z-2">
          <img
            ref="hornDomRef"
            src="/src/asset/top_left_jiao.webp"
            class="relative w-[8px] rotate-y-180 opacity-100 md:w-[10px] md:opacity-0"
            alt=""
          />
          <span class="fz-3 ml-0.25 mr-1 md:ml-1.5 md:mr-1.5 text-#8B97A1"
            >$tBOGX</span
          >
          <img
            src="/src/asset/t_bogx.png"
            class="w-[14px] align-text-bottom md:w-[22.5px]"
            alt=""
          />
          <span class="fz-3 ml-0.25 text-[#FFFFFF] md:ml-1.5">{{
            tBogxCounts
          }}</span>
        </div>
        <div class="bg-#BCC6CD/25 h-23px w-0.5px ml-20px mr-20px"></div>
        <Menu
          :class="{ 'mt-1.5': showTbogxRef }"
          class="mouse_pointer fz-3 mb-2px"
        ></Menu>
      </template>
      <template v-else>
        <div class="-mt-10px!">
          <UserInfoUtils></UserInfoUtils>
        </div>
      </template>
    </div>
    <!-- <div
      class="line z-2 w-[192px] translate-x-[-10%]"
      :style="{
        clip: `rect(auto, ${domAttributeReactive.lineBottomRect}px, auto, auto)`,
        opacity: domAttributeReactive.opacityLine,
        width: `${lineBottomConnectW}px`,
      }"
    ></div>
    <div
      class="line oblique"
      :style="{
        clip: `rect(auto, auto, auto, ${domAttributeReactive.lineAngleRect}px)`,
        opacity: domAttributeReactive.opacityLine,
        width: `${lineAngleW}px`,
      }"
    ></div> -->
    <TBogxModel
      v-if="!hasPhone"
      v-model:show="walletShowRef"
      :counts="tBogxCounts"
    ></TBogxModel>
    <TBogxDetail
      v-if="hasPhone"
      v-show="walletShowRef"
      :counts="tBogxCounts"
      @close="handleTBogxDetailClose"
    ></TBogxDetail>
  </div>
</template>

<style scoped>
.line {
  @apply absolute border-dotted h-[1px] border-0 border-b-1;
}

.oblique {
  bottom: 22%;
  left: -14%;
  transform: rotate(-45deg);
}

.hidden-box {
  bottom: -6%;
  left: -15%;
}
</style>
