import { requestDiscord, requestOkx } from 'src/helpers/request'
import { getClientData, thirdPartyDiscord } from 'src/sdk/thirdParty/discord'
// 获取token
export function fetchDiscordToken(data) {
  return requestDiscord.post('/oauth2/token', data)
}
// 刷新
export function fetchDiscordRefreshToken() {
  return fetchDiscordToken({
    grant_type: 'refresh_token',
    refresh_token: thirdPartyDiscord.tokenData.value?.refresh_token,
    ...getClientData(),
  })
}

// 注销
export function fetchDiscordRevokeAccessToken() {
  return requestDiscord.post('/oauth2/token/revoke', {
    token: thirdPartyDiscord.tokenData.value?.access_token,
    token_type_hint: 'access_token',
    ...getClientData(),
  })
}

/**
 * 获取nft列表
 * @param {object} params
 * @param {chain} String 链名称
 * @param {contractAddress} String 合约地址
 * @param {cursor} ​​String 指向要检索的页面的游标
 * @param {limit} ​​String 分页大小（默认值 300，最大 300）
 * @returns
 */
export function fetchOkxMarketplaceNftList(params) {
  return requestOkx.get('/api/v5/mktplace/nft/asset/list', {
    params: {
      chain: 'eth',
      contractAddress: '0x3e0361c7e8c3c87b2fa55cc042648bec9990fcd6',
      ...params,
    },
  })
}

/**
 * 获取nft详情
 * @param {object} params
 * @param {string} chain 链名称
 * @param {string} tokenId NFT 的 tokenId
 * @returns
 */
export function fetchOkxMarketplaceNftDetail(params) {
  return requestOkx.get('/api/v5/mktplace/nft/asset/detail', {
    params: {
      chain: 'eth',
      contractAddress: '0x3e0361c7e8c3c87b2fa55cc042648bec9990fcd6',
      ...params,
    },
  })
}

/**
 * 查询挂单
 * @param {object} params
 * @param {string} chain 链名称
 * @param {string} tokenId NFT 的 tokenId
 * @param {string} platform 目标挂单平台
 * @param {string} cursor 指向要检索的页面的游标
 * @param {string} limit 分页大小（默认值 300，最大 300）
 * @param {string} startTime 起始时间
 * @param {string} endTime 结束时间
 * @returns
 */
export function fetchOkxMarketplaceNftListings(params) {
  return requestOkx.get('/api/v5/mktplace/nft/markets/listings', {
    params: {
      chain: 'eth',
      collectionAddress: '0x3e0361c7e8c3c87b2fa55cc042648bec9990fcd6',
      ...params,
    },
  })
}

// export async function fetchDiscordInfo() {
//   const response = await fetch('https://discord.com/api/v10/users/@me', {
//     headers: {
//       Authorization: `Bearer PLxfhYktPJPo14msULq1cHM2X0AJoS`,
//     },
//   })

//   if (!response.ok) {
//     throw new Error('Failed to fetch user information')
//   }

//   const userInfo = await response.json()
//   return userInfo
// }
// window.fetchDiscordInfo = fetchDiscordInfo
