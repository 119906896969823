<script setup>
import { onMounted, ref, onBeforeUnmount, watch } from 'vue'
import lottie from 'lottie-web'

const props = defineProps({
  animationData: {
    type: Object,
    required: true,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
  ishover: {
    type: Boolean,
    default: false,
  },
  speed: {
    type: Number,
    default: 1,
  },
  isEnter: {
    type: Boolean,
    default: false,
  },
})

const lottieContainer = ref(null)
let lottieInstance = null

// 节流函数：限制函数在指定时间内只能触发一次
function throttle(func, delay) {
  let lastCall = 0
  return (...args) => {
    const now = new Date().getTime()
    if (now - lastCall >= delay) {
      lastCall = now
      func(...args)
    }
  }
}

watch(
  () => props.isEnter,
  val => {
    if (val) {
      lottieInstance.goToAndStop(0, true) // 初始化时停在第 1 帧
      lottieInstance.setSpeed(props.speed)
      lottieInstance.playSegments([0, 15], true) // 播放第 0 到 15 帧
    } else {
      lottieInstance.setSpeed(props.speed)
      lottieInstance.playSegments([15, 0], true) // 返回到第 1 帧
    }
  },
)

onMounted(() => {
  lottieInstance = lottie.loadAnimation({
    container: lottieContainer.value, // 渲染动画的 DOM 元素
    animationData: props.animationData, // 传入的 Lottie 动画 JSON 数据
    renderer: 'svg', // 使用 SVG 渲染
    loop: props.loop, // 是否循环播放
    autoplay: props.autoplay, // 是否自动播放
  })

  // 鼠标进入事件（使用节流）
  lottieContainer.value.addEventListener(
    'mouseenter',
    throttle(() => {
      if (props.ishover) {
        lottieInstance.setSpeed(props.speed)
        lottieInstance.playSegments([0, 15], true) // 播放第 0 到 15 帧
      }
    }, 500), // 节流 500ms
  )

  // 鼠标移出事件（使用节流）
  lottieContainer.value.addEventListener(
    'mouseleave',
    throttle(() => {
      if (props.ishover) {
        lottieInstance.setSpeed(props.speed)
        lottieInstance.playSegments([15, 0], true) // 返回到第 1 帧
      }
    }, 500), // 节流 500ms
  )
})

onBeforeUnmount(() => {
  if (lottieInstance) {
    lottieInstance.destroy() // 组件卸载时销毁动画实例
  }
})
</script>

<template>
  <div ref="lottieContainer" class="lottie-container"></div>
</template>

<style scoped>
.lottie-container {
  width: 100%;
  height: 100%;
}
</style>
