import { defineStore, storeToRefs } from 'pinia'
import { ref, watchEffect, computed, watch } from 'vue'
import { loginOut } from 'src/sdk/immutable'
import {
  fetchLockedFlashcardsForAllRolesInAccount,
  fetchTBogxConaumeList,
} from 'src/api'
import { fetchSbtInfo } from 'src/api/web3'
import { useAsyncState } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { getHasRouterActivation } from 'src/helpers/utils'
import { getTBogx } from 'src/sdk/immutable'
import { useUserInfoStore } from 'src/stores/user'
import {
  attributesToJson,
  getImmutableInfo,
  immutableFTPool,
  immutableNFTPool,
  soulCard,
} from 'src/helpers/blockchain'
import { ATTRIBUTES } from '@BOGX/web3Data'
import { IMMUTABLE_COLLECTION_NAME } from 'src/constant'
import {
  getResponseWaitState,
  getSoulToknDataId,
} from 'src/pages/myCollection/utils'

export const useImmutableInfoStore = defineStore(
  'immutableInfo',
  () => {
    const route = useRoute()
    const immutableInfo = ref(null)
    const { userInfo } = storeToRefs(useUserInfoStore())
    const soulCoreInfoLockList = ref([])
    const soulCoreInfoMarketplaceListRef = ref([])
    const soulCoreInfoMarketplaceListComputed = computed(() => {
      return soulCoreInfoMarketplaceListRef.value.map(data => {
        const attributes = attributesToJson(data[ATTRIBUTES])
        const skData = dataToSK(attributes)
        // UserID: '',
        return {
          info: {
            ...data,
            type: 3,
            responseWait: getResponseWaitState(attributes.State),
            Id: data.tokenId,
            Name: data.name,
            Icon: data.image,
            Quality: attributes.Quality,
            exp: attributes.Exp,
            Lv: attributes.Lv,
            StarLv: attributes['Star Lv'],
            ProgLv: attributes.ProgLv,
            ItemType: attributes.ItemType,
            Desc: attributes.Desc,
            state: attributes.State,
            ...skData,
            mint: {
              tokenid: data.tokenId,
              me: {
                desc: attributes['Mint Attributet'],
              },
            },
          },
        }
      })
    })
    const consumeTbogxList = ref([])
    const tBogxCounts = ref('0')
    const sbtInfoRef = ref(null)
    const account = computed(
      () => userInfo.value?.bindAccountInfo?.immutableWallet,
    )
    const { execute: executeConsumeTbogxList } = useAsyncState(
      async () => {
        const data = await fetchTBogxConaumeList()
        consumeTbogxList.value = data
      },
      null,
      {
        immediate: false,
      },
    )
    const { execute: executeSoulCoreInfoList } = useAsyncState(
      async () => {
        const data = await fetchLockedFlashcardsForAllRolesInAccount()
        data.SoulCoreInfos.forEach(item => {
          item.info.type =
            item.info?.mint?.me?.id || [5, 2].includes(item.state) ? 2 : 1
          item.info.responseWait = getResponseWaitState(item.state)
        })
        soulCoreInfoLockList.value = data.SoulCoreInfos
        addNFTList(data.SoulCoreInfos.map(getSoulToknDataId))
      },
      null,
      {
        immediate: false,
      },
    )
    async function addNFTList(tokenIds) {
      const list = await soulCard.tokenIdsToNFTList(tokenIds)
      soulCoreInfoLockList.value.forEach((item, index, arr) => {
        const tokenId = getSoulToknDataId(item)
        const nft = list.find(nft => getSoulToknDataId(nft) === Number(tokenId))
        if (nft) {
          arr[index] = {
            ...nft,
            ...item,
          }
        }
      })
    }
    const { execute: executeImmutableInfo } = useAsyncState(
      async () => {
        sbtInfoRef.value = await fetchSbtInfo()
        const { chainId, tBogx: contractAddress, provider } = sbtInfoRef.value
        const data = await getTBogx({
          account: account.value,
          chainId,
          contractAddress,
          provider,
        })
        console.log('tBogxCounts:', data)
        tBogxCounts.value = data ?? '0'
      },
      null,
      {
        immediate: false,
      },
    )
    watch(sbtInfoRef, info => {
      if (info) {
        const { chainName, chainId } = getImmutableInfo(info.chainId)
        soulCard.setContractAddress(info.soulCard)
        immutableNFTPool.setContractAddress(info.nftPool)
        immutableFTPool.setContractAddress(info.ftPool)
        const ercList = [soulCard, immutableNFTPool, immutableFTPool]
        ercList.forEach(erc => {
          erc.chainName = chainName
          erc.setChainId(chainId)
        })
        executeSoulCoreMarketplaceTnfoList()
      }
    })

    const { execute: executeSoulCoreMarketplaceTnfoList } = useAsyncState(
      async () => {
        if (soulCard.chainId && account.value) {
          const data = await soulCard.fetchMyNFTList()
          // filter防止没有正确附加元数据的nft导致bug
          soulCoreInfoMarketplaceListRef.value = data
        }
      },
      null,
      {
        immediate: false,
        onError(error) {
          console.log('immutableNFT-Error:', error)
        },
      },
    )
    watchEffect(() => {
      if (getHasRouterActivation(route.matched, IMMUTABLE_COLLECTION_NAME)) {
        executeConsumeTbogxList()
        executeSoulCoreInfoList()
        if (account.value) {
          executeImmutableInfo()
        }
      }
    })
    async function logOut() {
      soulCoreInfoLockList.value = []
      consumeTbogxList.value = []
      soulCoreInfoMarketplaceListRef.value = []
      tBogxCounts.value = '0'
      immutableInfo.value && (await loginOut())
      immutableInfo.value = null
    }
    return {
      immutableInfo,
      soulCoreInfoLockList,
      soulCoreInfoMarketplaceList: soulCoreInfoMarketplaceListComputed,
      consumeTbogxList,
      sbtInfo: sbtInfoRef,
      tBogxCounts,
      immutableCurrentBindAccount: account,
      logOut,
      executeSoulCoreInfoList,
      executeSoulCoreMarketplaceTnfoList,
      executeImmutableInfo,
    }
  },
  {
    persist: true,
  },
)
function dataToSK(data) {
  const result = {}
  let index = 0
  Object.keys(data).forEach(key => {
    const value = data[key]
    const arr = matchSK(value)
    if (arr) {
      result['sk' + ++index] = {
        lv: arr[1],
        lvMax: arr[2],
        name: key,
      }
    }
  })
  return result
}
function matchSK(text) {
  const regex = /Lv\.(\d+) \/ Lv\.(\d+)/
  return text.toString().match(regex)
}
