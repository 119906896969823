import { fetchCardMetadataDetail } from 'src/api'
import { Immutable } from '../immutable'
// import abi from 'src/sdk/blockchain/abi/soulCardAbi.json'
import abi from 'src/sdk/blockchain/abi/AirdropNFT.json'

export class SoulCard extends Immutable {
  constructor() {
    super()
    this.setAbi(abi)
  }
  /**
   *
   * @param {Array<bigInt | string | number>} tokenIds
   * @returns Array<object>
   */
  async tokenIdsToNFTList(tokenIds) {
    const data = await Promise.all(
      tokenIds.map(id => fetchCardMetadataDetail({ tokenId: id })),
    )
    return data.filter(item => Object.keys(item).length)
  }
  /**
   * 获取仓库的NFT列表
   * @returns Array<object>
   */
  async fetchMyNFTList() {
    const tokenIds = await this.getTokenIds()
    return this.tokenIdsToNFTList(tokenIds)
  }
  /**
   * 获取最新的数个nft，用于购买成功成功后，弹窗调用
   * @param {string | number} number
   * @returns Array<object>
   */
  async fetchNewNFTList(number) {
    const tokenIds = await this.getNewTokenIds({
      number,
    })
    return this.tokenIdsToNFTList(tokenIds)
  }
  /**
   * 获取最新的nft，用于购买成功成功后，弹窗调用
   * @param {string | number} number
   * @returns Array<object>
   */
  async fetchNewNFT() {
    const tokenId = await this.getNewTokenId()
    const [nft] = await this.tokenIdsToNFTList([tokenId])
    return nft
  }
}

export const soulCard = new SoulCard()
// window.soulCard = soulCard
// soulCard.setContractAddress('0x403a82e314bdE59fb01D199bB4d62Fee33EC58c7')
// soulCard.setContractAddress('0x606ca36aBc34c11D0c815f3E5AFf9Cc1adEA7C00')
