import axios from 'axios'
import { storeToRefs } from 'pinia'
import { useUserInfoStore } from 'src/stores/user'

export const requestWeb3 = axios.create({
  // withCredentials: true,
  baseURL: import.meta.env.VITE_REQUEST_WEB3_API || '',
})
// export fu
requestWeb3.interceptors.response.use(
  response => {
    if (response.data.code === 1) {
      return response.data.data
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    console.log(`error.response${error.response}`)
    if (error.response) {
      // const { userInfo } = storeToRefs(useUserInfoStore())
      // const { open: login } = storeToRefs(useLoginStore())
      switch (error.response.status) {
        case 401:
          console.log(`error.response.status${error.response.status}`)
        // 返回 401 清除token信息并跳转到登录页面
        // userInfo.value = null
        // login()
      }
    }
    return Promise.reject(error) // 返回接口返回的错误信息
  },
)

requestWeb3.interceptors.request.use(
  async config => {
    if (config.method === 'post') {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    if (!config.params) config.params = {}
    // config.params.address = accountRef.value?.address
    const { userInfo } = storeToRefs(useUserInfoStore())
    if (userInfo.value?.wallet && !config.params.address) {
      config.params.address = userInfo.value?.wallet
    }
    return config
  },
  err => {
    return Promise.reject(err)
  },
)
