import axios from 'axios'

const JSON_PATH = 'json'
export function fetchDownloadBOGX() {
  return axios
    .get(`${import.meta.env.VITE_WEBRES_PATH}/${JSON_PATH}/downloadBOGX.json`, {
      responseType: 'json',
    })
    .then(({ data }) => data)
}
