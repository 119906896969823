<script setup>
import { gsap } from 'gsap'
import { computed, onUnmounted, ref, watch, watchEffect } from 'vue'
const props = defineProps({
  show: Boolean,
  manual: Boolean,
  color: {
    type: String,
    default: '#fff',
  },
})
const emit = defineEmits(['show', 'update:show'])
const activateRef = ref(false)
const colorRef = computed(() => (activateRef.value ? props.color : 'inherit'))
const arrowRef = ref(0)
const lineRef = ref(100)
const baseGsapOptions = {
  paused: true,
  duration: 0.4,
}
const arrowTl = gsap.to(arrowRef, {
  ...baseGsapOptions,
  value: 80,
  onComplete() {
    if (activateRef.value) {
      this.reverse()
    }
  },
  onReverseComplete() {
    if (activateRef.value) {
      this.play()
    }
  },
})
const lineTl = gsap.to(lineRef, {
  ...baseGsapOptions,
  value: 0,
})
function handleMouseenter() {
  activateRef.value = true
  emit('show', true)
  emit('update:show', true)
  arrowTl.play()
  lineTl.play()
}
function handleMouseleave() {
  activateRef.value = false
  emit('show', false)
  emit('update:show', false)
  arrowTl.reverse()
  lineTl.reverse()
}
watchEffect(() => {
  if (!props.manual) return
  props.show ? handleMouseenter() : handleMouseleave()
})
// watch(
//   activateRef,
//   value => {
//     console.log('value', value)

//     if (value) {
//       arrowTl.play()
//       lineTl.play()
//     } else {
//       arrowTl.reverse()
//       lineTl.reverse()
//     }
//   },
//   {
//     immutable: true,
//   },
// )
onUnmounted(() => {
  arrowTl?.kill()
  lineTl?.kill()
})
</script>

<template>
  <div
    class="flex-center relative"
    @mouseenter="manual || handleMouseenter()"
    @mouseleave="manual || handleMouseleave()"
  >
    <div
      class="line line_left"
      :style="{ 'clip-path': `inset(0 0 -1px ${lineRef}%)` }"
    ></div>
    <span
      class="fz-2 relative mr-1.5"
      :style="{
        transform: `translateX(${-arrowRef}%)`,
        color: colorRef,
      }"
      >&lt;</span
    >
    <slot :activate="activateRef"></slot>
    <span
      class="fz-2 relative ml-1.5"
      :style="{
        transform: `translateX(${arrowRef}%)`,
        color: colorRef,
      }"
      >&gt;</span
    >
    <div
      class="line line_right"
      :style="{
        'clip-path': `inset(0 ${lineRef}% -1px 0)`,
      }"
    ></div>
  </div>
</template>
<style lang="scss" scoped>
.line {
  @apply absolute h-1px w-50px;
}

.line_left {
  @apply left-[-70px];

  background: linear-gradient(to right, #757a7e, #fff);
}

.line_right {
  @apply right-[-70px];

  background: linear-gradient(to left, #757a7e, #fff);
}
</style>
