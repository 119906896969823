import {
  createWeb3Modal,
  defaultWagmiConfig,
  useWeb3Modal,
} from '@web3modal/wagmi/vue'
import { ConnectionController, EventsController } from '@web3modal/core'
import { createWalletClient, custom } from 'viem'
import { signMessage } from '@wagmi/core'
import { arbitrumSepolia, mainnet } from 'viem/chains'
import { getWallets } from '@abmao/wallet-store'

import { projectId } from './config'
import { accountRef } from './store'
import { newCreatePublicClient } from './newBlockchain/walletConnect'
export function setWeb3Modal() {
  // 2. Create wagmiConfig
  const metadata = {
    name: import.meta.env.VITE_TITLE,
    description: import.meta.env.VITE_TITLE,
    url: window.location.origin,
  }

  const chains = [arbitrumSepolia, mainnet]
  const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

  // 3. Create modal
  createWeb3Modal({
    wagmiConfig,
    projectId,
    chains,
    themeMode: 'dark',
    enableAnalytics: true,
  })
}

export function hasConnectWallet() {
  const account = accountRef.value
  return (
    account &&
    account.address &&
    account.isConnected &&
    account.connector !== null
  )
}
export async function hasBlockchainLogin() {
  if (hasConnectWallet()) {
    return true
  } else {
    const { open } = useWeb3Modal()
    await open()
    return false
  }
}
export async function getSign(message) {
  const account = accountRef.value
  if (!account.connector) {
    setWeb3Modal()
  }

  try {
    // 签名
    const signature = await signMessage({
      message,
    })
    // 获取account
    return signature
  } catch (error) {
    // const { open, close } = useWeb3Modal()
    // open()
    throw error
  }

  // 切换链
}
export function getCurrentWallet() {
  const rdns = localStorage.getItem('wagmi.connectedRdns')
  if (rdns) {
    const wallets = getWallets()
    return wallets.find(provider => `"${provider.info.rdns}"` === rdns)
  }
}
export function getCurrentProvider() {
  const wallet = getCurrentWallet()
  return wallet?.provider || window.ethereum
}
export function getTransport() {
  return custom(getCurrentProvider())
}
export function hasTestChainID(chainId) {
  return arbitrumSepolia.id === chainId
}
export async function createClient(
  chainId,
  accountAddress = accountRef.value?.address,
  transport = getTransport(),
) {
  await hasBlockchainLogin()
  const walletClient = createWalletClient({
    chain: { id: chainId },
    transport,
    account: accountAddress,
  })
  try {
    console.log('switchChain')
    await walletClient.switchChain({ id: chainId })
  } catch (error) {
    console.error('switchChain', error)
    // 测试网络
    if (hasTestChainID(chainId)) {
      await walletClient.addChain({ chain: arbitrumSepolia })
      await walletClient.switchChain({ id: chainId })
    }
  }
  console.log('switchChain2')

  const publicClient = newCreatePublicClient(chainId, transport)
  return {
    publicClient,
    walletClient,
  }
}

export async function disconnectWeb3modal() {
  await ConnectionController.disconnect()
  EventsController.sendEvent({ type: 'track', event: 'DISCONNECT_SUCCESS' })
}
