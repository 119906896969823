import {
  bindAddressExist,
  immutableUnbindErrorText,
  insufficientBalanceErrorText,
} from 'src/sdk/blockchain'
import {
  BaseBlockchain,
  baseBlockchainParams,
  useDecorator,
} from './blockchain'
import {
  blockchainDataClient,
  fetchAuth,
  getImmutableTransport,
} from 'src/sdk/immutable'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { storeToRefs } from 'pinia'

export class Immutable extends BaseBlockchain {
  constructor() {
    super()
  }
  /**
   * 获取个人所有tokenid
   */
  getTokenIds(args) {
    return blockchainDataClient.listNFTsByAccountAddress(args).then(res => {
      return res.result.map(data => data.token_id)
    })
  }
  /**
   * 获取最新的数个tokenid
   */
  getNewTokenIds(args) {
    return this.getTokenIds({
      ...args,
      pageSize: args.pageSize || args.number,
    })
  }
  /**
   * 获取最新的tokenid
   */
  getNewTokenId(args) {
    return this.getTokenIds({
      ...args,
      pageSize: 1,
    })
  }

  writeContract(data) {
    return super.writeContract(data)
  }

  readContract(data) {
    return super.readContract(data)
  }
}
const ImmutableProty = Immutable.prototype
useDecorator(ImmutableProty, 'getTokenIds', baseBlockchainParams)
useDecorator(ImmutableProty, 'getTokenIds', immutableParams)
// useDecorator(ImmutableProty, 'getTokenIds', verifyWallet)
useDecorator(ImmutableProty, 'writeContract', immutableParams)
useDecorator(ImmutableProty, 'writeContract', verifyWallet)
useDecorator(ImmutableProty, 'readContract', immutableParams)
// useDecorator(ImmutableProty, 'readContract', verifyWallet)

export function immutableParams(target, name, descriptor) {
  const oldValue = descriptor.value
  descriptor.value = async function (args) {
    const { immutableCurrentBindAccount } = storeToRefs(useImmutableInfoStore())
    const accountAddress = immutableCurrentBindAccount.value
    // const accountAddress = '0x80ae0124507f5419c83907a430041082ac4e0e95'
    // if (!accountAddress) {
    //   throw new Error(insufficientBalanceErrorText)
    // }
    return oldValue.call(this, {
      chainName: this.chainName,
      accountAddress,
      transport: getImmutableTransport(),
      ...args,
    })
  }
  return descriptor
}

export function verifyWallet(target, name, descriptor) {
  const oldValue = descriptor.value
  descriptor.value = async function (args) {
    const { accounts } = await fetchAuth()
    const { immutableCurrentBindAccount } = storeToRefs(useImmutableInfoStore())
    const accountAddress = immutableCurrentBindAccount.value
    if (!accountAddress) {
      throw new Error(immutableUnbindErrorText)
    }
    bindAddressExist(accountAddress, accounts)
    return oldValue.call(this, args)
  }
  return descriptor
}
