import { getQuality } from 'src/constant'

const nftStaticPath = `${import.meta.env.VITE_FILE_PATH}/nft_video/`
export const nftList = [
  {
    id: 1,
    title: `SKOLL'S MASK`,
    name: 'MASK',
    link: `${nftStaticPath}NFT-LionFace1.mp4`,
    cover: `${nftStaticPath}NFT-LionFace1.webp`,
    quality: getQuality(2),
    Desc: `The general of the Ancient Kingdom "Froster".
    He was considered a capable assistant of Caxias the "Tyrant".

    As a descendant of the Frost Giant, he had a muscular body.
    He was very close to his younger brother who had a weak body.
    Together they had rescued the dying Caxias in the wild.

    Although Skoll was talented, he was short-tempered and arrogant.
    With his body overflowing with strength, he refused to learn combat skills.
    His friend Caxias once gave him a friendly suggestion
    but he was enraged by the comment and he fought Caxias after getting drunk.

    Later, during a hunting match held by the tribe,
    he was outmatched by a friend who had been weaker than him.
    The defeated Skoll soon recognized how arrogant he had been
    and that choosing not to train had been a mistake.

    From then, he and Caxias were reconciled
    And they promised each other to train themselves aiming to be the best warriors of the tribe`,
    rarity: 87.81,
  },
  {
    id: 2,
    title: `SKOLL'S HEAD`,
    name: 'HEAD',
    link: `${nftStaticPath}NFT-LionFace2.mp4`,
    cover: `${nftStaticPath}NFT-LionFace2.webp`,
    quality: getQuality(3),
    content: `After growing into an adult, Skoll defeated a strong monster in the mountains alone.
    He was then honored with the title of "Frost Hunter" .

    Caxias married Hydra, the daughter of the chieftain
    and became the candidate for next chieftain.

    During the celebration party of Caxias
    Skoll wished happiness to Caxias and was drunken the entire time.
    He had no doubts that his friend Caxias would lead the tribe to greatness.

    After Caxias became the chieftain
    Skoll was appointed as the Vanguard General.
    Skoll and Caxias, along with their troops
    started reuniting the fragmented frost giant tribes.

    In the end, Caxias led people unified Jotunheim together
    and founded Ancient Kingdom "Froster" .`,
    rarity: 10.0,
  },
  {
    id: 3,
    title: `SKOLL'S SOULMASS`,
    name: 'SOULMASS',
    link: `${nftStaticPath}NFT-LionFace3.mp4`,
    cover: `${nftStaticPath}NFT-LionFace3.webp`,
    quality: getQuality(4),
    content: `Later, Caxias built an altar at the "Well of Urd". With the power of sacrifice, he established the "Frostblade" Legion , and ordered Skoll to conquer the Midgard with it. Skoll obtained victory effortlessly, some even saying he shot down the "sun" himself. So the frost giants began to call him the "Sunstrider"

    After conquering humanity, Caxias became the "Tyrant," yet he grew increasingly distant from former companions like Skoll. "Since becoming the Tyrant, where have my dear friends gone?" he pondered. At a celebration banquet for the conquest of the Midgard, Skoll, inebriated, approached Caxias with a question.

    The silent "Tyrant" returned to Jotunheim, while Skoll remained stationed in the Midgard. Later, the Thunder God Thor led his army to reclaim the "Mortal Realm" and clashed multiple times with Skoll's legion.

    Skoll's forces were annihilated by Thor during the devastating battle. Filled with hatred towards the gods, Skoll broke his clan's taboo and submerged his left hand in the Well of Urðr, seeking the power to slay the divine. However, the ancient king was defeated, and Odin swiftly froze the spring, severing the entire lost kingdom from Jotunheim and casting it into the cosmos.

    Skoll was frozen in the ice and snow for millennia, gradually transforming into a bloodthirsty beast driven by despair. His eyes, crimson as blood, fixated on any intruders daring to explore this land.`,
    rarity: 2.19,
  },
]
