import { storeToRefs } from 'pinia'
import { useLoginStore, useUserInfoStore } from 'src/stores/user'

import { useWalletBind } from 'src/hooks/useWalletBind'
import { getUserBindAddress, getUserAddress } from 'src/helpers/blockchain'

export function useHandleWallet(cds) {
  const { handleBlockchainLogin, addressRef } = useWalletBind()

  const loginStore = useLoginStore()
  const { open: login } = loginStore
  const { hasLogin } = storeToRefs(loginStore)
  const { userInfo } = storeToRefs(useUserInfoStore())
  async function handleWallet() {
    if (!hasLogin.value) {
      await login()
    }
    // 钱包登录
    if (addressRef.value) {
      // 已经绑定
      if (userInfo.value?.wallet) {
        try {
          await getUserBindAddress()
          // 通过
          cds?.pass?.()
        } catch {
          // 替换
          // cds?.inconsistent?.()
          // 解绑
          cds?.unbind?.()
        }
      } else {
        // 绑定
        cds?.notBound?.()
      }
    } else {
      await handleBlockchainLogin()
    }
  }
  return handleWallet
}

export function useToggleWallet(cds) {
  const { handleBlockchainLogin, addressRef } = useWalletBind()

  const loginStore = useLoginStore()
  const { open: login } = loginStore
  const { hasLogin } = storeToRefs(loginStore)
  const { userInfo } = storeToRefs(useUserInfoStore())
  async function handleWallet() {
    if (!hasLogin.value) {
      await login()
    }
    // 钱包登录
    if (addressRef.value) {
      // 已经绑定
      if (userInfo.value?.wallet) {
        try {
          await getUserAddress()
          // 解绑
          cds?.unbind?.()
        } catch {
          // 其他
          cds?.invalid?.()
        }
      } else {
        // 绑定
        cds?.notBound?.()
      }
    } else {
      await handleBlockchainLogin()
    }
  }
  return handleWallet
}
