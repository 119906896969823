<script setup>
import { ref, computed } from 'vue'
import accountImg from 'src/asset/account.webp'
import purseImg from '/src/asset/purse.webp'
import illustrateImg from 'src/asset/softPledge/inventory/illustrate.webp'
import { middleEllipsis } from 'src/sdk/blockchain'
import History from './History'
import TestBtn from '../TestBtn'
import { useSoftPledge } from './index'
import NFTTable from './NFTTable.vue'
import { useUserInfoStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import { useHeightFull } from 'src/hooks/useHeightFull'
import NFTSoftPledge from '../../Inventory/NFTSoftPledge/index.vue'

const emit = defineEmits([
  'openPointHistory',
  'openEventsHistory',
  'openGameAccount',
  'openWalletHistory',
  'openAboutWallet',
  'openTest',
])
const { userInfo } = storeToRefs(useUserInfoStore())
const { erc20ListComputed, luckyPointsComputed, nftListComputed } =
  useSoftPledge()
const domFullRef = ref(null)
const { heightRef } = useHeightFull(domFullRef)
const searchHeight = 45
const heightComputed = computed(() => {
  const height = heightRef.value
  if (height > searchHeight) {
    return height - searchHeight + 'px'
  } else {
    return height + 'px'
  }
})
function openPointHistory() {
  emit('openPointHistory')
}
function openEventsHistory() {
  emit('openEventsHistory')
}
function openGameAccount() {
  emit('openGameAccount')
}
function openWalletHistory() {
  emit('openWalletHistory')
}
function openAboutWallet() {
  emit('openAboutWallet')
}
function openTest() {
  emit('openTest')
}
</script>
<template>
  <div class="flex flex-col item_body_height">
    <div class="box mb-1.5 flex-shrink-0">
      <div class="box_header">
        <img :src="accountImg" class="w-18px mr-3.5" alt="" />
        <div class="mr-7 fz-5 leading-6!">GAME ACCOUNT</div>
        <div class="text-#A3B4BA leading-4!" v-if="userInfo?.AccountId">
          ID: {{ userInfo?.AccountId }}
          <!-- {{
            middleEllipsis(userInfo?.bindAccountInfo?.immutableWallet ?? '')
          }} -->
        </div>
        <img
          :src="illustrateImg"
          class="right_icon"
          @click="openGameAccount"
          alt=""
        />
      </div>
      <div class="box_body">
        <div class="mr-1.5 box_body_box">
          <div class="box_title">POINT DETAILS</div>
          <div class="box_content h-230px!">
            <template v-for="item in erc20ListComputed" :key="item.name">
              <div class="item_red item_box">
                <div class="item_left">
                  <img :src="item.cover" :alt="item.name" />
                  <span>{{ item.name }}</span>
                </div>
                <div class="item_right">
                  <div class="bg"></div>
                  <div class="bg_right"></div>
                  <span>{{ item.number }}</span>
                </div>
              </div>
            </template>
          </div>
          <History @click="openPointHistory"></History>
        </div>
        <div class="box_body_box">
          <div class="box_title">EVENTS REWARD</div>
          <div class="box_content h-230px!">
            <div class="item_green item_box">
              <div class="item_left">
                <img
                  :src="luckyPointsComputed.cover"
                  :alt="luckyPointsComputed.name"
                />
                <span>{{ luckyPointsComputed.name }}</span>
              </div>
              <div class="item_right">
                <div class="bg"></div>
                <div class="bg_right"></div>
                <span>{{ luckyPointsComputed.number }}</span>
              </div>
            </div>
          </div>
          <TestBtn
            class="absolute left-8 bottom-4 mouse_pointer"
            @click="openTest"
          ></TestBtn>
          <History @click="openEventsHistory"></History>
        </div>
      </div>
    </div>
    <div class="box flex flex-col flex-grow-1">
      <div
        ref="domFullRef"
        class="box_header bg-#223744! border-#81C8DC! flex-shrink-0"
      >
        <img :src="purseImg" class="w-28.5px mr-3.5" alt="" />
        <div class="mr-7 fz-5 leading-6!">WALLET</div>
        <div class="text-#A3B4BA leading-4!" v-if="userInfo?.wallet">
          {{ middleEllipsis(userInfo?.wallet, 10, -10) }}
        </div>
        <NFTSoftPledge></NFTSoftPledge>
        <img
          :src="illustrateImg"
          class="right_icon"
          @click="openAboutWallet"
          alt=""
        />
      </div>
      <div class="box_body bg-#34444F! relative flex-grow-1">
        <div class="box_body_box p-0! border-#7b9fad!">
          <div
            class="overflow-y-auto scroll"
            :style="{ height: heightComputed }"
          >
            <NFTTable :list="nftListComputed"></NFTTable>
          </div>
          <History @click="openWalletHistory"></History>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import './index';
@import '../scroll.css';

.item_body_height {
  @include underMD {
    height: calc(100vh - var(--header-phone-height));
  }

  @include minMD {
    height: calc(100vh - var(--header-height));
  }
}

.right_icon {
  @extend .mouse_pointer;
  @apply absolute right-0 translate-x-[50%] translate-y-[25%] w-51px z-2;
}

.item_box {
  @apply border border-solid [&:not(:last-child)]:mb-2.75 flex;

  .item_left {
    @apply flex items-center w-33% min-w-180px py-1.75 pl-4 mr-1.5;

    span {
      @apply ml-4;
    }

    img {
      @apply w-25px;
    }
  }

  .item_right {
    @apply flex items-center flex-grow-2 relative;

    .bg {
      @apply absolute w-90% h-full border-solid border-0 border-l;
    }

    .bg_right {
      @apply absolute w-90% h-full right-0;
    }

    span {
      @apply relative pl-28px;
    }
  }
}

.item_red {
  @apply bg-#904d26  border-#EF8A13;

  .item_left {
    span {
      @apply color-#F7EB2E;
    }
  }

  .item_right {
    @apply text-#F2D9AA;

    .bg {
      @apply bg-#653d1d border-#EF8A13 -skew-x-30;
    }

    .bg_right {
      @apply bg-#653d1d;
    }
  }
}

.item_green {
  @apply bg-#2c794b  border-#13EFA9;

  .item_left {
    span {
      @apply color-##9AFF6D;
    }
  }

  .item_right {
    @apply text-#46F752;

    .bg {
      @apply bg-#15573e border-#13EFA9 -skew-x-30;
    }

    .bg_right {
      @apply bg-#15573e;
    }
  }
}

.item {
  @apply bg-#262B2E border border-dashed border-#93A1AF [&:not(:last-child)]:mb-2.25 py-2.25 px-4 flex items-center justify-between;

  .item_left {
    @apply #C3D5DD flex items-center;

    span {
      @apply ml-4;
    }

    img {
      @apply w-22px;
    }
  }

  .item_right {
    @apply text-#52D058;
  }
}
</style>
