<script setup>
import { NSpin, NGrid, NGridItem } from 'naive-ui'
import WalletBox from 'src/components/WalletBox.vue'
import Modal from 'src/components/Modal'
import Video from 'src/components/Video'
import closeImg from 'src/asset/close.webp'
import { useNFTSoftPledgeStore } from 'src/stores/softPledge'
import { storeToRefs } from 'pinia'
const nftSoftPledgeStore = useNFTSoftPledgeStore()
const { addLoadingRef } = storeToRefs(useNFTSoftPledgeStore())
const { addNFTStakingCombination } = nftSoftPledgeStore

const emit = defineEmits(['update:show'])
const props = defineProps({
  list: Array,
})

const typeDict = {
  RARE: '#07658D',
  EPIC: '#52538D',
  LEGENDARY: '#D88035',
}
function handleShow(b) {
  emit('update:show', b)
}

async function handleClick(tokenId) {
  await addNFTStakingCombination(0, tokenId)
  handleShow(false)
}
</script>
<template>
  <Modal @update:show="handleShow">
    <div
      class="bg-#040405 relative border border-solid border-#253D48 px-8 pt-5 pb-7 w-85vw item_container"
    >
      <div
        class="fz-7 mb-4.5 tracking-1 ml-4 flex items-center justify-between"
      >
        <span class="family_blk text-36px flex-1 flex-center">
          CLICK ICON TO REPLACE THE CURRENT GENESIS NFT
        </span>
        <img
          :src="closeImg"
          @click="handleShow(false)"
          class="w-24px mouse_pointer"
        />
      </div>
      <div
        class="w-full h-10px bg-#2A2D31 border border-solid border-#616568"
      ></div>
      <div
        class="w-full relative md:h-auto 2xl:h-760px pb-20px border border-t-0 border-solid border-#616568 overflow-y-auto"
      >
        <ul class="px-40px py-30px flex flex-wrap min-h-680px">
          <NGrid :x-gap="20" :y-gap="20" :cols="6">
            <NGridItem v-for="item in list" :key="item?.id">
              <div>
                <WalletBox
                  :cd="() => !addLoadingRef && handleClick(item?.tokenId)"
                >
                  <div
                    :style="{
                      borderColor: typeDict[item?.attributes[0]?.value],
                    }"
                    class="mouse_pointer relative w-213px border border-solid overflow-hidden"
                  >
                    <Video
                      :width="213"
                      :height="213"
                      :src="item?.link"
                      :controls="false"
                      :poster="item?.cover"
                      autoplay
                      loop
                      muted
                    ></Video>
                  </div>
                  <div
                    class="family_blk w-213px flex flex-items-center justify-between text-21px"
                  >
                    <div
                      class="w-146px h-31px flex-center tracking-1px"
                      :class="`nft-bg-${item?.attributes[0]?.value}`"
                    >
                      {{ item?.attributes[0]?.value }}
                    </div>
                    <div class="pr-1 text-17px">#{{ item?.tokenId }}</div>
                  </div>
                </WalletBox>
              </div>
            </NGridItem>
          </NGrid>
        </ul>
        <div v-if="addLoadingRef" class="absolute left-0 top-0 h-full w-full">
          <NSpin :size="100" class="absolute-center"></NSpin>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.nft-bg-RARE {
  @include bg100('src/asset/nftSoftStaking/deck/new/bg1.webp');
}

.nft-bg-EPIC {
  @include bg100('src/asset/nftSoftStaking/deck/new/bg2.webp');
}

.nft-bg-LEGENDARY {
  @include bg100('src/asset/nftSoftStaking/deck/new/bg3.webp');
}
</style>
