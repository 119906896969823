import { requestBase } from 'src/helpers/request'
/**
 * NFT市场展示魂卡属性
 * @param {*} param0 {nfttype, tokenid}
 * @returns
 */
export function fetchCardMetadataDetail({ nfttype = 'soulcore', tokenId }) {
  return fetch(
    `${import.meta.env.VITE_METADATA_PATH}/index.php/${nfttype}/${tokenId}`,
  ).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText)
    }
    return response.json()
  })
}
/**
 * 生成魂卡
 * @param {*} params {walletaddr,tokenId}
 * @returns
 */
export function fetchCreateSoulCard(params) {
  return requestBase({
    params: {
      ...params,
      code: 62,
    },
  })
}
// window.fetchCreateSoulCard = fetchCreateSoulCard
// async function createCard(number) {
//   await window.soulCard.writeContract({
//     functionName: 'safeMint',
//     args: ['0x80ae0124507f5419c83907a430041082ac4e0e95', number],
//   })
//   window.fetchCreateSoulCard({
//     walletaddr: '0x80ae0124507f5419c83907a430041082ac4e0e95',
//     tokenId: number,
//   })
// }
// window.createCard = createCard
/**
 * game mint
 * @param {*} params
 * @returns
 */
export function fetchGameMint(params) {
  return requestBase({
    params: {
      ...params,
      code: 92,
    },
  })
}

/**
 * 网页请求web2服务器，魂卡mint属性以及消耗
 * @param {*} params {itemToken: string|number}
 * @returns
 */
export function fetchSoulCardAttrsAndConsume(params) {
  return requestBase({
    params: {
      ...params,
      code: 94,
    },
  })
}
// /**
//  * 轮询订单接口状态
//  * @param {*} params
//  * @returns
//  */
// export function fetchOrderStatus(params) {
//   return requestBase({
//     params: {
//       ...params,
//       code: 102,
//     },
//   })
// }
