import { useIntervalFn } from '@vueuse/core'
import { watch } from 'vue'
import { useImmutableInfoStore } from 'src/stores/immutable'

export function useOrderStatus(dataRef) {
  const immutableInfoStore = useImmutableInfoStore()
  const { executeSoulCoreInfoList } = immutableInfoStore
  const { resume: startReadMoreMintStatus, pause: pauseReadMoreMintStatus } =
    useIntervalFn(
      async () => {
        await executeSoulCoreInfoList()
      },
      3000,
      {
        immediate: false,
      },
    )
  watch(dataRef, value => {
    value ? startReadMoreMintStatus() : pauseReadMoreMintStatus()
  })
  return {
    pauseReadMoreMintStatus,
    startReadMoreMintStatus,
  }
}
