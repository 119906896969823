<script setup>
import { ref, onMounted, computed } from 'vue'
import WalletBox from 'src/components/WalletBox.vue'
import ItemModal from 'src/components/ItemModal'
import Progress from '../Progress/index.vue'
import { NSpin } from 'naive-ui'
import { useRouter } from 'vue-router'
import { INVENTORY } from 'src/constant'
import { storeToRefs } from 'pinia'
import {
  useNFTSoftPledgeDomStore,
  useNFTSoftPledgeStore,
} from 'src/stores/softPledge'
const nftSoftPledgeStore = useNFTSoftPledgeStore()
const { deleteLoadingRef, emptyGuideShow } = storeToRefs(
  useNFTSoftPledgeStore(),
)
const { deleteNFTStakingCombination } = nftSoftPledgeStore
const {
  deckDom,
  bounsHeaderDom,
  bounsBottomDom,
  totalStakingPointDom,
  redemptionDom,
  deleteDeckDom,
} = storeToRefs(useNFTSoftPledgeDomStore())
const deckDomRef = ref(null)
const bounsHeaderDomRef = ref(null) // 右侧边栏赏金头部
const bounsBottomDomRef = ref(null) // 右侧边栏赏金底部
const totalStakingPointDomRef = ref(null) // 右侧边栏总质押积分
const redemptionDomRef = ref(null) // 右侧边栏赎回
const deleteDeckDomRef = ref(null) // 右侧边栏删除组合
const router = useRouter()

const props = defineProps({
  pointsData: Object,
  nftData: Object,
})

const quality = computed(() => props.nftData?.quality?.text)
const qualityColor = computed(() => props.nftData?.quality?.color)

const confirmDeleteShowRef = ref(false)

function handleDelete(id, tokenId) {
  confirmDeleteShowRef.value = false
  deleteNFTStakingCombination(0, {
    tokenId,
    id,
  })
}

function goInventory() {
  router.push({ name: INVENTORY })
}
onMounted(() => {
  deckDom.value = deckDomRef.value
  bounsHeaderDom.value = bounsHeaderDomRef.value
  bounsBottomDom.value = bounsBottomDomRef.value
  totalStakingPointDom.value = totalStakingPointDomRef.value
  redemptionDom.value = redemptionDomRef.value
  deleteDeckDom.value = deleteDeckDomRef.value
})
</script>
<template>
  <div class="relative mt-0.52vw w-full" ref="deckDomRef">
    <div
      class="staking-points-bg family_blk_bold mb-1.4vw pl-0.625vw w-full h-2.44vw leading-2.84vw flex"
      ref="totalStakingPointDomRef"
    >
      <span
        class="text-#6194B8 tracking-0.6px md:text-16px 3xl:text-1.25vw w-60%"
        >DECK STAKING POINTS</span
      >
      <span class="text-1.56vw text-#C4EBFF flex-1 ml-1.04vw">{{
        pointsData?.integral
          ? Number(pointsData?.integral)?.toLocaleString()
          : 0
      }}</span>
    </div>
    <div
      class="extra-staking-points-bg tracking-0.6px family_blk_bold pl-0.52vw w-full h-1.98vw leading-2.3vw text-#6194B8 md:text-16px 3xl:text-1.25vw"
      ref="bounsHeaderDomRef"
    >
      UNLOCK EXTRA STAKING POINTS BONUS
    </div>
    <div class="w-full h-10.93vw border border-b-0 border-solid border-#2D3743">
      <img
        class="w-full h-full"
        src="src/asset/nftSoftStaking/hela_gold.webp"
        alt=""
      />
    </div>
    <div
      class="-mt-0.41vw p-0.625vw pb-1.45vw w-full h-auto text-0.9375vw md:tracking-0.6px 3xl:tracking-1.2px leading-1.56vw text-#8699A3 border border-solid border-#2D3743"
    >
      <div class="flex flex-items-center">
        <div class="w-70% flex flex-items-center">
          <div
            class="mr-0.41vw w-0.52vw h-0.52vw bg-#708A8D border-2px border-solid border-#AEBEC0 rotate-45"
          ></div>
          <div>
            Genesis NFT Rarity Bonus:
            <span :style="{ color: qualityColor }">{{ quality }}</span>
          </div>
        </div>
        <Progress
          :num="pointsData?.nft_rarity_bonus"
          :max="pointsData?.nft_rarity_bonus_upper_limit"
        ></Progress>
      </div>
      <div class="flex">
        <div class="w-70% flex flex-items-center">
          <div
            class="mr-0.41vw w-0.52vw h-0.52vw bg-#708A8D border-2px border-solid border-#AEBEC0 rotate-45"
          ></div>
          <div>
            Genesis
            <span class="text-#fff">{{ pointsData?.nft_have || 0 }}</span>
            Holding Bonus:
          </div>
        </div>
        <Progress
          :num="pointsData?.nft_holdings_bonus"
          :max="pointsData?.nft_holdings_bonus_upper_limit"
        ></Progress>
      </div>
      <div class="flex">
        <div class="w-70% flex flex-items-center">
          <div
            class="mr-0.41vw w-0.52vw h-0.52vw bg-#708A8D border-2px border-solid border-#AEBEC0 rotate-45"
          ></div>
          <div>
            Genesis Staking Duration:
            <span class="text-#fff"
              >{{ pointsData?.create_day || 0 }} Days</span
            >
          </div>
        </div>
        <Progress
          :num="pointsData?.nft_staking_duration"
          :max="pointsData?.nft_staking_duration_upper_limit"
        ></Progress>
      </div>
      <div class="flex flex-items-center">
        <div class="w-70% flex flex-items-center">
          <div
            class="mr-0.41vw w-0.52vw h-0.52vw bg-#708A8D border-2px border-solid border-#AEBEC0 rotate-45"
          ></div>
          <div>Ethereal NFT Deck Bonus:</div>
        </div>
        <Progress
          :num="pointsData?.pfp_rarity_bonus"
          :max="pointsData?.pfp_rarity_bonus_upper_limit"
        ></Progress>
      </div>
      <!-- <div class="flex flex-items-center">
        <div class="w-70% flex flex-items-center">
          <div
            class="mr-0.41vw w-0.52vw h-0.52vw bg-#708A8D border-2px border-solid border-#AEBEC0 rotate-45"
          ></div>
          <div>
            Ethereal NFT <span class="text-#fff">Deity/Old Ones</span> Race
            Bonus:
          </div>
        </div>
        <Progress
          :num="pointsData?.combination_bonus"
          :max="pointsData?.combination_bonus_upper_limit"
        ></Progress>
      </div> -->
    </div>
    <div
      class="w-full flex justify-between flex-items-center px-1.04vw h-2.03vw text-0.9375vw bg-#131B20 border border-t-0 border-solid border-#2D3743"
      ref="bounsBottomDomRef"
    >
      <span>Total Bonus:</span>
      <span class="text-1.25vw text-#FFDB6D"
        >{{ pointsData?.total_bonus_sum || 0 }}%</span
      >
    </div>
    <div
      class="redemption-bg p-0.55vw pt-0.65vw mt-1.8vw w-full h-11.6vw relative"
      ref="redemptionDomRef"
    >
      <div class="family_blk_bold flex flex-col text-1.25vw leading-1.5vw">
        <span class="text-#A5AFB5">POINTS REDEMPTION</span>
        <span class="text-#D54055">COMING SOON .....</span>
      </div>
      <div class="absolute left-0 bottom-1.04vw text-1.25vw text-#89B393">
        <div class="relative">
          <WalletBox
            :cd="() => !deleteLoadingRef && (confirmDeleteShowRef = true)"
          >
            <div
              class="mouse_pointer flex flex-items-center relative mb-1.04vw"
              ref="deleteDeckDomRef"
            >
              <div class="btn-common-bg flex-center w-9.11vw h-1.77vw">
                Delete Deck
              </div>
              <img
                class="w-2.14vw ml-0.73vw"
                src="src/asset/nftSoftStaking/del_deck_icon.webp"
                alt=""
              />
              <div v-if="deleteLoadingRef" class="absolute h-full w-full">
                <NSpin :size="30" class="absolute-center"></NSpin>
              </div>
            </div>
          </WalletBox>
          <div
            v-if="!pointsData"
            class="absolute w-full h-full left-0 top-0"
          ></div>
        </div>
        <div class="mouse_pointer flex flex-items-center relative">
          <div
            @click="goInventory"
            class="btn-common-bg flex-center w-9.11vw h-1.77vw"
          >
            Inventory
          </div>
          <img
            class="w-2.27vw ml-0.625vw"
            src="src/asset/nftSoftStaking/inventory_icon.webp"
            alt=""
          />
        </div>
      </div>

      <!-- 指引 -->
      <div
        v-if="emptyGuideShow"
        class="absolute bottom-0 right-0 w-104% h-105% bg-#060D09/80 border border-solid border-#235042"
      >
        <div
          class="family_blk_bold px-0.94vw -mt-1.56vw ml-0.935vw md:text-base 2xl:text-2xl"
        >
          <img
            class="w-1.7vw ml-0.67vw"
            src="src/asset/nftSoftStaking/guide/step/4/arrow1.webp"
            alt=""
          />
          <div class="text-#69F093">BONUSES</div>
          <span class="text-#fff"
            >Breakdown of current deck's staking point bonuses.</span
          >
        </div>
      </div>
    </div>
    <!-- 删除二次确认 -->
    <ItemModal
      v-model:show="confirmDeleteShowRef"
      @confirm="handleDelete(pointsData?.group_id, pointsData?.token)"
    >
      <div class="fz-4 px-16 py-10 text-center text-#CFDDE7">
        Confirm Deck Deletion?
      </div>
    </ItemModal>
  </div>
</template>

<style lang="scss" scoped>
.staking-points-bg {
  @include bg100('src/asset/nftSoftStaking/total_skaing_points_bg.webp');
}

.extra-staking-points-bg {
  @include bg100('src/asset/nftSoftStaking/extra_staking_points_bg.webp');
}

.deck-title {
  @include bg100('src/asset/nftSoftStaking/bonus_title.webp');
}

.staking-ponits-bg {
  @include bg100('src/asset/nftSoftStaking/blue_title.webp');
}

.redemption-bg {
  @include bg100('src/asset/nftSoftStaking/redemption_bg.webp');
}

.btn-common-bg {
  @include bg100('src/asset/nftSoftStaking/btn_common_bg.webp');
  @apply hover:bg-[url(src/asset/nftSoftStaking/btn_common_active_bg.webp)] hover:text-#83F09D;

  transition: all 0.1s;
}
</style>
