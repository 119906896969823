<script setup>
import musicClose from 'src/asset/music_close.webp'
import musicOpen from 'src/asset/music_open.webp'
import { onMounted } from 'vue'
import { MUSIC_PATH } from 'src/constant'
import { useMusicStore } from 'src/stores/music'
import { storeToRefs } from 'pinia'

const store = useMusicStore()
const { bgmAudioPause, bgmAudioPlay } = store
const { pauseBGMRef, bgmAudioRef, historyBgmAudioRef } = storeToRefs(store)
const bgmSrc = `${MUSIC_PATH}BOGX_BGM.mp3`

function handleAudioStatus() {
  if (!bgmAudioRef.value) return
  pauseBGMRef.value = bgmAudioRef.value.paused
}
async function handleToggle(toggle = pauseBGMRef.value) {
  if (toggle) {
    await bgmAudioPlay()
  } else {
    bgmAudioPause()
  }
}
async function handleClick() {
  try {
    await handleToggle()
  } finally {
    historyBgmAudioRef.value = pauseBGMRef.value
  }
}
onMounted(() => {
  handleToggle(!pauseBGMRef.value)
})
</script>

<template>
  <div @click="handleClick()">
    <audio
      ref="bgmAudioRef"
      :src="bgmSrc"
      loop
      @play="handleAudioStatus"
      @pause="handleAudioStatus"
    ></audio>
    <img
      :src="pauseBGMRef ? musicClose : musicOpen"
      alt=""
      class="w-[16px] md:w-[28px]"
    />
  </div>
</template>
