import { useDecorator } from '../blockchain'
import { immutableParams, verifyWallet } from '../immutable'
import { Pool } from '../Pool'
import FTPoolabi from 'src/sdk/blockchain/abi/FtPoolAbi.json'
import NFTPoolabi from 'src/sdk/blockchain/abi/NftPoolAbi.json'
export class ImmutablePool extends Pool {
  constructor() {
    super()
  }
  lock(data) {
    return super.lock(data)
  }
}

const ImmutablePoolProty = ImmutablePool.prototype
useDecorator(ImmutablePoolProty, 'lock', immutableParams)
useDecorator(ImmutablePoolProty, 'lock', verifyWallet)

export const immutableFTPool = new ImmutablePool()
immutableFTPool.setAbi(FTPoolabi)
export const immutableNFTPool = new ImmutablePool()
immutableNFTPool.setAbi(NFTPoolabi)
