import { NOT_404_NAME } from 'src/constant'
import { router } from 'src/router'

function fetchIP() {
  return fetch('https://api.ipify.org?format=json')
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText)
      }
      return response.json()
    })
    .then(data => data.ip)
}
function fetchIPWhitelist() {
  return fetch(import.meta.env.VITE_IP_WHITELIST_PATH)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText)
      }
      return response.text()
    })
    .then(data => data)
}
export let hasIPWhitelist = true

export async function ipWhitelistInit() {
  try {
    const currentIP = await fetchIP()
    const whitelist = await fetchIPWhitelist()
    hasIPWhitelist = whitelist.includes(currentIP)
  } catch {
    hasIPWhitelist = false
  }
  if (!hasIPWhitelist) {
    goTo404()
  }
  return hasIPWhitelist
}
function goTo404() {
  router.replace({
    name: NOT_404_NAME,
  })
}
