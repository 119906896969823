<script setup>
import { NModal } from 'naive-ui'
import closeImg from 'src/asset/close.webp'
import { defineEmits, defineProps, ref, watchEffect, computed } from 'vue'
import { getAppropriateSize, getImageSize } from '@BOGX/utils'
import Video from 'src/components/Video'
import { getType, tokenIdFormat, getSoulToknDataId } from '../utils.js'
import { ReceiveJobItemMap, getQuality } from 'src/constant'
import Header from 'src/pages/myCollection/components/Header'
const VideoSrc = `${import.meta.env.VITE_FILE_PATH}/title_animation.mp4`

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default() {
      return {}
    },
  },
  src: {
    type: String,
    default: '',
  },
  type: {
    type: Number,
    // 1是图片，2是视频
    default: 1,
  },
  poster: {
    type: String,
    default: '',
  },
  square: {
    type: Boolean,
    default: false,
  },
  title: String,
  tip: String,
})
const emit = defineEmits(['update:show', 'close'])

const qualityRef = computed(() => getQuality(props.data.Quality) || {})
const isSupreme = computed(() => {
  const DataId = props.data.DataId
  if (!DataId) return false
  let strNumber = DataId.toString()
  let lastDigit = strNumber.charAt(strNumber.length - 1)
  return lastDigit === '1'
})
const soulSkill = computed(() => {
  const arrays = []
  for (let i = 1; i <= 6; i++) {
    if (props.data[`sk${i}`]?.name) {
      arrays.push(props.data[`sk${i}`])
    }
  }
  return arrays
})

const widthRef = ref('')
const heightRef = ref('')

function handleClose() {
  emit('update:show', false)
  emit('close')
}
watchEffect(() => {
  if (props.src) {
    getImageSize(props.src).then(({ width, height }) => {
      const size = getAppropriateSize(width, height)
      widthRef.value = size.width
      heightRef.value = size.height
    })
  }
})
</script>

<template>
  <NModal class="modal" :show="show" @update:show="handleClose">
    <div
      class="relative"
      :class="`${title ? 'box_success' : '4xl:scale-120 4xl:origin-[50%_50%]! 4xl:ease-linear! 4xl:duration-200!'}`"
    >
      <Header @close="handleClose" v-if="title">
        <div class="text-center relative">
          {{ title }}
        </div>
      </Header>
      <!-- <div v-if="title" class="flex-center relative box_header">
        <span class="family_th fz-7 line-height-none!">{{ title }}</span>
        <img
          :src="closeImg"
          class="mouse_pointer absolute right-25px w-34px absolute -translate-y-50% top-50%"
          @click="handleClose"
        />
      </div> -->
      <div class="box_main border border-t-0 border-solid border-#636B74/60">
        <div class="relative flex">
          <div
            v-if="data.Icon"
            class="soul_select 4xl:mr-9 4xl:mr-6 mr-4 text-0 4xl:w-[772px] 4xl:h-[742px] gl:w-[617px] gl:h-[593px] w-463px h-445px"
            :class="`${type === 2 ? 'video-box' : ''}`"
          >
            <template v-if="type === 2">
              <Video
                class="video-cover"
                :src="src"
                :controls="false"
                :poster="poster"
                autoplay
                loop
                muted
              ></Video>
            </template>
            <template v-else-if="type === 1">
              <img
                :src="data.Icon"
                alt=""
                :style="{ width: '100%', height: '100%' }"
              />
            </template>
          </div>
          <div class="4xl:w-[713px] w-427px gl:w-[570px]">
            <div
              class="px-3 relative flex justify-between flex-items-center w-full 4xl:h-[47px] gl:h-37px h-28px"
            >
              <Video
                v-if="data.type !== 1"
                class="absolute! top-0 bottom-0 left-0 right-0 w-full 4xl:h-[47px] gl:h-37px h-28px"
                :src="VideoSrc"
                :controls="false"
                autoplay
                object-fit:
                cover
                loop
                muted
              ></Video>
              <span class="fz-2 relative z-2">{{
                getType(data.ItemId, data.ItemType)
              }}</span>

              <div class="fz-5 relative z-2">
                {{ data.Name }}
                <span v-if="data.type !== 1">
                  &nbsp;&nbsp;#{{ tokenIdFormat(getSoulToknDataId(data)) }}
                </span>
              </div>
              <img
                v-if="data.type !== 1"
                class="4xl:w-[24px] gl:w-19px w-[14px] relative z-2"
                src="src/asset/passport_logo.svg?url"
                alt=""
              />
              <div v-else></div>
            </div>
            <div
              class="pt-5 px-2.5 w-full 4xl:h-[695px] gl:h-[556px] h-417px border border-[#4D575C] border-solid border-t-0 bg-#0f0f11"
            >
              <div
                class="pb-2.5 flex w-full justify-center border-0 border-b-1 border-#2E363B border-solid"
              >
                <ul>
                  <li
                    class="px-2.5 mb-2.5 flex justify-between flex-items-center mint_box border border-dotted bg-#191E20 fz-2"
                  >
                    <span class="gray-color">Role ID:</span>
                    <div class="-ml-5 flex items-center">
                      <span class="gray-color"
                        >{{
                          ReceiveJobItemMap[data.ReceiveJob]
                        }}&nbsp;/&nbsp;</span
                      >
                      <span>{{ data.ReceiveName }}</span>
                    </div>
                    <span></span>
                  </li>
                  <li
                    class="px-2.5 mb-2.5 flex justify-between flex-items-center mint_box border border-dotted bg-#262B2E fz-2"
                  >
                    <span class="gray-color">Rarity:</span>
                    <span class="-ml-5" :style="{ color: qualityRef.color }">{{
                      qualityRef.text
                    }}</span>
                    <span></span>
                  </li>
                  <li
                    class="px-2.5 mb-2.5 bg-#AA672B flex justify-between flex-items-center mint_box border border-solid border-#F3AE42 fz-2"
                    v-if="data.mint"
                  >
                    <span class="text-#F7D57B">Mint:</span>
                    <span class="-ml-5">{{ data.mint.me.desc }}</span>
                    <span></span>
                  </li>
                  <li
                    class="px-2.5 mb-2.5 flex justify-center flex-items-center mint_box border border-solid fz-2"
                    :class="`${isSupreme ? 'text-#FFDD94 bg-#CD6A12 border-#F3AE42' : 'text-#EADDBB bg-#4D4A47 border-#68645E'}`"
                  >
                    <span class="text-center">{{
                      isSupreme ? 'SUPREME' : 'NORMAL'
                    }}</span>
                  </li>
                  <li
                    class="px-2.5 mb-2.5 flex justify-center flex-items-center mint_box border border-dotted border-#93A1AF bg-#262B2E fz-2"
                  >
                    <span class="gray-color"> Advance: </span>
                    <span class="text-center">&nbsp;Lv.{{ data.ProgLv }}</span>
                    <span></span>
                  </li>
                  <li
                    class="px-2.5 mb-2.5 flex justify-center flex-items-center mint_box border border-dotted border-#93A1AF bg-#262B2E fz-2"
                  >
                    <span class="gray-color"> Star:</span>
                    <span class="text-center">&nbsp;Lv.{{ data.StarLv }}</span>
                    <span></span>
                  </li>
                  <li
                    class="px-2.5 mb-2.5 flex justify-center flex-items-center mint_box border border-dotted border-#93A1AF bg-#262B2E fz-2"
                  >
                    <span class="gray-color">Exp:</span>
                    <span class="text-center">&nbsp;Lv.{{ data.Lv }}</span>
                    <span></span>
                  </li>
                </ul>
                <div
                  class="ml-5 4xl:w-[320px] 4xl:h-[314px] gl:w-[256px] gl:h-[28px] w-192px h-21px bg-#141719 border border-solid border-#464A4E 4xl:p-3.5"
                >
                  <p class="text-center my-0">SOUL SKILL</p>
                  <ul class="mt-4 w-full">
                    <li
                      v-for="item in soulSkill"
                      class="flex-center fz-2 mb-2.5 w-full 4xl:h-[33px] gl:h-26px h-20px bg-#262B2E"
                    >
                      <span class="gray-color mr-1">{{ item.name }}:</span>
                      <span class="text-#FFFFFF"
                        >Lv.{{ item.lv }} / Lv.{{ item.lvMax }}</span
                      >
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="w-full 4xl:h-[336px] gl:h-268px gray-color fz-2 pt-5 pb-5 overflow-auto h-201px"
              >
                <div class="mb-4 text-center">Story</div>
                <div
                  class="text-center 4xl:px-20 gl:px-10 px-8"
                  v-html="data.Desc"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tip" class="mt4 flex-center flex-col">
          <span class="fz-3 mb3 text-#BE7FF4 fz-3">{{ tip }}</span>
          <div class="mint_btn h-[34px] w-[167px] fz-5" @click="handleClose">
            Confirm
          </div>
        </div>
      </div>

      <img
        v-if="!title"
        :src="closeImg"
        class="mouse_pointer absolute right-[-28px] top-14px w-20px"
        @click="handleClose"
      />
    </div>
  </NModal>
</template>

<style lang="scss" scoped>
.soul_select {
  padding: 1px;
  background: linear-gradient(
    180deg,
    rgb(153 216 225 / 100%) 0%,
    rgb(215 177 218 / 100%) 100%
  );
}

.video-js .vjs-tech {
  object-fit: cover !important;
}

// .box_view {
//   @apply 4xl:scale-120 4xl:origin-[50%_50%]! 4xl:ease-linear! 4xl:duration-200!;
// }

.box_success {
  .box_header {
    @apply 4xl:py4 py2 bg-#2A2D31;
  }

  .box_main {
    @apply 4xl:px40 px6 4xl:py10 py4  bg-#0e1217;
  }
}

.mint_btn {
  color: #fff;
  background: #a031ff;
  border-radius: 5px;

  @apply border border-solid border-#A862FF;
  @extend .mouse_pointer;
  @extend .flex-center;

  @include minMD {
    &:hover {
      background-color: #d46cff;

      @apply border-#E08DFF;
    }
  }
}

.mint_box {
  @apply w-192px 4xl:w-[320px] 4xl:h-[36px] gl:w-[256px] gl:h-[28px] h-21px;
}

.video-box {
  @apply pt-2px;
}
</style>
