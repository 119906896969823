import { fetchMetadata, fetchSaveMetadata } from 'src/api'
import { NEW_MESSAGE_NFT_SOFT_PLEDGE, NFT_SOFT_STAKING } from 'src/constant'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export function useRedSpot() {
  const router = useRouter()
  const showRedSpotRef = ref(0)
  async function fetchData() {
    const data = await fetchMetadata({
      infoKey: NEW_MESSAGE_NFT_SOFT_PLEDGE,
      infoType: 1,
    })
    try {
      const value = JSON.parse(data.infoValue)
      showRedSpotRef.value = value
    } catch {
      showRedSpotRef.value = 1
    }
  }
  async function handleClick() {
    router.push({ name: NFT_SOFT_STAKING })
    await fetchSaveMetadata({
      infoKey: NEW_MESSAGE_NFT_SOFT_PLEDGE,
      infoType: 1,
      infoValue: 0,
    })
    fetchData()
  }
  fetchData()
  return {
    handleClick,
    showRedSpotRef,
  }
}
