<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { gsap } from 'gsap'
import { getSmoothScrolling } from 'src/helpers/smoothScrolling'
import { ease } from 'src/constant'
import LottieAnimation from 'src/components/Lottie.vue'
import animationData from 'src/lottie/Iconplay.json'
import {
  playDeshaedLine,
  reverseDeshaedLine,
  scrollTriggerBottom,
} from '../constant.js'

import TrailerSubModal from './TrailerSubModal.vue'
import Dashedline from './Dashedline'

const props = defineProps(['scrollTriggerDom'])
const trailerSubShowRef = ref(false)
const triangleDomRef = ref(null)
const startDomRef = ref(null)
const startImgDomRef = ref(null)
const loopDomRef = ref(null)
const LoopImgDomRef = ref(null)
const lineBoxDomRef = ref(null)
const isEnter = ref(false)
const lineRectRef = ref({ r: 0 })

let hoverStartTl,
  hoverLineMovementTl,
  hoverLoopShowTl,
  hoverLoopTl,
  hoverLineHideTl,
  hoverLineDashedTl,
  scrollTween
function initScroll() {
  const scrollTrigger = {
    ...scrollTriggerBottom,
    trigger: props.scrollTriggerDom,
  }
  scrollTween = gsap.to(triangleDomRef.value, {
    x: '-110%',
    scrollTrigger,
  })
}
function initHover() {
  const options = {
    paused: true,
    ease,
    onUpdate() {
      if (getSmoothScrolling()?.isScroll) {
        handleMouseleave()
      }
    },
  }
  // 三角形冒出
  hoverStartTl = gsap.to(triangleDomRef.value, {
    ...options,
    x: '-28%',
    duration: 0.3,
    onComplete() {
      if (getSmoothScrolling()?.isScroll) {
        handleMouseleave()
      } else {
        // hoverLineMovementTl.play()
        hoverLineDashedTl.play()
      }
    },
  })
  hoverLineMovementTl = gsap.timeline(options)

  const startSteps = 62 - 1
  const topStartImgx = startSteps * startDomRef.value.offsetWidth * -1
  const loopSteps = 73 - 1
  const topLoopImgx = loopSteps * loopDomRef.value.offsetWidth * -1

  // 线条出现
  hoverLineMovementTl
    .to(startDomRef.value, {
      opacity: 1,
      duration: 0.01,
    })
    .to(startImgDomRef.value, {
      x: topStartImgx,
      duration: 1,
      ease: `steps(${startSteps})`,
      onComplete() {
        hoverLoopShowTl.play()
        hoverLineHideTl.play()
      },
    })
  // 线条隐藏
  hoverLineHideTl = gsap.to(startDomRef.value, {
    ...options,
    opacity: 0,
    duration: 0.01,
  })

  // 呼吸线条显示
  hoverLoopShowTl = gsap.to(loopDomRef.value, {
    ...options,
    opacity: 1,
    duration: 0.01,
    onComplete() {
      hoverLoopTl.play()
      // hoverLineHideTl.play()
    },
  })

  // 呼吸线条运动
  hoverLoopTl = gsap.to(LoopImgDomRef.value, {
    ...options,
    x: topLoopImgx,
    duration: 1,
    ease: `steps(${loopSteps})`,
    onComplete() {
      hoverLoopTl.reverse()
    },
    onReverseComplete() {
      hoverLoopTl.play()
    },
  })
  // 虚线出现
  hoverLineDashedTl = gsap.to(lineRectRef.value, {
    ...options,
    duration: 2,
    r: 80,
    onReverse() {
      hoverLineDashedTl.duration(playDeshaedLine)
    },
  })
}
onMounted(() => {
  initScroll()
  initHover()
})
function handleMouseenter() {
  isEnter.value = true
  if (getSmoothScrolling()?.isScroll) {
    handleMouseleave()
  } else {
    hoverLineDashedTl.duration(playDeshaedLine)
    hoverStartTl.play()
    hoverLineMovementTl.play()
  }
}
function handleMouseleave() {
  isEnter.value = false
  hoverStartTl.reverse()
  hoverLineMovementTl.reverse()
  hoverLineHideTl.reverse()
  hoverLoopShowTl.reverse()
  hoverLoopTl.reverse()
  hoverLineDashedTl.duration(reverseDeshaedLine).reverse()
}
function handleOpenTrailer() {
  trailerSubShowRef.value = true
}
onUnmounted(() => {
  hoverStartTl?.kill()
  hoverLineMovementTl?.kill()
  hoverLoopShowTl?.kill()
  hoverLoopTl?.kill()
  hoverLineHideTl?.kill()
  hoverLineDashedTl?.kill()
  scrollTween?.kill()
})
</script>

<template>
  <div
    ref="triangleDomRef"
    class="triangle mouse_pointer fixed left-0 top-[50%] z-[2]"
    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
    @click="handleOpenTrailer"
  >
    <div class="triangle_img flex items-center">
      <span class="fz-3 ml-16 mr-3">TRAILER</span>
      <div class="w-28px ml-1.5 -mb-1.5">
        <LottieAnimation
          :ishover="false"
          :isEnter="isEnter"
          :animationData="animationData"
          :loop="false"
          :autoplay="false"
          :speed="1"
        ></LottieAnimation>
      </div>
    </div>
    <div ref="startDomRef" class="triangle_item">
      <img
        ref="startImgDomRef"
        src="/src/asset/sprite/right_start.png"
        alt=""
      />
    </div>
    <div ref="loopDomRef" class="triangle_item">
      <img ref="LoopImgDomRef" src="/src/asset/sprite/right_loop.png" alt="" />
    </div>
    <Dashedline
      ref="lineBoxDomRef"
      class="line-box"
      :style="{ clip: `rect(auto, ${lineRectRef.r}vw, auto, auto)` }"
    ></Dashedline>
  </div>
  <TrailerSubModal v-model:show="trailerSubShowRef"> </TrailerSubModal>
</template>

<style scoped lang="scss">
.triangle {
  transform: translate(-70%, -50%);

  .triangle_img {
    @include bg100('src/asset/left_triangle.webp');
    @apply w-[204px] h-[130.5px];
  }

  .triangle_item {
    transform: translate(-27%, -104%) rotateY(180deg);

    @apply pointer-events-none absolute w-[210px] h-[120px] overflow-hidden left-[50%] opacity-0;

    & > img {
      @apply h-[120px];
    }
  }

  .line-box {
    top: 50%;
    left: 250px;
    width: 80vw;
    transform: translateY(-50%);

    @apply absolute;
  }

  .arrow {
    background: url('/src/asset/left_center_jian.webp');
    background-size: 100% 100%;
    transition: background-image ease-in-out 0.2s;
  }
}
</style>
