<script setup>
import { ref } from 'vue'
import { NPopover } from 'naive-ui'
import { fetchDownloadBOGX } from 'src/api'
const listRef = ref([])
fetchDownloadBOGX().then(data => (listRef.value = data))
</script>

<template>
  <NPopover style="padding: 0.375rem" class="item-bg">
    <template #trigger>
      <slot></slot>
    </template>
    <div>
      <ul class="rounded">
        <template v-for="item in listRef" :key="item.name">
          <li>
            <a
              class="relative block h-[36px] w-241px rounded bg-#A6B7C4 text-black md:h-[38px] md:w-[270px]"
              :href="item.link"
              target="_blank"
              download
            >
              <span class="absolute-center ellipsis-1 w-80% text-center fz-3">{{
                item.name
              }}</span>
              <img
                src="/src/asset/download.webp"
                alt=""
                class="absolute right-[8px] top-[50%] w-[9px] translate-y-[-50%]"
              />
            </a>
          </li>
        </template>
      </ul>
    </div>
  </NPopover>
</template>

<style scoped lang="scss">
ul {
  li {
    &:not(:last-child) {
      @apply mb-1.5;
    }

    @include minMD {
      a:hover {
        @apply bg-[#ffffff];
      }
    }
  }
}
</style>
