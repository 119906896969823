<script setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserInfoStore } from 'src/stores/user'

const { userInfo } = storeToRefs(useUserInfoStore())

const showRef = ref(false)
</script>

<template>
  <div class="relative">
    <template v-if="showRef">
      <img
        @click="showRef = !showRef"
        src="/src/asset/eye.webp"
        alt=""
        class="w-[20px] align-sub md:w-[30px] mb-1"
      />
      <span class="absolute-center">{{ userInfo?.password }}</span>
    </template>
    <template v-else>
      <img
        @click="showRef = !showRef"
        src="/src/asset/close_eye.webp"
        alt=""
        class="h-[13px] align-sub md:h-[20px]"
      />
      <span class="absolute-center">******</span>
    </template>
  </div>
</template>
