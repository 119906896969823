<script setup>
import { NPopover } from 'naive-ui'
import { ref, watch } from 'vue'
import { useMouseInElement, useTimeoutFn } from '@vueuse/core'
const emit = defineEmits(['show', 'update:show'])
const props = defineProps({
  show: Boolean,
})
const triggerDomRef = ref(null)
const domRef = ref(null)
const moveTriggerRef = ref(0)
const insideRef = ref(false)
const { isOutside: triggerOutside } = useMouseInElement(triggerDomRef)
const { isOutside: domOutside } = useMouseInElement(domRef)
const themeOverrides = {
  boxShadow: '',
  color: 'transparent',
  padding: 0,
}
const { start, stop } = useTimeoutFn(
  function () {
    // 剩余时间
    updateShow()
  },
  200,
  {
    immediate: false,
  },
)
watch(
  [triggerOutside, domOutside, moveTriggerRef],
  ([value, value1, trigger]) => {
    const inside = !value || !value1
    if (inside && !trigger) {
      handleRelease()
    }
    if (trigger) {
      insideRef.value = inside
      stop()
      start()
    }
  },
  {
    immutable: true,
  },
)
function updateShow(value = insideRef.value) {
  emit('show', value)
  emit('update:show', value)
}
function handleRelease() {
  moveTriggerRef.value = 1
}
</script>

<template>
  <div>
    <NPopover :theme-overrides="themeOverrides" :show="show">
      <template #trigger>
        <div ref="triggerDomRef">
          <slot name="trigger"></slot>
        </div>
      </template>
      <div ref="domRef">
        <slot></slot>
      </div>
    </NPopover>
  </div>
</template>
