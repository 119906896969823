import { omit } from 'lodash-es'
import { computed } from 'vue'
import BBCode from 'nbbcjs'
import { bbCodeParseHtml } from '../../../helpers/bbCode'

export function useSoulList(listRef, roleListRef) {
  const soulListComputed = computed(() => {
    return listRef.value.map(info => {
      const data = info.info
      return {
        // TODO 1：mint，2 unlock, 3 lock
        // type: 2,
        // TODO mint 或者 unlock 后等待服务器返回中
        // responseWait: false,
        ...data,
        ...omit(info, ['info']),
        Desc: data.Desc && bbCodeParseHtml(new BBCode().parse(data.Desc)),
        ReceiveJob: roleListRef.value.find(role => role.UserID === info.UserID)
          ?.Job,
        ReceiveName: roleListRef.value.find(role => role.UserID === info.UserID)
          ?.Name,
      }
    })
  })
  const unlockResponseWaitDataComputed = computed(() =>
    soulListComputed.value.find(
      item => item.type === 2 && item.responseWait && item.state !== 15,
    ),
  )
  const mintResponseWaitDataComputed = computed(() =>
    soulListComputed.value.find(item => item.type === 1 && item.responseWait),
  )
  const lockResponseWaitDataComputed = computed(() =>
    soulListComputed.value.find(item => item.type === 3 && item.responseWait),
  )
  function getHasLoading(data) {
    if (!data) return
    const { type, responseWait } = data
    if (type === 1) {
      return responseWait || mintResponseWaitDataComputed.value
    } else if (type === 2) {
      return responseWait || unlockResponseWaitDataComputed.value
    } else if (type === 3) {
      // TODO 测试
      // return responseWait || lockResponseWaitDataComputed.value
      return responseWait || false
    }
  }
  return {
    soulListComputed,
    mintResponseWaitDataComputed,
    unlockResponseWaitDataComputed,
    lockResponseWaitDataComputed,
    getHasLoading,
  }
}
