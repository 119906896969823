<script setup>
import { getStatusAndTime } from '@BOGX/utils'
import { defineProps, ref, watch } from 'vue'
import { useIntervalFn } from '@vueuse/core'
import { hasPhone } from 'src/stores/media'

const props = defineProps({
  startTime: {
    type: [Number, Date],
  },
  endTime: {
    type: [Number, Date],
  },
  currentTime: {
    type: [Number, Date],
  },
})
const emit = defineEmits(['end'])
const currentTimeRef = ref(Date.now() / 1000)
const statusRef = ref(3)
const formattedTimeRef = ref([0, 0, 0, 0, 0, 0, 0, 0])
const { pause, resume } = useIntervalFn(
  () => {
    /* your function */
    const { status, formattedTime } = getStatusAndTime(
      props.startTime * 1000,
      props.endTime * 1000,
      currentTimeRef.value * 1000,
    )
    formattedTimeRef.value = formattedTime.replace(/,/g, '').split('')
    statusRef.value = status
    if (status === 1) {
      currentTimeRef.value += 1
    } else {
      emit('end')
      pause()
    }
  },
  1000,
  {
    immediate: false,
  },
)
watch(
  () => props.currentTime,
  value => {
    if (value) {
      currentTimeRef.value = value
      resume()
    }
  },
  { immediate: true },
)
defineExpose({
  status: statusRef,
})
</script>

<template>
  <!-- <span class="fz-6">{{ formattedTimeRef[0] }}{{ formattedTimeRef[1] }}</span> -->
  <!-- <span
    class="mx-4px mb-[-4px] text-#6D929F md:mx-1 md:mb-0"
    :class="`${!hasPhone ? '' : 'fz-2'}`"
    >Days</span
  > -->
  <span class="fz-3"
    >{{ formattedTimeRef[2] }}{{ formattedTimeRef[3] }}:{{ formattedTimeRef[4]
    }}{{ formattedTimeRef[5] }}:{{ formattedTimeRef[6]
    }}{{ formattedTimeRef[7] }}</span
  >
</template>
