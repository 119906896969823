import { useWeb3ModalEvents } from '@web3modal/wagmi/vue'
import { onBeforeUnmount, watchEffect } from 'vue'
import { watchAccount, watchNetwork } from '@wagmi/core'
import { logout } from './store'
import { disconnectWeb3modal, getCurrentWallet } from './walletConnect'
import { getImmutable } from './newBlockchain'

export async function onEvent() {
  watchAccount(() => logout())
  watchNetwork(() => logout())
}

export function useModalEvent(fn) {
  const events = useWeb3ModalEvents()
  const stop = watchEffect(async () => {
    const newEvent = events.data.event
    console.log('newEvent', newEvent, events)
    disablingImmutable(events)
    // 钱包模块加载好、已链接、关闭
    const eventArr = ['MODAL_LOADED', 'CONNECT_SUCCESS', 'MODAL_CLOSE']
    if (eventArr.includes(newEvent)) {
      fn()
    }
  })
  onBeforeUnmount(() => {
    stop()
  })
}
function disablingImmutable(events) {
  if (getImmutable() === getCurrentWallet()) {
    const newEvent = events.data.event
    // if (newEvent === 'SELECT_WALLET') {
    //   disconnectWeb3modal()
    // }
    // 连接成功
    if (newEvent === 'CONNECT_SUCCESS') {
      window.$message.warning(
        'Immutable Passport is not supported here. Please connect an EOA wallet, such as MetaMask.',
      )
      disconnectWeb3modal()
    }
  }
}
export function initDisconnectImmutable() {
  const wagmiStoreKeyList = [
    'wagmi.connectedRdns',
    'wagmi.injected.shimDisconnect',
  ]
  if (
    localStorage
      .getItem(wagmiStoreKeyList[0])
      ?.includes?.('com.immutable.passport')
  ) {
    wagmiStoreKeyList.forEach(key => {
      localStorage.removeItem(key)
    })
  }
}
