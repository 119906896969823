<script setup>
import { computed } from 'vue'
import Table from '../Table'
import { useNFTTable } from './useNFTTable'
import RedPopover from 'src/components/RedPopover.vue'
import bian_exchangeImg from 'src/asset/softPledge/inventory/bian_exchange.webp'
import ArrowSVG from 'src/asset/arrow.svg'
const props = defineProps({
  list: {
    type: Array,
    default() {
      return []
    },
  },
})
const { listNFTComputed } = useNFTTable(props)
const tablethemeOverridesComputed = computed(() => {
  const thColor = '#223744'
  const tdColor = '#34444F'
  // const padding = 0
  return {
    thColor,
    thColorModal: thColor,
    borderColorModal: thColor,
    tdColorStriped: thColor,
    tdColorModal: tdColor,
    tdColor,
    tdPaddingSmall: 0,
  }
})
</script>
<template>
  <Table :theme-overrides="tablethemeOverridesComputed">
    <thead>
      <tr>
        <th></th>
        <th><div class="text-center">ITEM</div></th>
        <th><div class="text-center">NFT TYPE</div></th>
        <th><div class="text-center">STAKING AT</div></th>
        <th><div class="text-center">STAKING POINTS</div></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="item in listNFTComputed" :key="item.type + item.token">
        <tr>
          <td>
            <div class="flex-center">
              <img
                :src="item.image"
                class="w-39px border border-solid border-#587277"
                alt=""
              />
            </div>
          </td>
          <td>
            <div class="text-center">
              <span class="text-#B5DCEB">{{ item.name }}</span>
              <template v-if="item.deck">
                <span class="text-#B5DCEB"> | </span>
                <span class="text-#52D058">Staking</span>
              </template>
            </div>
          </td>
          <td>
            <div class="text-#B5DCEB text-center">
              {{ Number(item.type) ? 'Ethereal' : 'Orisols' }}
            </div>
          </td>
          <td>
            <div class="text-center text-#B5DCEB">
              {{ item.time?.slice(0, -3) }}
            </div>
          </td>
          <td>
            <div class="flex-center">
              <span class="text-#52D058 inline-block mr-1.25">{{
                item.integral
              }}</span>
              <div class="next_box flex-center">
                <span class="text-#FFE26C">+{{ item.next_hour }}</span>
                <span>/h</span>
              </div>
            </div>
          </td>
          <td>
            <div class="flex-center">
              <RedPopover>
                <template #trigger>
                  <div class="relative w-105px text-#EBC06C flex-center">
                    <img :src="bian_exchangeImg" class="w-full" alt="" />
                    <span class="absolute left-50% -top-0.5 translate-x-[-50%]"
                      >Exchange</span
                    >
                    <ArrowSVG
                      width="18"
                      height="20"
                      class="absolute -top-0.1 -right-8px"
                    ></ArrowSVG>
                  </div>
                </template>
                <div>Coming Soon ......</div>
              </RedPopover>
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </Table>
</template>
<style lang="scss" scoped>
.next_box {
  @include bg100('src/asset/softPledge/inventory/jin_box.webp');
  @apply w-101px h-18px;
}
</style>
