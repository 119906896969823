import { useMessage } from 'naive-ui'
import { defineStore, storeToRefs } from 'pinia'
import { ref, watchEffect, computed, watch, nextTick } from 'vue'
import {
  fetchLuckyWheelInfo,
  fetchSignIn,
  fetchDrawLuckyWheel,
  fetchNFTInfo,
  fetchPFPDetail1,
  fetchUnAddNFTList,
  fetchAddNFTStakingCombination,
  fetchNFTStakingInfo,
  fetchDeleteNFTStakingCombination,
  fetchAddPFP,
  fetchDeletePFP,
  fetchUnAddPFPList,
} from 'src/api'
import { useUserInfoStore } from './user'
import { useAsyncState } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { getHasRouterActivation } from 'src/helpers/utils'
import { AppHttpCode, codeToMessage } from '@BOGX/helpers'
import { chunk } from 'lodash-es'
import { useBoundingClientRect } from 'src/hooks/useBoundingClientRect'
import { NFT_SOFT_STAKING } from 'src/constant'
export const useSoftPledgeInfoStore = defineStore(
  'softPledgeInfo',
  () => {
    const route = useRoute()
    const reawardList = ref([])
    const playingInfo = ref(null)
    const { execute: executeReawardList } = useAsyncState(
      async () => {
        const data = await fetchLuckyWheelInfo()
        reawardList.value = data.turntableInfo
        playingInfo.value = data.PlayingInfo
      },
      null,
      {
        immediate: false,
      },
    )
    const { isLoading: drawLoading, execute: executeDrawLuckWheel } =
      useAsyncState(
        async () => {
          const data = await fetchDrawLuckyWheel()
          executeReawardList()
          return data.id
        },
        null,
        {
          immediate: false,
          onError(error) {
            message.error(codeToMessage(error?.data?.code))
          },
        },
      )
    const { isLoading: signInLoading, execute: executeSignIn } = useAsyncState(
      async () => {
        const data = await fetchSignIn()
        executeReawardList()
        return Number(data.increased_points)
      },
      null,
      {
        immediate: false,
        onError(error) {
          message.error(codeToMessage(error?.data?.code))
          throw error
        },
      },
    )
    async function drawLuckWheel() {
      try {
        const data = await fetchDrawLuckyWheel()
        executeReawardList()
        return data.item_id
      } catch (error) {
        message.error(codeToMessage(error?.data?.code))
      }
    }

    function resetSoftPledgeStore() {
      reawardList.value = []
      playingInfo.value = null
    }
    watchEffect(() => {
      if (getHasRouterActivation(route.matched, 'softPledge')) {
        executeReawardList()
      }
    })
    return {
      reawardList,
      playingInfo,
      drawLoading,
      signInLoading,
      drawLuckWheel,
      executeDrawLuckWheel,
      executeSignIn,
      executeReawardList,
      resetSoftPledgeStore,
    }
  },
  // {
  //   persist: true,
  // },
)

export const useNFTSoftPledgeStore = defineStore(
  'NFTSoftPledge',
  () => {
    const message = useMessage()
    const { userInfo } = storeToRefs(useUserInfoStore())
    const route = useRoute()
    // 选中的组合
    const activeDeckIndexRef = ref(0)
    // 引导示例选中
    const activeDeckIndexComputed = computed(() => {
      if (guideShowRef.value) {
        return 0
      } else {
        return activeDeckIndexRef.value
      }
    })
    // 组合{id,nft,pfpList}
    const deckListRef = ref([])
    // 引导组合示例
    const deckGuideListRef = ref([])
    const deckListComputed = computed(() => {
      if (guideShowRef.value) {
        return deckGuideListRef.value
      } else {
        return deckListRef.value
      }
    })
    // 初始状态
    const notDeckComputed = computed(() => !deckListComputed.value.length)
    // 引导页
    const guideShowRef = ref(false)

    // 初始引导页状态
    const exitGuideShowRef = ref(false)

    // 是否显示初始引导页
    const emptyGuideComputed = computed(
      () =>
        notDeckComputed.value && exitGuideShowRef.value && !guideShowRef.value,
    )

    // 当前步数
    const guideCurrentIndexRef = ref(0)
    // const guideCurrentIndexRef = ref(2)

    const pfpList = ref([])
    async function fetchData() {
      const list = await fetchDeskData()
      exitGuideShowRef.value = true
      const nftIdList = list.map(item => item.token)
      const pfpPList = list.map(item => item.pfp)
      const nftPList = nftIdList.map(id => fetchNFTInfo(id))
      const pfpPListPromises = pfpPList.map(group =>
        Promise.all(
          group.map(async item => {
            const details = await fetchPFPDetail1(item.pfp_token)
            return {
              ...details,
              position: item.position,
              bonus: item.bonus,
              point: item.add_point,
            }
          }),
        ),
      )

      const listData = await Promise.all([...nftPList, ...pfpPListPromises])
      const nftlist = listData.slice(0, nftPList.length)
      const pfpList = listData.slice(nftPList.length)

      deckListRef.value = nftlist.map((nft, index) => {
        return {
          id: nft.id,
          nft,
          pfpList: pfpList[index],
          data: list[index],
        }
      })
    }

    // 未创建组合的NFT列表
    const unAddNFTList = ref([])
    // 未创建组合的PFP列表
    const unAddPFPList = ref([])
    const ownNFT = ref([])
    const ownPFP = ref([])
    const hasNFTComputed = computed(() => {
      return !!ownNFT.value.length
    })
    const hasPFPComputed = computed(() => {
      return !!ownPFP.value.length
    })

    async function fetchNFTList() {
      try {
        const list = await fetchUnAddNFTList({
          wallet: userInfo.value?.wallet,
        })
        ownNFT.value = list
        const nftList = list.map(id => fetchNFTInfo(id))
        const listData = await Promise.all([...nftList])
        unAddNFTList.value = listData
      } catch (error) {
        // TODO 拦截重发问题
        if (
          error?.data?.code === AppHttpCode.INVALID_PARAM &&
          userInfo.value?.wallet
        ) {
          return fetchNFTList()
        }
        return []
      }
    }

    async function fetchPFPList() {
      try {
        const list = await fetchUnAddPFPList({
          wallet: userInfo.value?.wallet,
        })
        ownPFP.value = list
        const pfpList = list.map(id => fetchPFPDetail1(id))
        const listData = await Promise.all([...pfpList])
        unAddPFPList.value = listData
      } catch (error) {
        // TODO 拦截重发问题
        if (
          error?.data?.code === AppHttpCode.INVALID_PARAM &&
          userInfo.value?.wallet
        ) {
          return fetchPFPList()
        }
        return []
      }
    }
    // NFT页面整体信息
    async function fetchDeskData() {
      try {
        const data = await fetchNFTStakingInfo({
          wallet: userInfo.value?.wallet,
        })
        return data
      } catch (error) {
        // TODO 拦截重发问题
        if (
          error?.data?.code === AppHttpCode.INVALID_PARAM &&
          userInfo.value?.wallet
        ) {
          return fetchDeskData()
        }
        return []
      }
    }
    // 创建NFT组合
    const { isLoading: addLoadingRef, execute: addNFTStakingCombination } =
      useAsyncState(
        async tokenId => {
          await fetchAddNFTStakingCombination({
            wallet: userInfo.value?.wallet,
            nft_token: tokenId,
          })
          message.success('Create successful.')
          fetchData()
          fetchNFTList()
        },
        null,
        {
          immediate: false,
          onError(error) {
            message.error(codeToMessage(error?.data?.code))
            throw error
          },
        },
      )

    // 删除NFT组合
    const {
      isLoading: deleteLoadingRef,
      execute: deleteNFTStakingCombination,
    } = useAsyncState(
      async ({ tokenId, id }) => {
        await fetchDeleteNFTStakingCombination({
          combination_id: id,
          nft_token: tokenId,
        })
        message.success('Delete successful.')
        fetchData()
        fetchNFTList()
        fetchPFPList()
      },
      null,
      {
        immediate: false,
        onError(error) {
          message.error(codeToMessage(error?.data?.code))
          throw error
        },
      },
    )

    // 创建PFP组合
    const { isLoading: addPFPLoadingRef, execute: addPFPStakingCombination } =
      useAsyncState(
        async (id, tokenId, index) => {
          await fetchAddPFP({
            wallet: userInfo.value?.wallet,
            group_id: id,
            pfp_token: tokenId,
            position: index,
          })
          message.success('Create successful.')
          fetchData()
          fetchPFPList()
        },
        null,
        {
          immediate: false,
          onError(error) {
            message.error(codeToMessage(error?.data?.code))
            throw error
          },
        },
      )

    // 删除PFP组合
    const {
      isLoading: deletePFPLoadingRef,
      execute: deletePFPStakingCombination,
    } = useAsyncState(
      async (id, tokenId, index, deleteOne) => {
        await fetchDeletePFP({
          wallet: userInfo.value?.wallet,
          group_id: id,
          pfp_token: tokenId,
          position: index,
          remove: deleteOne,
        })
        message.success('Remove successful.')
        fetchData()
        fetchPFPList()
      },
      null,
      {
        immediate: false,
        onError(error) {
          console.log(error)
          message.error(codeToMessage(error?.data?.code))
          throw error
        },
      },
    )

    // 初始引导页组合示例
    const emptyGuideListRef = ref([])
    async function changeDeckGuideList() {
      const nftIdList = [6, 82]
      const nftPList = nftIdList.map(id => fetchNFTInfo(id))
      const data = {
        combination_bonus: '0%',
        integral: '1298207',
        nft_next_hour_integral: '15',
        nft_rarity_bonus: '100%',
        pfp_rarity_bonus: '0%',
        nft_have: 1,
        create_day: 3,
        nft_holdings_bonus: '30%',
        nft_staking_duration: '20%',
        pfp_rarity_bonus: '0%',
        total_bonus_sum: 150,
      }

      const pfpIdList = [2973, 81, 410, 15, 436]
      const pfpPList = pfpIdList.map(id => fetchPFPDetail1(id))

      const listData = await Promise.all([...nftPList, ...pfpPList])
      const nftlist = listData.splice(0, nftPList.length)
      const pfplist = listData
      const pfpsList = chunk(pfplist, 5)
      deckGuideListRef.value = nftlist.map((nft, index) => {
        return {
          id: nft.id,
          nft,
          pfpList: pfpsList[index],
          data,
        }
      })
      emptyGuideListRef.value = nftlist.map((nft, index) => {
        return {
          id: nft.id,
          nft,
          pfpList: pfpsList[index],
          data,
        }
      })
    }
    function startGuide() {
      if (!guideShowRef.value) {
        guideCurrentIndexRef.value = 0
        guideShowRef.value = true
      }
    }
    function closeGuide() {
      if (guideShowRef.value) {
        guideShowRef.value = false
        exitGuideShowRef.value = false
        guideCurrentIndexRef.value = 0
        emptyGuideListRef.value = []
      }
    }

    // 关闭初始引导页
    function closeEmptyGuide() {
      exitGuideShowRef.value = false
      emptyGuideListRef.value = []
    }

    watchEffect(() => {
      if (getHasRouterActivation(route.matched, NFT_SOFT_STAKING)) {
        changeDeckGuideList()
        fetchData()
        fetchNFTList()
        fetchPFPList()
      }
    })

    return {
      activeDeckIndex: activeDeckIndexRef,
      activeDeckIndexComputed,
      unAddNFTList,
      unAddPFPList,
      pfpList,
      hasNFT: hasNFTComputed,
      hasPFP: hasPFPComputed,
      deckList: deckListComputed,
      notDeck: notDeckComputed,
      guideShow: guideShowRef,
      guideCurrentIndex: guideCurrentIndexRef,
      addLoadingRef,
      deleteLoadingRef,
      addPFPLoadingRef,
      deletePFPLoadingRef,
      emptyGuideShow: emptyGuideComputed,
      emptyGuideList: emptyGuideListRef,
      startGuide,
      closeGuide,
      closeEmptyGuide,
      fetchNFTList,
      addNFTStakingCombination,
      deleteNFTStakingCombination,
      addPFPStakingCombination,
      deletePFPStakingCombination,
    }
  },
  // {
  //   persist: true,
  // },
)
export const useNFTSoftPledgeDomStore = defineStore('NFTSoftPledgeDom', () => {
  const { guideShow, guideCurrentIndex } = storeToRefs(useNFTSoftPledgeStore())
  const panelDomRef = ref(null) // 左侧边栏
  const contentDomRef = ref(null) // 左侧边栏内容
  const setp1NFT1DomRef = ref(null) // nft第一个
  const setp1NFT2DomRef = ref(null) // nft第2个
  const setp1NFTAddDomRef = ref(null) // nft add
  const pfpBoxDomRef = ref(null) // 中间pfp 的 ul
  const mainContainerLeftDomRef = ref(null) // 中间
  const mainDomRef = ref(null) // 中间和右侧边栏
  const deckDomRef = ref(null) // 右侧边栏
  const bounsHeaderDomRef = ref(null) // 右侧边栏赏金头部
  const bounsBottomDomRef = ref(null) // 右侧边栏赏金底部
  const totalStakingPointDomRef = ref(null) // 右侧边栏总质押积分
  const redemptionDomRef = ref(null) // 右侧边栏赎回
  const deleteDeckDomRef = ref(null) // 右侧边栏删除组合
  const panelSizeRef = useBoundingClientRect(panelDomRef).dataRef
  const setp1NFT1SizeRef = useBoundingClientRect(setp1NFT1DomRef).dataRef
  const setp1NFT2SizeRef = useBoundingClientRect(setp1NFT2DomRef).dataRef
  const { dataRef: setp1NFTAddSizeRef, handleResize: setp1NFTAddSizeResize } =
    useBoundingClientRect(setp1NFTAddDomRef, true)
  watch([guideShow, guideCurrentIndex], ([show, index]) => {
    if (show && !index) {
      nextTick().then(setp1NFTAddSizeResize)
    }
  })
  const pfpBoxSizeRef = useBoundingClientRect(pfpBoxDomRef).dataRef
  const mainContainerLeftSizeRef = useBoundingClientRect(
    mainContainerLeftDomRef,
  ).dataRef
  const deckSizeRef = useBoundingClientRect(deckDomRef).dataRef
  const bounsHeaderSizeRef = useBoundingClientRect(bounsHeaderDomRef).dataRef
  const bounsBottomSizeRef = useBoundingClientRect(bounsBottomDomRef).dataRef
  const totalStakingPointSizeRef = useBoundingClientRect(
    totalStakingPointDomRef,
  ).dataRef
  const redemptionSizeRef = useBoundingClientRect(redemptionDomRef).dataRef
  const deleteDeckSizeRef = useBoundingClientRect(deleteDeckDomRef).dataRef
  // const mainSizeRef = useBoundingClientRect(mainDomRef).dataRef
  const contentPaddingTopComputed = computed(() => {
    const dom = contentDomRef.value
    if (dom) {
      return parseInt(window.getComputedStyle(dom)['paddingTop'])
    }
    return 0
  })
  const mainContentPaddingTopComputed = computed(() => {
    const dom = mainDomRef.value
    if (dom) {
      return parseInt(window.getComputedStyle(dom)['paddingTop'])
    }
    return 0
  })
  const mainContainerLeftComputed = computed(() => {
    const dom = mainContainerLeftDomRef.value
    if (dom) {
      return parseInt(window.getComputedStyle(dom)['marginRight'])
    }
    return 0
  })
  const modelPadding = ref(16)
  // 右侧栏顶部距离
  const modelGapTopComputed = computed(() => {
    return contentPaddingTopComputed.value - modelPadding.value
  })
  // 左侧栏顶部距离
  const leftGapTopComputed = computed(() => {
    return panelSizeRef.value.top + mainContentPaddingTopComputed.value
  })
  // const modelGapBottomComputed = computed(() => {
  //   return modelPadding.value
  // })
  return {
    panelDom: panelDomRef,
    contentDom: contentDomRef,
    setp1NFT1Dom: setp1NFT1DomRef,
    setp1NFT2Dom: setp1NFT2DomRef,
    setp1NFTAddDom: setp1NFTAddDomRef,
    pfpBoxDom: pfpBoxDomRef,
    mainContainerLeftDom: mainContainerLeftDomRef,
    mainDom: mainDomRef,
    deckDom: deckDomRef,
    bounsHeaderDom: bounsHeaderDomRef,
    bounsBottomDom: bounsBottomDomRef,
    totalStakingPointDom: totalStakingPointDomRef,
    redemptionDom: redemptionDomRef,
    deleteDeckDom: deleteDeckDomRef,
    panelSize: panelSizeRef,
    setp1NFT1Size: setp1NFT1SizeRef,
    setp1NFT2Size: setp1NFT2SizeRef,
    setp1NFTAddSize: setp1NFTAddSizeRef,
    pfpBoxSize: pfpBoxSizeRef,
    mainContainerLeftSize: mainContainerLeftSizeRef,
    deckSize: deckSizeRef,
    bounsHeaderSize: bounsHeaderSizeRef,
    bounsBottomSize: bounsBottomSizeRef,
    totalStakingPointSize: totalStakingPointSizeRef,
    redemptionSize: redemptionSizeRef,
    deleteDeckSize: deleteDeckSizeRef,
    // modelBottomGap: modelGapBottomComputed,
    modelGapTop: modelGapTopComputed,
    leftGapTop: leftGapTopComputed,
    contentPaddingTop: contentPaddingTopComputed,
    mainContentPaddingTop: mainContentPaddingTopComputed,
    mainContainerLeft: mainContainerLeftComputed,
    modelPadding,
  }
})
