import { ref, watch, computed } from 'vue'
import { useAsyncState } from '@vueuse/core'
import {
  fetchNFTCardBind,
  fetchNFTCardList,
  fetchNFTCardUnbind,
  fetchNFTInfo,
  fetchPFPDetail1,
  fetchStrxngersNFTDetail,
} from 'src/api'
import { useUserInfoStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import { getWalletSign, splitNFTName } from 'src/helpers/blockchain'
import { useMessage } from 'naive-ui'
import { errorCodeToMessage } from 'src/helpers/utils'
import { timeLeftHHMM } from '@BOGX/utils'
export function useNFTCards(emit) {
  const { bindAddressComputed } = storeToRefs(useUserInfoStore())
  const listRef = ref([])
  const message = useMessage()
  const { execute: executeList } = useAsyncState(
    async nft_type => {
      const wallet = bindAddressComputed.value
      if (wallet) {
        const dataList = await fetchNFTCardList({
          wallet,
          nft_type,
        })
        const pList = []
        Array.from(nftMap.keys()).forEach(type => {
          const data = dataList[type]
          if (data) {
            const dataBindList = data.bind_list
            if (dataBindList) {
              Object.keys(dataBindList).forEach(tokenId => {
                const fetchDetail = fetchItemDetail(
                  type,
                  tokenId,
                  dataBindList[tokenId],
                  [],
                )
                fetchDetail && pList.push(fetchDetail)
              })
            }
            const dataUnbindList = data.unbind
            const lockUnbindList = data?.lock_unbind
            if (dataUnbindList) {
              dataUnbindList.forEach(tokenId => {
                const fetchDetail = fetchItemDetail(
                  type,
                  tokenId,
                  {},
                  lockUnbindList || [],
                )
                fetchDetail && pList.push(fetchDetail)
              })
            }
          }
        })
        listRef.value = await Promise.all(pList)
      } else {
        listRef.value = []
      }
    },
    null,
    {
      onError(error) {
        console.log('error', error)
      },
    },
  )
  const { execute: executeBind, isLoading: loadingBind } = useAsyncState(
    async (nft_type, token_id, char_id) => {
      const data = await handleEdit()
      if (data) {
        await fetchNFTCardBind({
          nft_type,
          token_id,
          char_id,
          ...data,
        })
        emit('success')
        await executeList()
        message.success('bind success')
      }
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
        // message.error('bind fail')
        errorCodeToMessage(error)
      },
    },
  )
  const { execute: executeUnbind, isLoading: loadingUnbind } = useAsyncState(
    async (nft_type, token_id, char_id, bind_id) => {
      const data = await handleEdit()
      if (data) {
        await fetchNFTCardUnbind({
          nft_type,
          token_id,
          char_id,
          bind_id,
          ...data,
        })
        emit('success')
        await executeList()
        message.success('unbind success')
      }
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
        errorCodeToMessage(error)
        // message.error('unbind fail')
      },
    },
  )
  const loadingEditRef = computed(
    () => loadingBind.value || loadingUnbind.value,
  )
  watch(bindAddressComputed, () => {
    executeList()
  })
  async function handleEdit() {
    const wallet = bindAddressComputed.value
    const web3Signed = await getWalletSign()
    if (wallet && web3Signed) {
      return {
        wallet,
        web3Signed,
      }
    } else {
      return false
    }
  }
  return {
    listRef,
    loadingEditRef,
    executeList,
    executeBind,
    executeUnbind,
  }
}

function countdown(targetTimestamp) {
  if (!targetTimestamp) return
  const now = Date.now() // 当前时间的时间戳

  if (now >= targetTimestamp) {
    return false // 倒计时结束
  }

  const remainingTime = targetTimestamp - now
  const hours = Math.floor(remainingTime / (1000 * 60 * 60))
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60))

  // 格式化输出 HH:MM
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

  return formattedTime
}

function fetchNFTDetail(type, tokenId) {
  return nftMap.get(type)?.getDetail?.(tokenId)
}

function fetchItemDetail(type, tokenId, bindData = {}, unbindList = []) {
  const fetchDetail = fetchNFTDetail(type, tokenId)
  const currentItem = unbindList?.find(item => String(item.id) === tokenId)
  if (fetchDetail) {
    return fetchDetail.then(data => {
      const { name } = splitNFTName(data.name)
      return {
        data,
        type,
        roleId: bindData.uid,
        id: bindData.id,
        typeText: nftMap.get(type)?.typeText,
        operateType: Number(!!bindData.uid), // 1解绑操作，0绑定操作
        coolingTimeText: timeLeftHHMM(Date.now(), currentItem?.time * 1000),
        name,
      }
    })
  }
}

const nftMap = new Map([
  [
    'nft',
    {
      getDetail: fetchNFTInfo,
      typeText: 'Genesis NFT',
    },
  ],
  [
    'pfp',
    {
      getDetail: fetchPFPDetail1,
      typeText: 'PFP',
    },
  ],
  [
    'strxngers',
    {
      getDetail: fetchStrxngersNFTDetail,
      typeText: 'Strxngers NFT',
    },
  ],
])

const border = `1px dashed #696157`
const color = '#0C0B09'
const placeholderColor = '#A39883'
export const selectTheme = {
  peers: {
    InternalSelection: {
      color,
      colorActive: color,
      colorFocus: color,
      heightMedium: '27px',
      border,
      borderActive: border,
      borderFocus: border,
      borderHover: border,
      placeholderColor,
      textColor: placeholderColor,
    },
    InternalSelectMenu: {
      color: '#403B34',
    },
  },
}
// TEST 签名测试
// import { hashMessage, recoverAddress } from 'viem'
// const signature =
//   '0x3444271554a197c96d835f4d68cedf882787fc8c3c53fefdf48e4c121f30e0b36017137051db1a66b8328e7dbdd08d38e8fe48e98a5bb2152f9d30774ea59cbd1c' // 签名
// const message = '66e4f74cc3047' // 原始消息
// const signerAddress = '0xcfc5879650D8aDFB4e03d41658eEDb7911589de8' // 签名者的地址
// // 将消息 hash 化
// const messageHash = hashMessage(message)

// // 从签名中恢复地址
// const recoveredAddress = recoverAddress({
//   hash: messageHash,
//   signature: signature,
// })
// recoveredAddress.then(data => {
//   console.log('data', data)
//   if (data.toLowerCase() === signerAddress.toLowerCase()) {
//     console.log('签名者地址验证成功')
//   } else {
//     console.log('签名者地址验证失败')
//   }
// })
