import abi from 'src/sdk/blockchain/abi/AirdropToken.json'
import { SoulJadeStore } from '../soulJade'
import { accountRef } from 'src/sdk/blockchain'
import { formatUnits, parseUnits } from 'viem'
class SoulJade extends SoulJadeStore {
  constructor() {
    super()
    this.setAbi(abi)
  }
  async mint(chainId, number, price, address) {
    const data = await super.getBalances({
      functionName: 'balanceOf',
      args: [accountRef.value?.address],
    })

    const allowance = await super.getAllowance({
      functionName: 'allowance',
      args: [accountRef?.value.address, address],
    })

    const allowanceCount = +formatUnits(allowance, 6)

    console.log('allowanceCount', allowanceCount)
    const priceFormatUsdc = formatUnits(data, 6)
    if (priceFormatUsdc < (+price * 100 * number) / 100) {
      throw new Error('40001')
    }

    // 将单价转换为 wei 单位
    const priceInWei = parseUnits(new String(price), 6) // ETH 转换为 wei
    console.log(priceInWei)

    // 计算总价 (priceInWei * quantity)
    const totalPriceInWei = priceInWei * BigInt(number)
    console.log(totalPriceInWei)
    if (allowanceCount < (+price * 100 * number) / 100) {
      await super.mint({
        functionName: 'approve',
        args: [address, totalPriceInWei],
      })
    }
  }
}

export const soulJade = new SoulJade()
