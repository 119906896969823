<script setup>
import { hasPhone } from 'src/stores/media'
import { NPopover } from 'naive-ui'

const themeOverrides = {
  padding: !hasPhone ? '8px 14px' : '0px 8px 2px',
  color: '#8d4040',
}
</script>

<template>
  <NPopover
    :theme-overrides="themeOverrides"
    class="RedPopover"
    arrow-class=".RedPopover-arrow-class"
    content-class=".RedPopover-content-class"
  >
    <template #trigger>
      <slot name="trigger"></slot>
    </template>
    <slot></slot>
  </NPopover>
</template>

<style lang="scss">
.RedPopover {
  &.n-popover,
  .n-popover-arrow {
    @apply border border-solid;
  }
}

.BlownPopover {
  &.n-popover,
  .n-popover-arrow {
    @apply border border-solid border-#594F43;
  }
}

.GrayPopover {
  &.n-popover,
  .n-popover-arrow {
    @apply border border-solid border-#7A818C;
  }
}
</style>
