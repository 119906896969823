<script setup>
import Table from './Table'
import { useHistoryTable } from './index'
import bian_copyImg from 'src/asset/softPledge/inventory/bian_copy.webp'
import bian_usedImg from 'src/asset/softPledge/inventory/bian_used.webp'
import Observe from 'src/components/Observe'
const props = defineProps({
  list: {
    type: Array,
    default() {
      return []
    },
  },
  hasNFT: Boolean,
})
const { listRef, handleCopy } = useHistoryTable(props)
function getTypeText(type) {
  let text
  switch (type) {
    case 'nft':
      text = 'Orisols'
      break
    case 'pfp':
      text = 'Ethereal'
      break
  }
  return text ? text + ' ' : text
}
</script>
<template>
  <Table>
    <thead>
      <tr>
        <th v-if="hasNFT"><div class="text-center">NFT NUMBER</div></th>
        <th><div class="text-center">MODE</div></th>
        <th><div class="text-center">ITEM</div></th>
        <th><div class="text-center">TIME</div></th>
        <th><div class="text-center">REDEEM CODE</div></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(item, index) in listRef" :key="item.type + item.token">
        <tr>
          <td v-if="hasNFT">
            <div class="flex-center text-#fff px-2" v-if="item.token">
              {{ getTypeText(item.type) }}#{{ item.token }}
            </div>
          </td>
          <td>
            <div class="text-#B5DCEB text-center">{{ item.key }}</div>
          </td>
          <td>
            <div class="text-center text-#B5DCEB">
              {{ item.log }}
              <!-- <span class="text-#52D058 inline-block mr-0.5">+245</span>
              <span class="text-#B5DCEB">IMX points</span> -->
            </div>
          </td>
          <td>
            <div class="text-center text-#B5DCEB">
              {{ item.time }}
            </div>
          </td>

          <td>
            <div class="flex-center text-#EBC06C">
              <span v-if="item.cdkey">{{
                item.showCode ? item.cdkey : item.noneCode
              }}</span>
              <span v-else>--</span>
            </div>
          </td>
          <td>
            <div class="flex-center" v-if="item.cdkey">
              <div
                class="relative w-105px text-#EBC06C flex-center mouse_pointer"
                @click="!item.use && handleCopy(item.cdkey)"
              >
                <img
                  :src="item.use ? bian_usedImg : bian_copyImg"
                  class="w-full"
                  alt=""
                />
                <span class="absolute top-0 leading-4">{{
                  item.use ? 'used' : 'copy'
                }}</span>
                <!-- <span class="absolute top-0 leading-4">used</span> -->
              </div>
              <Observe class="ml-2" v-model:checked="item.showCode"></Observe>
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </Table>
</template>
