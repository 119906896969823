export const MUSIC_PATH = `${import.meta.env.VITE_FILE_PATH}/music/`

// 外部链接
export const DISCORD_PATH = 'https://discord.gg/bogx'
export const TWITTER_PATH = 'https://twitter.com/BladeofgodX'
export const XTERIO_PATH = 'https://xter.io/games/5b933434e2e9'
export const EPIC_STORE_PATH = 'https://store.epicgames.com/en-US/p/bogx-a639f3'
export const OPEN_SEA_PATH =
  'https://opensea.io/collection/bogx-genesis-orisols'
export const OKX_PATH =
  'https://www.okx.com/web3/marketplace/nft/collection/eth/bogx-genesis-orisols'
export const BLUR_PATH = 'https://blur.io/collection/bogx-genesis-orisols'
export const ELEMENT_PATH =
  'https://element.market/collections/bogx-genesis-orisols'

export const OKX_SULCARD_PATH =
  'https://www.okx.com/zh-hans/web3/marketplace/nft/collection/imx-zk/bogx-soul-card-nft'
export const TOKEN_TROVE_PATH =
  'https://tokentrove.com/collection/BladeofGodXSoulCards'
export const SPHERE_PATH =
  'https://sphere.market/immutable/collection/0x095bDf251bEE0C9462f0c2B1FF5ddd78bB401480'

// pfp交易市场链接
export const PFP_OKX_PATH =
  'https://www.okx.com/zh-hans/web3/marketplace/nft/collection/eth/bogx-ethereal'
export const PFP_OPENSEA_PATH = 'https://opensea.io/collection/bogx-ethereal'
export const PFP_ELEMENT_PATH =
  'https://element.market/collections/bogx-ethereal'
