<script setup>
// import videojs from 'video.js'
import { defineProps, onMounted, ref, watch } from 'vue'
import { loadCSS, loadScript } from '@BOGX/utils'

const emit = defineEmits(['ended'])
const props = defineProps({
  src: {
    type: String,
  },
  width: [String, Number],
  height: [String, Number],
  controls: {
    type: Boolean,
    default: true,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  loop: {
    type: Boolean,
    default: false,
  },
  muted: {
    type: Boolean,
    default: false,
  },
  preload: {
    type: String,
    default: 'auto',
  },
  poster: String,
  hiddenControls: {
    type: Boolean,
    default: false,
  },
})
const videoRef = ref(null)

onMounted(() => {
  loadScript('https://vjs.zencdn.net/8.3.0/video.min.js').then(() => {
    const player = window.videojs(
      videoRef.value,
      {
        autoplay: props.autoplay,
        muted: props.muted,
        loop: props.loop,
        width: props.width,
        height: props.height,
        controls: props.controls,
        preload: props.preload,
        poster: props.poster,
      },
      function onPlayerReady() {
        // In this context, `this` is the player that was created by Video.js.

        // How about an event listener?
        this.on('ended', function () {
          emit('ended')
        })
      },
    )
    // 监听 play 事件，隐藏 controls 栏
    player.on('play', function () {
      if (props.hiddenControls) {
        player.controls(false) // 去掉 controls 栏
      }
    })
    watch(
      () => props.src,
      src => {
        src && player.src(src)
      },
      {
        immediate: true,
      },
    )
    watch(
      () => props.height,
      h => {
        player.height(h)
      },
    )
    watch(
      () => props.width,
      w => {
        player.width(w)
      },
    )
    watch(
      () => props.poster,
      poster => {
        player.poster(poster)
      },
    )
    watch(
      () => props.loop,
      loop => {
        player.loop(loop)
      },
    )
  })
  loadCSS('https://vjs.zencdn.net/8.3.0/video-js.min.css')
})
</script>

<template>
  <video ref="videoRef" class="video-js" webkit-playsinline playsinline></video>
</template>
