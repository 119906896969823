<script setup>
import closeImg from 'src/asset/close.webp'
const props = defineProps({
  close: {
    type: Boolean,
    default: true,
  },
})
const emit = defineEmits(['close'])
function handleClose() {
  emit('close')
}
</script>
<template>
  <div
    class="relative box_header item_tracking border border-solid border-#636B74/60"
  >
    <div class="fz-7 text-#FFFFFF flex-center h-full">
      <slot></slot>
    </div>

    <img
      v-if="close"
      :src="closeImg"
      class="mouse_pointer absolute right-25px w-30px absolute -translate-y-50% top-50%"
      @click="handleClose"
    />
  </div>
</template>

<style scoped>
.box_header {
  @apply h-64px;

  background: linear-gradient(270deg, #202830 0%, #202830 1%, #0f1215 100%);
}
</style>
