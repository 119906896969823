<script setup>
import { watch } from 'vue'
import Popover from 'src/components/Popover'
import Poster from 'src/components/Poster'
import Countdown from 'src/pages/PFP/mint/components/Countdown'
import { useMintInfo } from '.'
import dayjs from 'dayjs'
import bgImg from 'src/asset/mint/poster/pfp.webp'
const emit = defineEmits(['go', 'show', 'update:show', 'mintShow'])
const {
  serverTimeComputed,
  startTimeComputed,
  endTimeComputed,
  mintCompleteComputed,
} = useMintInfo()

// 开始72小时前和mint中常驻
let showSotp
showSotp = watch(
  [
    serverTimeComputed,
    startTimeComputed,
    endTimeComputed,
    mintCompleteComputed,
  ],
  ([serverTime, startTime, endTime, mintComplete]) => {
    if (!(serverTime && startTime && endTime)) return
    if (mintComplete) {
      updateShow(false)
      return
    }
    showSotp?.()
    const server = dayjs(serverTime) // 当前服务器时间
    const start = dayjs(startTime) // 活动开始时间
    const end = dayjs(endTime) // 活动结束时间
    // 72 小时前
    const startMinus72Hours = start.subtract(72, 'hour')
    const show =
      (server.isAfter(startMinus72Hours) && server.isBefore(start)) ||
      server.isBetween(start, end)
    handleMintShow(show)
  },
  {
    immutable: true,
  },
)
function handleGoTo() {
  emit('go')
}
function handleClose() {
  updateShow(false)
}
function updateShow(value) {
  emit('show', value)
  emit('update:show', value)
}
function handleMintShow(show) {
  emit('mintShow', show)
}
</script>

<template>
  <Popover @update:show="updateShow">
    <template #trigger>
      <slot></slot>
    </template>
    <Poster
      class="item-box"
      @click="handleGoTo"
      @close="handleClose"
      :url="bgImg"
    >
      <Countdown
        class="whitespace-nowrap w-130% mint_countdown"
        :start-time="startTimeComputed"
        :end-time="endTimeComputed"
        :current-time="serverTimeComputed"
        @end="handleClose"
      ></Countdown>
      <!-- <div class="text-center">
        <div class="fz-2">ETHEREAL MINT EVENT</div>
        <div class="fz-1 text-#FF7D31">
          The Ethereal Collection is recently available for minting!
        </div>
      </div> -->
    </Poster>
  </Popover>
</template>

<style scoped lang="scss">
.item-box {
  .mint_countdown {
    transform: scale(0.66) translate(7%, -25%);
    transform-origin: left;
  }

  animation: attractive 0.4s ease-in-out infinite alternate;
}

@keyframes attractive {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-5%);
  }
}
</style>
