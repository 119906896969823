import { writeContract } from 'src/sdk/blockchain'
import {
  useDecorator,
  baseBlockchainParams,
  BaseBlockchain,
} from './blockchain'

export class Pool extends BaseBlockchain {
  constructor() {
    super()
  }
  lock(data) {
    return writeContract({
      functionName: 'lock',
      ...data,
    })
  }
}

const NFTPoolProty = Pool.prototype
useDecorator(NFTPoolProty, 'lock', baseBlockchainParams)
