<script setup>
import { ref } from 'vue'
import Poster from 'src/components/Poster'
import bgImg from 'src/asset/home/test_bg.webp'
const show = ref(true)

function handleGoTo() {
  window.open(
    'https://medium.com/@voidlabs2024/bogx-third-test-guide-280dc6fe4cff',
  )
}
function handleClose() {
  show.value = false
}
</script>

<template>
  <Poster
    v-if="show"
    class="item-box"
    @click="handleGoTo"
    @close="handleClose"
    :url="bgImg"
  >
    <div class="text-center">
      <div class="fz-2">BOGX 3RD TEST NOW LIVE</div>
      <div class="fz-1 text-#FF7D31">
        Click to explore more about the 3rd test!
      </div>
    </div>
  </Poster>
</template>

<style scoped lang="scss">
.item-box {
  @apply fixed left-0 z-2 top-60px;
}
</style>
