import {
  ATTRIBUTES,
  SPECIES,
  NFP_TYPE,
  NFP_VALUE,
  HIDE_ATTRIBUTES,
} from '@BOGX/web3Data'

const pfpBaseFilePath = import.meta.env.VITE_PFP_BASE_FILE_PATH
export function packPFPList(list) {
  list.forEach(packPFP)
  return list
}
export function packPFP(item) {
  item.id = Number(item.name.match(/#(\d+)/)[1])
  item.viewName = 'Bogx PFP'
  const attributes = item[ATTRIBUTES]
  // 隐藏部分属性
  HIDE_ATTRIBUTES.forEach(key => deleteAttributes(attributes, key))
  // 种族
  const index = attributes.findIndex(data => data[NFP_TYPE] === SPECIES)
  const speciesData = attributes[index]
  if (speciesData) {
    item[SPECIES] = speciesData[NFP_VALUE]
    attributes.splice(index, 1)
    attributes.unshift(speciesData)
  }
  item.image = `${import.meta.env.VITE_WEBRES_PATH}${pfpBaseFilePath}img/${item.image}`
  return item
}
function deleteAttributes(attributes, key) {
  const bodyIndex = attributes.findIndex(data => data[NFP_TYPE] === key)
  if (bodyIndex !== -1) {
    attributes.splice(bodyIndex, 1)
  }
}
export function getFiltersData(list) {
  const result = []
  list.forEach(item => {
    const attributes = item[ATTRIBUTES]
    attributes.forEach(attribute => {
      const key = attribute[NFP_TYPE]
      const listNameItem = getListLargeItem(result, key)
      const value = attribute.value
      const smallItem = getListSmallItem(listNameItem.childrens, value, key)
      smallItem.number++
    })
  })
  return result
}

function getListLargeItem(list, name) {
  let item = list.find(item => item.name === name)
  if (!item) {
    item = createNameItem(name)
    list.push(item)
  }
  return item
}

function createNameItem(name) {
  return {
    name,
    icon: 'PFP-filter-' + name.toLocaleUpperCase(),
    childrens: [],
  }
}

function getListSmallItem(list, name, type) {
  let item = list.find(item => item.name === name)
  if (!item) {
    item = createSmallItem(name, type)
    list.push(item)
  }
  return item
}

let typeId = 0

function createSmallItem(name, type) {
  return {
    type,
    name,
    number: 0,
    id: ++typeId,
  }
}
