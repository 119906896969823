<script setup>
import { computed, ref, watch } from 'vue'
import { NEmpty, useMessage, NSelect, NFormItem } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { errorCodeToMessage } from 'src/helpers/utils'

import { useRoleList } from 'src/pages/myCollection/hooks/useRoleList'
import { useBaseSelectData } from 'src/pages/myCollection/hooks/useBaseSelectData'

import { hasPhone } from 'src/stores/media'
import SoulBox from '../components/SoulBox.vue'
import PanelImmutable from '../components/PanelImmutable.vue'
import TransferImmutableModal from '../components/TransferImmutableModal'
import Empty from '../components/Empty'
import List from '../components/List'
import ImmutableItemDetail from '../components/ImmutableItemDetail'
import ImmutablePreviewModal from '../components/ImmutablePreviewModal'
import { useSoulList } from '../hooks/useSoulList'
import { useMintGameSoulCard } from '../hooks/useMintGameSoulCard'
import { useOrderStatus } from '../hooks/useOrderStatus'
import { useUserSoulList } from '../hooks/useUserSoulList'
import { useUnlockImmutableSoulCard } from '../hooks/useUnlockImmutableSoulCard'
import { getBtnName, idsToListTokenIdOne, idsToListItem } from '../utils'
import Mint from './components/Mint/index.vue'
import MintWait from './components/mint_wait/index.vue'
import Modal from 'src/components/Modal'
import { handleIngMessage } from '../utils'

const immutableInfoStore = useImmutableInfoStore()
const { executeSoulCoreInfoList } = immutableInfoStore
const { soulCoreInfoLockList } = storeToRefs(immutableInfoStore)
const roleListRef = useRoleList()
const { soulListComputed, mintResponseWaitDataComputed, getHasLoading } =
  useSoulList(soulCoreInfoLockList, roleListRef)
const { mintShowRef, currentStageRef, handleMintClose, handleMintShow } =
  useMintGameSoulCard()
const mintIngIdRef = ref(null)
const { startReadMoreMintStatus, pauseReadMoreMintStatus } =
  useOrderStatus(mintIngIdRef)

watch(
  mintResponseWaitDataComputed,
  data => {
    // 储存mint ing的闪卡，后续要轮询接口弹出成功mint弹窗
    if (data) {
      mintIngIdRef.value = data.Id
      startReadMoreMintStatus()
    } else if (mintIngIdRef.value && !data) {
      // mint成功，弹窗弹窗，暂停轮询
      handleMintClose()
      handleMintComplete()
      pauseReadMoreMintStatus()
    }
  },
  {
    immutable: true,
  },
)
// 区分是不是成功mint弹窗的展示
const hasMintSuccessRef = ref(false)
const mintSuccessTextComputed = computed(() => {
  if (hasMintSuccessRef.value) {
    return {
      title: 'MINT SUCCESSFUL',
      tip: 'Tip: Soul Card already minted but still in-game. To transfer or trade, you can unlock it.',
    }
  } else {
    return {}
  }
})
// 清除 mint 成功弹窗的信息，不然会一直轮询接口是否成功mint
watch(hasMintSuccessRef, value => {
  if (!value) {
    mintIngIdRef.value = null
  }
})
const { userSoulListComputed, currentUserIdRef } =
  useUserSoulList(soulListComputed)
const {
  selectDataRef,
  itemDetailShowRef,
  singleChoiceDataRef,
  previewDataRef,
  transferShowRef,
  currentSelectDataRef,
  previewShowRef,
  handleSelect,
  handleCancel,
  handleEnlarge,
  handleClose,
  handleCheck,
  handleEmptySelect,
  handleItemDetailClose,
} = useBaseSelectData()
const { isLoading: unlockLoading, execute } = useUnlockImmutableSoulCard()
const message = useMessage()
async function handleConfirmUnlock() {
  try {
    const tokenId = idsToListTokenIdOne(
      currentSelectDataRef.value,
      userSoulListComputed.value,
    )
    const { newState: state } = await execute(0, tokenId)
    executeSoulCoreInfoList()
    // const message = getStateMessage(state)
    message.success('success')
    transferShowRef.value = false
  } catch (error) {
    executeSoulCoreInfoList()
    if (error?.data?.code === 186) {
      // TODO 当处于魂卡解锁冷却期时，表示允许解锁的时间的文案
      message.warning(
        `Unlocking is still in the cooling period, unlocking time: ${error.data.data.validTime}`,
      )
    } else {
      errorCodeToMessage(error)
    }
    throw error
  }
}
function handleUnlockId(userId, ids) {
  handleUnlock(ids)
}
function handleUnlock(ids) {
  const selectData = Array.isArray(ids)
    ? ids
    : selectDataRef.value.length
      ? selectDataRef.value
      : [singleChoiceDataRef.value.Id]
  if (!selectData) {
    message.warning('Please select an item.')
  } else {
    currentSelectDataRef.value = selectData
    const data = idsToListItem(selectData, userSoulListComputed.value)
    // 1为mint流程，否则走lock流程
    if (data.type === 1) {
      singleChoiceDataRef.value = data
      handleMintShow()
    } else {
      transferShowRef.value = true
    }
  }
}
function handleDetails(data) {
  previewShowRef.value = true
  previewDataRef.value = data
}
function openMintSuccessModal(id) {
  const data = idsToListItem(id, soulListComputed.value)
  if (!data) return
  hasMintSuccessRef.value = true
  if (hasPhone) {
    handleCheck(data)
  } else {
    handleDetails(data)
  }
}
function closePreviewModal() {
  handleClose()
  hasMintSuccessRef.value = false
}
function closeItemDetailModal() {
  handleItemDetailClose()
  hasMintSuccessRef.value = false
}

function openMintWait() {
  if (mintResponseWaitDataComputed.value) {
    currentStageRef.value = 1
    mintShowRef.value = true
  } else {
    handleMintClose()
  }
}
// mint成为lock弹出弹窗
function handleMintComplete() {
  openMintSuccessModal(mintIngIdRef.value)
  executeSoulCoreInfoList()
}
function getBtnClass(item) {
  const hasLoading = getHasLoading(item)
  if (item.type === 1) {
    return `mint_button${hasLoading ? ' cursor-no-drop!' : ''}`
  } else {
    return hasLoading ? 'item_btn_disabled_green' : 'green_button'
  }
}
</script>

<template>
  <div class="h-full flex">
    <PanelImmutable
      v-if="!hasPhone"
      class="flex-shrink-0"
      :data="singleChoiceDataRef"
      @details="handleDetails"
    >
    </PanelImmutable>
    <div class="w-full flex flex-col px-3.5 py-8 md:py-10 md:pl-6">
      <NFormItem label="HERO" label-placement="left" class="md:w-350px">
        <NSelect
          v-model:value="currentUserIdRef"
          :options="roleListRef"
        ></NSelect>
      </NFormItem>
      <div class="flex flex-grow">
        <List
          v-if="userSoulListComputed.length"
          :list="userSoulListComputed"
          :box="false"
          class="px-0! py-2!"
        >
          <template #item="item">
            <SoulBox
              :data="item"
              :select-data="selectDataRef"
              :single-choice-data="singleChoiceDataRef"
              :role-list="roleListRef"
              @check="handleCheck"
              @select="handleSelect"
              @cancel="handleCancel"
              @enlarge="handleEnlarge"
              @submit="handleUnlockId"
            >
              <div
                v-if="item.responseWait"
                class="absolute inset-0 opacity-38 border border-solid pointer-events-none"
                :class="`${item.type === 1 ? 'minting_z' : 'unlocking_z'}`"
              ></div>
              <template #btn="{ handleSubmit }">
                <div
                  class="flex-center fz-3 h-32px rounded-0!"
                  :class="`${getBtnClass(item)}`"
                  @click="
                    !getHasLoading(item)
                      ? handleSubmit()
                      : handleIngMessage(item)
                  "
                >
                  {{ getBtnName(item) }}
                </div>
              </template>
            </SoulBox>
          </template>
        </List>
        <NEmpty v-else class="m-auto"></NEmpty>
        <Empty
          v-if="!hasPhone"
          v-show="selectDataRef.length"
          @click="handleEmptySelect"
        />
      </div>
    </div>
  </div>
  <ImmutableItemDetail
    v-if="hasPhone"
    v-show="itemDetailShowRef"
    :data="singleChoiceDataRef"
    :role-list="roleListRef"
    @close="closeItemDetailModal"
    @submit="handleUnlock"
  ></ImmutableItemDetail>
  <ImmutablePreviewModal
    v-model:show="previewShowRef"
    @close="closePreviewModal"
    :title="mintSuccessTextComputed.title"
    :tip="mintSuccessTextComputed.tip"
    :data="previewDataRef"
    :poster="previewDataRef?.Icon"
    :src="previewDataRef?.animation_url"
    :type="previewDataRef?.animation_url ? 2 : 1"
  ></ImmutablePreviewModal>
  <TransferImmutableModal
    v-model:show="transferShowRef"
    :loading="unlockLoading"
    text="Confirm to unlock this item?"
    tip="After unlocking, market orders will be cancelled."
    confirm-text="Transfer"
    @confirm="handleConfirmUnlock"
  ></TransferImmutableModal>
  <Modal v-model:show="mintShowRef" :mask-closable="!currentStageRef">
    <div
      class="item-bg item_tracking"
      :class="`${!currentStageRef ? 'mint_box' : 'mint_box_wait'}`"
    >
      <Mint
        @close="handleMintClose"
        @success="openMintWait"
        v-if="!currentStageRef"
        :data="singleChoiceDataRef"
      ></Mint>
      <MintWait
        v-if="currentStageRef === 1"
        :data="singleChoiceDataRef"
      ></MintWait>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
@import '../border';
@import '../components/btn';

.mint_box {
  @apply w-94vw border border-solid border-#636B74/60;
}

.mint_box_wait {
  @apply w-[758px] border border-solid border-#636B74/60;
}

.mint_button {
  @apply bg-#B9632E rounded border border-solid border-#E1802A;
  @extend .mouse_pointer;

  &:hover {
    @apply bg-#DD824B;
  }
}

.minting_z {
  @apply bg-#9C5A3D  border-#E79D5B;
}

.unlocking_z {
  @apply bg-#335656 border-#bfcdc4;
}
</style>
