<script setup>
import SearchSvg from 'src/asset/search.svg'
const emit = defineEmits(['history'])
</script>

<template>
  <div @click="emit('history')" class="flex items-center mouse_pointer">
    <div
      class="relative bg-#716657 border border-solid border-#48362F mr-1 w-19px h-19px rounded"
    >
      <SearchSvg class="w-16px absolute top-0.25 color-#C4B596"></SearchSvg>
    </div>
    <div class="fz-3 text-#DBD4C0">History</div>
  </div>
</template>
