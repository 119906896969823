import axios from 'axios'
import cryptoJS from 'crypto-js'

export const okxBaseURL = 'https://www.okx.com'
export const requestOkx = axios.create({
  withCredentials: true,
  baseURL: okxBaseURL,
})

const api_config = {
  api_key: 'e9df5f49-92a3-4526-99c2-5290b00ee0c5',
  secret_key: '664D57A194752628516B64E6B3B348BE',
  passphrase: 'Cjh123456.',
  project: '3e9f2ea56fa81993cb4184df6f936196', // 此处仅适用于 WaaS APIs
}

function preHash(timestamp, method, request_path, params) {
  let query_string = ''
  if (method === 'GET' && params) {
    query_string = '?' + new URLSearchParams(params).toString()
  }
  if (method === 'POST' && params) {
    query_string = JSON.stringify(params)
  }
  return timestamp + method + request_path + query_string
}

function sign(message, secret_key) {
  const hmac = cryptoJS.HmacSHA256(message, secret_key)
  return cryptoJS.enc.Base64.stringify(hmac)
}

function createSignature(method, request_path, params) {
  const timestamp = new Date().toISOString().slice(0, -5) + 'Z'
  const message = preHash(timestamp, method, request_path, params)
  const signature = sign(message, api_config['secret_key'])
  return { signature, timestamp }
}

requestOkx.interceptors.request.use(config => {
  const { method, url, params } = config
  const { timestamp, signature } = createSignature(
    method.toLocaleUpperCase(),
    url,
    params,
  )
  config.headers['OK-ACCESS-KEY'] = api_config['api_key']
  config.headers['OK-ACCESS-SIGN'] = signature
  config.headers['OK-ACCESS-TIMESTAMP'] = timestamp
  config.headers['OK-ACCESS-PASSPHRASE'] = api_config['passphrase']
  config.headers['OK-ACCESS-PROJECT'] = api_config['project']

  return config
})

requestOkx.interceptors.response.use(async response => {
  if (response.status === 200) {
    return response.data
  } else {
    return Promise.reject(response)
  }
})
