<script setup>
import { useMusicStore } from 'src/stores/music'
import RedPopover from 'src/components/RedPopover'

const { buttonClickAudioPlay, buttonCaptureAudioPlay } = useMusicStore()
</script>
<template>
  <RedPopover>
    <template #trigger>
      <span
        class="mouse_pointer mx-5 red-color"
        @mouseenter="buttonCaptureAudioPlay"
        @click="buttonClickAudioPlay"
        ><slot></slot
      ></span>
    </template>
    <div>Coming Soon ......</div>
  </RedPopover>
</template>
