import { requestBase, requestWeb3 } from 'src/helpers/request/index'
import axios from 'axios'
import { adaptationNFTToWeb } from 'src/helpers/nft'
import { pfpListStore } from 'src/helpers/store'
import { packPFPList, packPFP } from 'src/pages/PFP/data'
import { SinglePromise } from 'src/helpers/singlePromise'
const pfpMetadataShow = import.meta.env.VITE_PFP_METADATA_SHOW
const pfpBaseFilePath = import.meta.env.VITE_PFP_BASE_FILE_PATH

/**
 * 获取web3用签名字符串
 * @returns
 *  signMessage: 用于签名用的字符串(15分钟有效期)
 *
 */
export function fetchSignMessage() {
  return requestBase({
    params: {
      code: 51,
    },
  })
}

export function fetchMintUserInfo() {
  // return requestWeb3.get('/gnft/mintInfo')
  return {
    contract: '0x3e0361c7E8C3C87B2fa55cC042648BeC9990fCD6',
    chainId: 1,
    supply: 669,
    inWhiteList: 0,
    sign: '0x0',
    startTime: 1714485600,
    endTime: 1714514400,
    serverTime: 1714974261,
  }
}

export function fetchNFTInfo(id) {
  return axios
    .get(`${import.meta.env.VITE_NTF_INFO_PATH}/${id}`, {
      responseType: 'json',
    })
    .then(res => {
      return adaptationNFTToWeb(res.data)
    })
}

/**
 * 新增某个钱包 mint NFT完成事件
 * @param {string} walletaddr
 * @returns
 *
 */
export function fetchMintSuccessReport(params) {
  return requestBase({
    params: {
      NFTtype: import.meta.env.VITE_MINT_ID,
      ...params,
      code: 56,
    },
  })
}

/**
 * 新增某个钱包 mint NFT完成事件
 * @param {string} walletaddr
 * @returns {object}
 *  @property {number[]} mintedNFT 该钱包已经mint的nft类型
 *
 */
export function fetchMintStatusList(params) {
  return requestBase({
    params: {
      ...params,
      code: 57,
    },
  })
}
// 查看tbogx合约信息
export function fetchSbtInfo() {
  return axios
    .get(`${import.meta.env.VITE_TBOGX_INFO_PATH}`, {
      responseType: 'json',
    })
    .then(res => {
      // return {
      //   ...res.data.data,
      //   chainId: 13473,
      //   soulCard: '0x7f6f216be04d2a19c0d89d4bfac8bd4167a16afd',
      // }
      return res.data.data
    })
}

/**
 * contract: 区块链上的智能合约地址。

0x5fa2dF2cbA590a07FF7F0a26AA8A24184aF9131b
chainId: 区块链网络的ID。

421614
supply: 代币的总供应量。

5000
phase1WL: 第一阶段白名单的数量。

0
phase2WL: 第二阶段白名单的数量。

0
timeline: 项目时间线的时间戳数组（Unix时间戳）。

1718841600
1719532800
1719619200
1719705600
serverTime: 服务器时间（Unix时间戳）。

1718883202
phase: 当前项目所处的阶段。

1
 */
/**
 * 获取pfpMint信息
 * phase = 1 第一阶段白单
 * phase = 2 第二阶段白单
 * phase = 3 公开轮
 * phase = 4 已结束
 * @returns
 */
export function fetchPFPMintInfo() {
  return Promise.resolve({
    contract: '0x2B5c961BC0CD1945D4e173c1a6df38b9C1702B5d',
    chainId: 1,
    supply: 3333,
    fcfsAmount: 0,
    gtdAmount: 0,
    gtdPrice: '90000000000000000',
    fcfsPrice: '90000000000000000',
    gtdTime: [1728993600, 1729000800],
    fcfsTime: [1729000800, 1729260000],
    openTime: 1729080000,
    serverTime: 1729129190,
  })
}

// 获取pfp sig，获取签名，只能传1、或 2
/**
 *
 * @param {*} params {address: string, phase: 1 | 2}
 * @returns
 */
export function fetchPFPSig(params) {
  return axios
    .get(`${import.meta.env.VITE_PFP_API_PATH}/sig`, {
      responseType: 'json',
      params,
    })
    .then(res => {
      return res.data.data
    })
}

export function fetchPFPList() {
  if (pfpMetadataShow) {
    const list = pfpListStore.value
    if (list) return Promise.resolve(list)
    return axios
      .get(
        import.meta.env.VITE_WEBRES_PATH + `${pfpBaseFilePath}gallery.json`,
        {
          responseType: 'json',
        },
      )
      .then(({ data }) => {
        const list = packPFPList(data)
        pfpListStore.value = list
        return list
      })
  } else {
    return Promise.resolve([])
  }
}

// export function fetchPFPDetail(id) {
//   if (pfpMetadataShow) {
//     const list = pfpListStore.value
//     if (list) return Promise.resolve(list[id - 1])
//     return axios
//       .get(
//         import.meta.env.VITE_WEBRES_PATH + `${pfpBaseFilePath}metadata/` + id,
//         {
//           responseType: 'json',
//         },
//       )
//       .then(res => {
//         return packPFP(res.data)
//       })
//   } else {
//     return Promise.resolve({})
//   }
// }
export const fetchPFPListP = new SinglePromise(() => fetchPFPList())

export async function fetchPFPDetail1(id) {
  if (pfpMetadataShow) {
    const list = pfpListStore.value
    if (pfpListStore.value) return Promise.resolve(list[id - 1])
    const pfpList = await fetchPFPListP.run()
    return Promise.resolve(pfpList[id - 1])
  } else {
    return Promise.resolve({})
  }
}
export async function fetchStrxngersNFTDetail(id) {
  return {
    name: 'Strxngers #' + id,
    tokenId: id,
    animation_url: '',
    image: `https://storage.googleapis.com/nftimagebucket/tokens/0xe5b4d6b5f37cae9dc4c7384cd97038cd0573d7d2/preview/${id}.png`,
    attributes: [],
  }
}

export function fetchSoulCardWeb3Info() {
  return requestWeb3.get('/web3/info')
}
