import Inventory from 'src/pages/softPledge/Inventory/index.vue'
import NFTSoftStaking from 'src/pages/nftSoftStaking/index.vue'
import { INVENTORY, NFT_SOFT_STAKING } from 'src/constant'
const softPledgeShow = import.meta.env.VITE_SOFT_PLEDGE_SHOW
const softPledgeRouter = [
  {
    path: '/inventory',
    component: Inventory,
    name: INVENTORY,
  },
]
if (softPledgeShow) {
  softPledgeRouter.push({
    path: '/softStaking',
    component: NFTSoftStaking,
    name: NFT_SOFT_STAKING,
  })
}
export { softPledgeRouter }
