import { setWeb3Modal } from './walletConnect'
import { onEvent, useModalEvent, initDisconnectImmutable } from './event'
import { updateAccount } from './store'

export function init() {
  initDisconnectImmutable()
  setWeb3Modal()
  updateAccount()
  useModalEvent(() => {
    updateAccount()
  })
  onEvent()
}
