<script setup>
import ArrowBackGray from 'src/components/ArrowBackGray'
import { NVirtualList, NEmpty } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { remToPx } from 'src/sdk/media'
import { computed } from 'vue'

const { consumeTbogxList } = storeToRefs(useImmutableInfoStore())
const items = Array.from({ length: 100 }, (_, i) => ({
  key: `${i}`,
  value: i,
}))

const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    },
  },
  counts: {
    type: String,
    default() {
      return '0'
    },
  },
})
const emit = defineEmits(['close'])

const getStatusText = computed(() => {
  return status => {
    if (status === 4) {
      return 'Mint consume'
    } else if (status === 2) {
      return 'Claim completed'
    } else {
      return 'Claim failed'
    }
  }
})
</script>

<template>
  <div class="fixed left-0 top-0 bottom-0 z-3 h-full w-full bg-#0e161b">
    <ArrowBackGray
      class="top-3 ml-3 fixed!"
      @click="() => emit('close')"
    ></ArrowBackGray>
    <div class="flex-center w-full py-3">
      <img class="w-20 h-25.75" src="/src/asset/logo/bogx_icon.png" alt="" />
      <div class="fz-8 ml-7.5 text-#95E1E8">$tBOGX x{{ counts }}</div>
    </div>
    <div class="green-border w-full pt-3 px-6 bg-#040608 fz-3 text-#B8EBF4">
      <p class="text-left mt-0">
        1. It costs $tBOGX to mint a Soul Core into a Soul Card NFT.
      </p>
      <p class="text-left mb-0">
        2. Ways to acquire $tBOGX: Obtain Soul Gem through various in-game
        activities and convert Soul Gem to $tBOGX through AI Agent features.
      </p>
      <p class="text-left mb-0">
        3. Mint requires time and may fail if exceeded.
      </p>
    </div>
    <div
      class="w-full h-9 py-1 shadow-[0_0.3px_0_0_#6A9BA2] flex flex-items-center justify-around text-#9FD7DF"
    >
      <div class="w-50% h-full flex-center shadow-[0.3px_0_0_0_#6A9BA2]">
        TIME
      </div>
      <div class="w-50% h-full flex-center">OPERATION RECORD</div>
    </div>
    <div class="flex flex-col" :style="`height: calc(100vh - 13.5rem)`">
      <div v-if="consumeTbogxList.length">
        <NVirtualList
          :style="`height: calc(100vh - 13.5rem)`"
          :item-size="33"
          :items="consumeTbogxList"
          :padding-bottom="40"
        >
          <template #default="{ item }">
            <div
              :key="item.id"
              class="h-8 w-full shadow-[0_0.3px_0_0_#45676E] py-1 flex text-#9FD7DF fz-3"
            >
              <div
                class="w-50% flex justify-center flex-items-center h-full shadow-[0.3px_0_0_0_#6A9BA2]"
              >
                {{ item.time }} (UTC+8)
              </div>
              <div class="flex justify-center flex-items-center h-full flex-1">
                <span>{{ getStatusText(item.status) }}</span>
                <img
                  class="w-5.5 h-5 mx-0.5"
                  src="/src/asset/bogx_assets.png"
                />
                <span>$tBOGX x{{ item.token_count }}</span>
              </div>
            </div>
          </template>
        </NVirtualList>
      </div>
      <NEmpty v-else class="m-auto"></NEmpty>
    </div>
  </div>
</template>

<style scoped lang="scss">
.green-border {
  box-shadow: 0 0 0 0.3px #52747c;
}
</style>
