<script setup>
import { NPopover, NSpin, useMessage } from 'naive-ui'
import { gsap } from 'gsap'
import copy from 'copy-to-clipboard'
import { constantCase } from 'change-case'
import addIcon from 'src/asset/nftSoftStaking/deck/pfp/add_icon.webp'
import SocializeLinks from '../NFTPanel/SocializeLinks.vue'
import ItemModal from 'src/components/ItemModal'
import WalletBox from 'src/components/WalletBox.vue'
import {
  useNFTSoftPledgeDomStore,
  useNFTSoftPledgeStore,
} from 'src/stores/softPledge'
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { storeToRefs } from 'pinia'
const { pfpBoxDom } = storeToRefs(useNFTSoftPledgeDomStore())
const { emptyGuideShow, deletePFPLoadingRef, deckList } = storeToRefs(
  useNFTSoftPledgeStore(),
)
const message = useMessage()

const props = defineProps({
  pfpList: Array,
  empty: Boolean,
})

const list = computed(() => {
  let result = []
  if (!props.empty) {
    result = Array(5).fill(null) // 创建一个长度为5的数组用于存放数据
    props.pfpList?.forEach(item => {
      if (item.position && item.position <= 5) {
        result[item.position - 1] = item // 根据 position 放置到对应的位置
      }
    })
  }
  return props.empty ? props.pfpList : result
})
const emit = defineEmits(['edit'])

const typeDict = {
  OLD_ONES: '#73FC90',
  FROST_GIANT: '#73FCF4',
  DEITY: '#73D7FC',
  DRAGON: '#FCA073',
  ELF: '#FFF799',
  MORTAL: '#c0c0c0',
}

let triangleRef = ref(null)
let triangleTl

function initAnimation() {
  triangleTl = gsap.to(triangleRef.value, {
    rotate: 180,
    transformOrigin: 'center bottom',
    ease: 'linear',
    paused: true,
    duration: 0.15,
  })
}

function getNPopoverStatus(val) {
  if (val) {
    triangleTl.restart()
  } else {
    triangleTl.reverse()
  }
}

const isCopyed = ref(false)
let timer
function copyAddress() {
  copy('0x2B5c961BC0CD1945D4e173c1a6df38b9C1702B5d')
  isCopyed.value = !isCopyed.value
  timer = setTimeout(() => {
    isCopyed.value = false
  }, 2000)
}

const pfpBoxDomRef = ref(null)

function handleEdit(data, index) {
  emit('edit', index, data)
}

function handleRemoveAll() {
  if (!props.pfpList?.length) {
    message.warning(`The Deck's Ethereals is Empty`)
    return
  }
  confirmShowRef.value = true
}

const confirmShowRef = ref(false)
function handleConfirm() {
  emit('removeAll')
  confirmShowRef.value = false
}
onMounted(() => {
  pfpBoxDom.value = pfpBoxDomRef.value
  initAnimation()
})

onUnmounted(() => {
  triangleTl?.kill()
  clearTimeout(timer)
})
</script>

<template>
  <div class="relative mt-0.52vw pt-0.52vw">
    <div class="flex h-2.24vw relative flex-items-end justify-between">
      <div class="mb-0.3125vw">
        <div class="flex flex-items-end">
          <img
            class="w-8.54vw mr-0.88vw"
            src="src/asset/nftSoftStaking/ethereal1.webp"
            alt=""
          />
          <div class="ml-0px mouse_pointer">
            <div v-if="!isCopyed">
              <NPopover class="BrownPopover" placement="bottom" raw>
                <template #trigger>
                  <img
                    @click="copyAddress"
                    class="mouse_pointer w-1.40vw"
                    src="src/asset/nftSoftStaking/copy_icon.webp"
                    alt=""
                  />
                </template>
                <div
                  class="mouse_pointer flex-center px-0.21vw h-1.35vw rounded-6px color-#F89B58 bg-#15110E border border-solid border-#804D2C text-18px"
                >
                  copy contract address
                </div>
              </NPopover>
            </div>
            <img
              v-else
              :style="{ opacity: isCopyed ? 1 : 0 }"
              class="w-0.937vw"
              src="src/asset/nftSoftStaking/copyed_icon.webp"
              alt=""
            />
          </div>
        </div>
      </div>
      <NPopover
        placement="bottom"
        :show-arrow="false"
        @update:show="getNPopoverStatus"
        raw
      >
        <template #trigger>
          <div
            class="btn-buy mouse_pointer flex-center w-11.77vw h-2.19vw absolute right-0 bottom-0 text-1.25vw"
          >
            BUY NOW
            <div
              ref="triangleRef"
              class="triangle w-0.677vw h-0.677vw absolute z-1 bottom-1px mx-auto bg-#EBAA56"
            ></div>
          </div>
        </template>
        <div class="bg-#7E582B w-11.77vw px-0.365vw py-0.3125vw rounded-5px">
          <SocializeLinks
            :isMaxHeight="false"
            :isPFPLink="true"
          ></SocializeLinks>
        </div>
      </NPopover>
    </div>
    <div
      class="w-full h-20vw relative py-1.04vw pb-0.73vw px-1.35vw bg-#0E1512 border border-solid border-#31633F"
    >
      <ul class="flex relative justify-between" ref="pfpBoxDomRef">
        <li
          v-for="item in 5"
          :style="{ marginRight: item !== 5 ? '1.14vw' : '0' }"
          class="flex relative z-1 flex-col border-1.8px border-solid border-transparent"
        >
          <img
            v-if="list?.[item - 1]"
            @click="handleEdit(list?.[item - 1], item)"
            :style="{
              borderColor: typeDict[constantCase(list[item - 1]?.Species)],
            }"
            class="mouse_pointer w-7.76vw border border-solid overflow-hidden"
            :src="list[item - 1]?.image"
            alt=""
          />
          <img
            v-else-if="!deckList.length && !emptyGuideShow"
            class="mouse_pointer w-7.76vw overflow-hidden"
            src="src/asset/nftSoftStaking/empty_pfp.webp"
            alt=""
          />
          <img
            v-else
            @click="handleEdit(list?.[item - 1], item)"
            :src="addIcon"
            class="mouse_pointer w-7.76vw"
            alt=""
          />

          <template v-if="list?.[item - 1]">
            <div
              class="flex justify-between flex-items-start h-1.14vw text-0.78vw"
            >
              <div
                class="family_blk w-5.6vw h-1.14vw leading-1.4vw text-center text-0.73vw md:tracking-0.2px 3xl:tracking-0.4px"
                :class="`bg-${constantCase(list[item - 1]?.Species)}`"
              >
                {{ list[item - 1]?.Species?.toUpperCase() }}
              </div>
              <div
                class="text-0.625vw mr-2px tracking-1px h-1.14vw leading-1.14vw"
              >
                #{{ list[item - 1]?.id }}
              </div>
            </div>
            <div
              class="flex-center h-1.04vw bg-#192520 border border-solid border-#293F36"
            >
              <span class="text-#52D058 text-0.9375vw mr-1"
                >+{{ list[item - 1]?.point || 15 }}
              </span>
              <span class="text-0.9375vw">
                (<img
                  class="w-0.3125vw"
                  src="src/asset/nftSoftStaking/deck/pfp/arrow_top.webp"
                />
                {{ list[item - 1]?.bonus || 50 }}%)</span
              >
            </div>
          </template>
        </li>
        <div
          :style="{ opacity: pfpList?.length >= 5 ? 1 : 0 }"
          class="line absolute top-40% left-50% -translate-50% w-94% h-1px z-0 transition-all"
        ></div>

        <!-- 指引 -->
        <div
          v-if="emptyGuideShow"
          class="absolute z-2 w-104% h-120% -top-10% -left-2% border border-solid border-#235042"
        >
          <div
            class="mt-10.93vw family_blk_bold px-0.94vw md:text-base 2xl:text-2xl"
          >
            <img
              class="w-1.7vw ml-0.67vw"
              src="src/asset/nftSoftStaking/guide/step/4/arrow1.webp"
              alt=""
            />
            <div class="text-#69F093">ETHEREAL NFT SLOTS</div>
            <span class="text-#fff"
              >Players can freely configure decks based on the bonuses.</span
            >
          </div>
        </div>
      </ul>
      <div class="w-full relative">
        <div
          class="px-0.57vw py-0.41vw mt-0.938vw w-80% h-9.11vw text-#83A697 text-0.9375vw leading-1.35vw"
        >
          <div>
            1. The base staking points for each Ethereal placed in slot:
            <span class="text-#fff">5/hour</span>
          </div>
          <div>
            2. When the Ethereals in slot meets the following conditions, the
            deck's
          </div>
          <div class="ml-1.14vw">
            <span class="text-#FFFFFF">Genesis</span> and
            <span class="text-#FFFFFF">Ethereals</span> receive additional
            bonuses:
          </div>
          <div class="ml-1.14vw flex flex-items-center">
            <div
              class="mr-0.52vw w-0.47vw h-0.47vw bg-#708A8D border-2px border-solid border-#AEBEC0 rotate-45"
            ></div>
            <div>Ethereals' races form a specific set</div>
          </div>
          <!-- <div class="ml-1.14vw flex flex-items-center">
            <div
              class="mr-0.52vw w-0.47vw h-0.47vw bg-#708A8D border-2px border-solid border-#AEBEC0 rotate-45"
            ></div>
            <div>
              Specific number of
              <span class="text-#FFFFFF">Deity/Old Ones</span> race Avatars
            </div>
          </div>
          <div class="ml-1.14vw flex flex-items-center">
            <div
              class="mr-0.52vw w-0.47vw h-0.47vw bg-#708A8D border-2px border-solid border-#AEBEC0 rotate-45"
            ></div>
            <div>
              Ethereal rarity is <span class="text-#FFFFFF">Epic</span> or
              <span class="text-#FFFFFF">Legendary </span>
            </div>
          </div> -->
        </div>
        <div class="absolute bottom-2vw right-0 text-1.25vw text-#89B393">
          <!-- <div
            class="btn-bg mouse_pointer w-9.11vw h-1.77vw mb-15px flex-center"
          >
            View Deck
          </div> -->
          <WalletBox :cd="() => !deletePFPLoadingRef && handleRemoveAll()">
            <div
              class="btn-bg relative mouse_pointer w-9.11vw h-1.77vw flex-center"
            >
              Remove All
              <div v-if="deletePFPLoadingRef" class="absolute h-full w-full">
                <NSpin :size="30" class="absolute-center"></NSpin>
              </div>
            </div>
          </WalletBox>
        </div>
      </div>
    </div>

    <!-- 指引 -->
    <div
      v-if="emptyGuideShow"
      class="absolute w-102% h-full top-0 -left-1% bg-#060D09/80 border border-solid border-#235042"
    ></div>

    <ItemModal v-model:show="confirmShowRef" @confirm="handleConfirm">
      <div class="fz-5 px-2 py-10 text-center text-#CFDDE7">
        Confirm remove all Ethereals?
      </div>
    </ItemModal>
  </div>
</template>

<style lang="scss" scoped>
.btn-mint {
  @include bg100('src/asset/nftSoftStaking/btn_mint_bg.webp');
  @apply hover:bg-[url(src/asset/nftSoftStaking/btn_mint_active_bg.webp)];

  transition: all 0.1s;
}

.btn-bg {
  @include bg100('src/asset/nftSoftStaking/btn_common_bg.webp');
  @apply hover:bg-[url(src/asset/nftSoftStaking/btn_common_active_bg.webp)] hover:text-#83F09D;

  transition: all 0.1s;
}

.bg-OLD_ONES {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/OLD_ONES.webp');
}

.bg-FROST_GIANT {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/FROST_GIANT.webp');
}

.bg-DEITY {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/DEITY.webp');
}

.bg-DRAGON {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/DRAGON.webp');
}

.bg-ELF {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/ELF.webp');
}

.bg-MORTAL {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/MORTAL.webp');
}

.line {
  background: linear-gradient(
    90deg,
    #e9a5a5 0%,
    #b8c1c0 25%,
    #65c0e0 49.88%,
    #aea2db 75%,
    #81c1d9 100%
  );
}
</style>
