<script setup>
import { storeToRefs } from 'pinia'
import { NTabPane, NTabs, useMessage } from 'naive-ui'
import {
  useChangePasswordLinkStore,
  useLoginStore,
  useRegisterStore,
} from 'src/stores/user'
import { computed, ref } from 'vue'
import { fetchUserInfoP } from 'src/helpers/user'
import { codeToMessage } from '@BOGX/helpers'
import { useAsyncState } from '@vueuse/core'
import { hasPhone } from 'src/stores/media'
import Modal from '../Modal.vue'
import ThreePartyLogin from './ThreePartyLogin'
import AccountLogin from './AccountLogin'
import EmailLogin from './EmailLogin'

const defaultTab = 'account'
const accountLoginRef = ref(null)
const emailLoginRef = ref(null)
const threePartyLoginRef = ref(null)
const tabValueRef = ref(defaultTab)
const loginRef = computed(() =>
  tabValueRef.value === defaultTab
    ? accountLoginRef.value
    : emailLoginRef.value,
)

const message = useMessage()
const loginStore = useLoginStore()
const { open: register } = useRegisterStore()
const { success: loginSuccess, errorClose: errorCloseLogin } = loginStore
const { show: loginShow } = storeToRefs(loginStore)
const { open: changePasswordLinkOpen } = useChangePasswordLinkStore()
const { isLoading, execute: executeLogin } = useAsyncState(
  async () => {
    await loginRef.value.handleSubmit()
    const userInfo = await fetchUserInfoP.run()
    handleLoginSuccess(userInfo)
    return null
  },
  null,
  {
    immediate: false,
    onError(error) {
      message.error(codeToMessage(error?.data?.code))
    },
  },
)
const loadingRef = computed(
  () => isLoading.value || threePartyLoginRef.value?.loading,
)
function handleLoginSuccess(userInfo) {
  message.success('Login successful.')
  loginSuccess(userInfo)
}

async function handleSubmit(e) {
  if (loadingRef.value) return
  await loginRef.value.handleValidate(e)
  await executeLogin()
}
function openRegister() {
  register()
  // errorCloseLogin()
}
function handlePassWordShow() {
  changePasswordLinkOpen()
}
const themeOverrides = {
  barColor: '#9E4FE8',
  tabTextColorActiveBar: '#fff',
  tabTextColorHoverBar: '#fff',
  tabTextColorBar: '#606067',
  tabFontSizeLarge: !hasPhone ? '16px' : '12px',
  tabPaddingLargeBar: !hasPhone ? '10px' : '3px',
}
</script>

<template>
  <Modal :show="loginShow" @update:show="errorCloseLogin">
    <div class="user_modal h-[430px] md:h-[584px]">
      <span class="modal_triangle_top top-0"></span>
      <img src="/src/asset/labs_logo.webp" alt="" class="logo md:mb-[42px]" />
      <NTabs
        v-model:value="tabValueRef"
        class="tabs mt-4 md:mt-0"
        size="large"
        justify-content="space-evenly"
        :theme-overrides="themeOverrides"
      >
        <NTabPane class="mt-1.5 md:mt-3" name="email" tab="VERIFICATION LOG IN">
          <EmailLogin ref="emailLoginRef" :disabled="loadingRef"></EmailLogin>
        </NTabPane>
        <NTabPane class="mt-1.5 md:mt-3" name="account" tab="ACCOUNT LOG IN">
          <AccountLogin ref="accountLoginRef" :disabled="loadingRef" />
        </NTabPane>
      </NTabs>
      <span
        v-if="tabValueRef === 'account'"
        class="mouse_pointer mb-[16px] mt-[-8px] flex justify-end text-#D9B16E md:mb-3"
        :class="`fz-${!hasPhone ? 3 : 2}`"
        @click="handlePassWordShow"
        >Forget your password?
      </span>
      <div class="mb-[24px] md:mb-[34px]">
        <div class="flex-sb">
          <div class="item-btn-black user_btn" @click="errorCloseLogin">
            Cancel
          </div>
          <div
            class="item-btn-gray user_btn"
            @click="handleSubmit"
            :class="{ disabled: loadingRef }"
          >
            Log in
          </div>
        </div>
      </div>
      <ThreePartyLogin ref="threePartyLoginRef"></ThreePartyLogin>
      <div>
        <div class="fz-2 gray-color text-center">
          Do not have
          <span class="text-white">VOIDLABS</span> account yet?
          <span class="orange-color mouse_pointer" @click="openRegister"
            >Sign up here</span
          >
          .
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import './common';

.tabs {
  // margin-left: -7px;

  ::v-deep {
    .n-tabs-nav-scroll-content {
      .n-tabs-tab-wrapper:first-of-type {
        .n-tabs-tab__label {
          // @apply ml-[-8px];
        }
      }
    }

    //   .n-tabs-bar {
    //     &::after {
    //       width: 10px;
    //       height: 10px;
    //       content: '';
    //       background-color: #9e4fe8;
    //       border-radius: 50%;
    //     }
    //   }
  }
}
</style>
