<script setup>
import { ref } from 'vue'
import { useMessage } from 'naive-ui'
import { errorCodeToMessage } from 'src/helpers/utils'
import { apiOKX } from 'src/helpers/wallet'
import ItemModal from 'src/components/ItemModal'
import { getOKXProvider } from 'src/sdk/blockchain'
import { hasInstallOKX } from '@abmao/wallet-app'
const props = defineProps({
  address: String,
})
const message = useMessage()

const walletConfirmShowRef = ref(false)
const walletUnbindShowRef = ref(false)
const okxAccountRef = ref('')
async function handleWallet() {
  if (props.address) {
    walletUnbindShowRef.value = true
  } else {
    if (hasInstallOKX()) return
    const accounts = await getOKXProvider().request({
      method: 'eth_requestAccounts',
    })
    okxAccountRef.value = accounts[0]
    walletConfirmShowRef.value = true
  }
}
function handleClose() {
  walletConfirmShowRef.value = false
  walletUnbindShowRef.value = false
}
async function handleBind() {
  try {
    await apiOKX.bindWallet()
    message.success('Wallet address binding successful.')
    handleClose()
  } catch (error) {
    errorCodeToMessage(error)
    throw error
  } finally {
    handleClose()
  }
}

async function handleUnbind() {
  try {
    await apiOKX.unbindWallet(props.address)
    message.success('Wallet address unbind successful.')
  } catch (error) {
    errorCodeToMessage(error)
    throw error
  } finally {
    handleClose()
  }
}
</script>

<template>
  <div @click="handleWallet">
    <slot></slot>
    <!-- 确认绑定钱包地址 -->
    <ItemModal
      v-model:show="walletConfirmShowRef"
      @cancel="handleClose"
      @confirm="handleBind"
    >
      <div class="fz-4 px-2 py-5 text-center text-#CFDDE7">
        Confirm wallet address binding.
        <div class="orange-color break-all">{{ okxAccountRef }}</div>
      </div>
    </ItemModal>
    <!-- 你想更改钱包地址吗？ -->
    <ItemModal
      v-model:show="walletUnbindShowRef"
      @cancel="handleClose"
      @confirm="handleUnbind"
    >
      <div class="fz-4 px-2 py-5 text-center text-#CFDDE7">
        Do you want to unbind the wallet address?
        <div class="orange-color break-all">{{ address }}</div>
      </div>
    </ItemModal>
  </div>
</template>
