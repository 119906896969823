<script setup>
import { ref, watch, watchEffect } from 'vue'
import Header from './components/Header/index.vue'
import Card from './components/Card/index'
import RolesModal from './components/RolesModal/index'
import SuccessModal from './components/SuccessModal/index'
import History from './components/History/index.vue'
import HistoryModal from './components/History/HistoryModal.vue'
import { storeToRefs } from 'pinia'
import { useImmutableIncome } from '.'
import { useMessage, NSelect, NFormItem } from 'naive-ui'
import { getCurrentProvider } from 'src/sdk/blockchain/walletConnect'

const showSuccessModal = ref(false)

const {
  chains,
  selectedChainId,
  productsList,
  roleList,
  roleId,
  roleName,
  loading,
  selectId,
  successList,
  showRoleModal,
  showHistoryModal,
  handleSelectHero,
  handleConfirm,
  handleBuy,
  handleHistoryModal,
} = useImmutableIncome()

const selectTheme = {
  peers: {
    InternalSelection: {
      heightMedium: '32px',
      textColor: '#E4D5B9',
      color: '#1C1A19',
      borderRadius: '8px',
      borderHover: '1px dashed #3f3c3a99',
      fontSize: '18px',
    },
    Select: {
      optionFontSize: '18px', // 设置选项字体大小
    },
  },
}
</script>

<template>
  <div class="wrap relative item_container">
    <Header></Header>
    <div class="main flex justify-center pt-3% relative">
      <div class="md:scale-80 2xl:scale-100 origin-tc">
        <div class="flex md:scale-120 origin-lb">
          <div
            @click="handleSelectHero"
            class="mouse_pointer mb-8 mr-4 text-#E4D5B9 relative w-280px h-32px flex flex-items-center pl-12px bg-#0C0B09 border border-solid border-#494744/60 rounded-5px"
          >
            HERO
            <div
              class="absolute pl-9px left-66px right-0 top-0 text-16px bottom-0 leading-28px text-#E4D5B9 bg-#1C1A19 border border-dashed border-#3F3C3A/60 rounded-5px"
            >
              {{ roleId ? roleName : 'Please select' }}
            </div>
          </div>
          <div
            class="mb-4 flex items-center md:mb-0 md:h-[32px] md:bg-#0c0b0a text-#E4D5B9 md:pl-2 border border-solid border-#494744/60 rounded-5px"
          >
            <span class="mr-3 md:mr-2">NETWORK</span>
            <NSelect
              v-model:value="selectedChainId"
              :options="chains"
              :theme-overrides="selectTheme"
              style="width: 200px"
              class="custom-select"
            ></NSelect>
          </div>
        </div>
        <div class="flex">
          <Card
            @buy="data => handleBuy(0, data, selectedChainId)"
            :productData="item"
            :selectId="selectId"
            :key="item.id"
            :loading="loading"
            v-for="item in productsList"
          ></Card>
        </div>
        <div class="fz-3 mt-4 leading-18px text-#E4D5B9">
          <div class="fz-3 mb-1">
            Soul Jade is the main currency in BOGX for purchasing items.
          </div>
          <div class="fz-3">
            <div>
              Step1: Select an existing character, or create one by downloading
              the game from the homepage.
            </div>
            <div>Step2: Choose a mainnet (ETH or Polygon supported).</div>
            <div>
              Step3: Select the category of Soul Jade items to buy, and use USDC
              for the transaction.
            </div>
            <div>
              Step4: Claim your items in the game's mailbox after purchase.
            </div>
          </div>
        </div>
      </div>
    </div>
    <History @click="handleHistoryModal"></History>
    <RolesModal
      :list="roleList?.slice(1)"
      :roleId="roleId"
      @confirm="handleConfirm"
      v-model:show="showRoleModal"
    ></RolesModal>
    <SuccessModal
      :list="successList"
      v-model:show="showSuccessModal"
    ></SuccessModal>
    <HistoryModal
      v-model:show="showHistoryModal"
      headerText="SOUL JADE STORE"
      :charId="roleId"
    ></HistoryModal>
  </div>
</template>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  @include bgAuto('src/asset/immutableIncome/bg.webp');

  background-position-y: 82px;

  .main {
    height: calc(100vh - var(--header-height));
  }
}

.custom-select {
  ::v-deep {
    .n-base-selection .n-base-selection-label .n-base-selection-input {
      font-size: 16px; /* 设置输入框文本的字体大小 */
    }
  }
}
</style>
