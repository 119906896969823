import { useAsyncState } from '@vueuse/core'
import { ref, watch } from 'vue'
import copy from 'copy-to-clipboard'
import { useMessage } from 'naive-ui'

export function useHistoryTable(props) {
  const message = useMessage()
  const listRef = ref([])
  const { execute: fetchList } = useAsyncState(
    async () => {
      const pList = props.list.map(item => {
        return Promise.resolve(item)
      })
      const list = await Promise.all(pList)
      listRef.value = list.map((item, index) => {
        const data = props.list[index]
        return {
          ...item,
          ...data,
          showCode: false,
        }
      })
    },
    null,
    {
      immediate: false,
    },
  )
  watch(
    () => props.list,
    () => {
      fetchList()
    },
    {
      immediate: true,
    },
  )
  function handleCopy(text) {
    copy(text)
    message.success('copy success')
  }
  return {
    listRef,
    handleCopy,
  }
}
