<script setup>
import { onMounted, onUnmounted, reactive, ref } from 'vue'
import { gsap } from 'gsap'
import { useMusicStore } from 'src/stores/music'
import { lineAngleW, lineBottomConnectW } from '../constant'
import { useRouter } from 'vue-router'
import { IMMUTABLE_INCOME } from 'src/constant'
import LottieAnimation from 'src/components/Lottie.vue'
import animationData from 'src/lottie/Iconstore.json'
const { buttonClickAudioPlay, buttonCaptureAudioPlay } = useMusicStore()
const hornDomRef = ref(null)
const router = useRouter()
const domAttributeReactive = reactive({
  opacityLine: 1,
  lineBottomRect: 0,
  lineAngleRect: lineAngleW,
})

let hoverIng = false
let hornXTl, hornOpacityTL, bottomLineTl, obliqueLineTl, opacityTl

function initHover() {
  const options = {
    ease: 'none',
    paused: true,
  }
  hornOpacityTL = gsap.to(hornDomRef.value, {
    ...options,
    opacity: 1,
    duration: 0.3,
  })
  hornXTl = gsap.to(hornDomRef.value, {
    ...options,
    x: '-20%',
    duration: 0.5,
    onComplete() {
      if (hoverIng) {
        hornXTl.reverse()
      }
    },
    onReverseComplete() {
      if (hoverIng) {
        hornXTl.play()
      }
    },
  })
  bottomLineTl = gsap.to(domAttributeReactive, {
    ...options,
    lineBottomRect: 230,
    duration: 0.4,
  })
  obliqueLineTl = gsap.to(domAttributeReactive, {
    ...options,
    lineAngleRect: 0,
    duration: 0.1,
    onComplete() {
      bottomLineTl.play()
    },
  })
  opacityTl = gsap.to(domAttributeReactive, {
    opacityLine: 0,
    duration: 0.3,
    ...options,
    onComplete() {
      bottomLineTl.progress(0).pause()
      obliqueLineTl.progress(0).pause()
      opacityTl.progress(0).pause()
    },
  })
}
function handleMouseenter() {
  hoverIng = true
  hornOpacityTL.play()
  hornXTl.play()
  obliqueLineTl.play()
  buttonCaptureAudioPlay()
}
function handleMouseleave() {
  hoverIng = false
  hornOpacityTL.reverse()
  hornXTl.reverse()
  opacityTl.play()
}

function handleGo() {
  router.push({
    name: IMMUTABLE_INCOME,
  })
}
onMounted(() => {
  initHover()
})
onUnmounted(() => {
  hornXTl?.kill()
  hornOpacityTL?.kill()
  bottomLineTl?.kill()
  obliqueLineTl?.kill()
  opacityTl?.kill()
})
</script>

<template>
  <div
    class="mouse_pointer fixed right-0 top-10 z-2"
    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
    @click="handleGo"
  >
    <div
      class="relative z-2 pr-0 pt-3 flex justify-items-start flex-items-center"
    >
      <img
        ref="hornDomRef"
        src="/src/asset/Connect1.webp"
        class="relative -ml-2 w-[11.5px] h-12px opacity-0"
        alt=""
      />
      <span class="fz-3 mr-1.5 text-#DCB281 tracking-1.5px"
        >SOUL JADE STORE</span
      >
      <div class="w-25px -mb-1.5 mr-7.5">
        <LottieAnimation
          :ishover="true"
          :animationData="animationData"
          :loop="false"
          :autoplay="false"
        ></LottieAnimation>
      </div>
      <!-- <div
        ref="galleryRef"
        class="gallery-logo mr-30px active w-[23px] h-[18px]"
      ></div> -->
    </div>
    <div
      class="line z-2 w-full translate-x-[-10%]"
      :style="{
        clip: `rect(auto, ${domAttributeReactive.lineBottomRect}px, auto, auto)`,
        width: `230px`,
        opacity: domAttributeReactive.opacityLine,
      }"
    ></div>
    <div
      class="line w-26px oblique"
      :style="{
        clip: `rect(auto, auto, auto, ${domAttributeReactive.lineAngleRect}px)`,
        opacity: domAttributeReactive.opacityLine,
        width: `${lineAngleW}px`,
      }"
    ></div>
  </div>
</template>

<style scoped>
.line {
  @apply absolute border-dotted h-[1px] border-0 border-b-1 border-#707070;
}

.oblique {
  bottom: 22%;
  left: -12%;
  transform: rotate(-45deg);
}

.gallery-logo {
  background: url('/src/asset/gallery_logo.webp');
  background-size: 100% 100%;
  transition: background-image ease-in-out 0.2s;

  /* @apply hover:bg-[url(/src/asset/gallery_logo.webp)]; */
}

.active {
  @apply hover:bg-[url(/src/asset/gallery_logo.webp)];
}
</style>
